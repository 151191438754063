import { atom, selectorFamily } from "recoil";

/* 被标记为reject的id集合 */

// media id
export const rejectedMediaIdsState = atom<string[]>({
  key: "rejected/media_ids",
  default: [],
});

// profile
export const rejectedUserIdsState = atom<string[]>({
  key: "rejected/user_ids",
  default: [],
});

// About me
export const rejectedAboutMeIdsState = atom<string[]>({
  key: "rejected/about_me_ids",
  default: [],
});

// seeking
export const rejectedSeekingIdsState = atom<string[]>({
  key: "rejected/seeking_ids",
  default: [],
});

/*
 * Is selected status
 */

export const isSelectedMediaIdQuery = selectorFamily({
  key: "is_selected/media_id",
  get:
    (id: string) =>
    ({ get }) =>
      get(rejectedMediaIdsState).includes(id),
});

export const isSelectedProfileIdQuery = selectorFamily({
  key: "is_selected/profile_ids",
  get:
    (id: string) =>
    ({ get }) =>
      get(rejectedUserIdsState).includes(id),
});

export const isSelectedSeekingIdQuery = selectorFamily({
  key: "is_selected/seeking_id",
  get:
    (id: string) =>
    ({ get }) =>
      get(rejectedSeekingIdsState).includes(id),
});
