import { fetchSeekingDescriptions, SeekingDescriptionsParams } from "apis";
import dayjs from "dayjs";
import { Status } from "enums";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import { searchPageSizeState } from "states/search";
import { getErrorMessage } from "utils";
import {
  genders,
  genderState,
  searchTimestampsQuery,
  seekingStatusState,
} from "views/components";

export const seekingDescriptionsRequestIdState = atom({
  key: "seekingDescriptions/requestId",
  default: dayjs().valueOf(),
});

export const seekingDescriptionsPageState = atom({
  key: "seekingDescriptions/page",
  default: 1,
});

const seekingDescriptionsRespQuery = selector({
  key: "seekingDescriptions/resp",
  get: async ({ get }) => {
    get(seekingDescriptionsRequestIdState);

    const params: SeekingDescriptionsParams = {
      page: get(seekingDescriptionsPageState),
      pageSize: get(searchPageSizeState),
      status: get(seekingStatusState),
      gender: genders[get(genderState)],
    };

    if (Status.Pending !== get(seekingStatusState)) {
      const searchTimestamps = get(searchTimestampsQuery);
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    }

    try {
      const resp = await fetchSeekingDescriptions(params);

      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const seekingDescriptionsQuery = selector({
  key: "seekingDescriptions",
  get: ({ get }) => {
    return get(seekingDescriptionsRespQuery)?.data ?? [];
  },
});

export const seekingDescriptionsCountQuery = selector({
  key: "seekingDescriptions/count",
  get: ({ get }) => {
    return get(seekingDescriptionsRespQuery)?.page?.total ?? 0;
  },
});
