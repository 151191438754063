// users

import { fetchProfiles, ProfilesParams } from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchEmailState,
  searchIMEIState,
  searchPageSizeState,
  searchUIDState,
} from "states/search";
import { getErrorMessage } from "utils";
import {
  profileStatusesQuery,
  relationshipsStatusesQuery,
  riskStatusesQuery,
  searchTimestampsQuery,
} from "views/components";

export const profilesPageState = atom({
  key: "profiles/page",
  default: 1,
});

export const profilesRequestIdState = atom({
  key: "profiles/requestId",
  default: dayjs().valueOf(),
});

export const profilesRespQuery = selector({
  key: "profiles/resp",
  get: async ({ get }) => {
    get(profilesRequestIdState);

    const page = get(profilesPageState);
    const pageSize = get(searchPageSizeState);

    const params: ProfilesParams = {
      pageSize,
    };

    const uid = get(searchUIDState);
    const email = get(searchEmailState);
    const imei = get(searchIMEIState);

    if (!uid && !email && !imei) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;

      params.page = page;
      params.relationshipsTag = get(relationshipsStatusesQuery);
      params.status = get(profileStatusesQuery);
      params.riskStatus = get(riskStatusesQuery);
    } else {
      params.id = !uid ? undefined : uid;
      params.email = !email ? undefined : email;
      params.imei = !imei ? undefined : imei;
    }

    try {
      const resp = await fetchProfiles(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const profilesQuery = selector({
  key: "profiles",
  get: ({ get }) => {
    return get(profilesRespQuery)?.data ?? [];
  },
});

export const profilesCountQuery = selector({
  key: "profiles/count",
  get: ({ get }) => {
    return get(profilesRespQuery)?.page?.total ?? 0;
  },
});
