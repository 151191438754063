import { Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { Platform } from "enums";
import { useRecoilValue } from "recoil";
import { secretsQuery } from "states";

import { CustomTable, EmptyView, platformState } from "views/components";
import { SecretsTableCell } from "./secrets-table-cell";

// PhoneModelsTable
export function SecretsTable() {
  const platform = useRecoilValue(platformState);
  const secrets = useRecoilValue(secretsQuery);

  return (
    <>
      {secrets.length === 0 && <EmptyView />}
      {secrets.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Platform</Th>
              <Th>
                {(platform as Platform) === Platform.iOS
                  ? "Bundle ID"
                  : "Package Name"}
              </Th>
              <Th>Build</Th>
              <Th>Secret</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>

          <Tbody>
            {secrets.map((item) => (
              <SecretsTableCell
                key={item.id}
                secret={item}
              />
            ))}
          </Tbody>
        </CustomTable>
      )}
    </>
  );
}
