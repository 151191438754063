import { HStack, Input, Stack, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { searchUIDState } from "states";
import { SearchRelationshipDirection } from "./RelationshipDirectionSelector";

type SearchUIDInputProps = {
  showDirection?: boolean;
};

export function SearchUIDInput({ showDirection }: SearchUIDInputProps) {
  const [uid, setUID] = useRecoilState(searchUIDState);

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Search
      </Text>
      <HStack spacing={0}>
        {showDirection && <SearchRelationshipDirection />}
        <SearchInput
          defaultValue={uid}
          onSearch={setUID}
        />
      </HStack>
    </Stack>
  );
}

type SearchInputProps = {
  defaultValue: string | number;
  onSearch?: (targetValue: string) => void;
};

function SearchInput({ defaultValue, onSearch }: SearchInputProps) {
  const [inputValue, setInputValue] = useState(defaultValue);

  const debouncedSearch = useMemo(
    () => debounce((value) => onSearch && onSearch(value), 500),
    [onSearch]
  );

  useEffect(() => {
    debouncedSearch(inputValue);

    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  return (
    <Input
      size="sm"
      placeholder="uid"
      defaultValue={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
}
