import { Box, Stack, StackProps, Text } from "@chakra-ui/react";

export function Label(props: LabelProps & StackProps) {
  // value为undefined或”“时，使用”--“
  // const value = (props.value ?? "") === "" ? "--" : props.value;

  const { title, value, children, ...stackProps } = props;

  // const value =
  //   props.value === undefined || props.value === null ? "--" : props.value;

  return (
    // left对奇，避免内容太长不折行
    <Stack
      alignItems="left"
      spacing={2}
      {...stackProps}>
      <Text
        fontSize={"xs"}
        fontWeight="bold"
        opacity={0.5}>
        {title.toUpperCase()}
      </Text>
      {/* 统一加大字号 */}
      <Box fontSize="lg">{children ? children : value}</Box>
    </Stack>
  );
}
interface LabelProps {
  title: string;
  value?: string | number;
}
