import {
  AspectRatio,
  Box,
  Center,
  HStack,
  Link,
  Spacer,
  Stack,
  StackProps,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CARD_WIDTH,
  HOVER_COLOR_BG_DARK,
  HOVER_COLOR_BG_LIGHT,
  SELECTED_COLOR_BG,
} from "constant";
import {
  AutoStatus,
  getGestureTitle,
  MediaType,
  Status,
  StatusType,
} from "enums";
import { Media } from "interfaces";
import _ from "lodash";
import React from "react";
import { BsCameraVideo } from "react-icons/bs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isSelectedMediaIdQuery, rejectedMediaIdsState } from "states";
import {
  FullScreenButton,
  MediaView,
  RejectMark,
  StatusTag,
  UserIdContainer,
  ZStack,
} from "views/components";

interface MediaViewProps {
  media: Media;
  uid?: string;
  isVerify?: boolean;
}

export function ProfileMediaView(props: MediaViewProps & StackProps) {
  const hoverColor = useColorModeValue(
    HOVER_COLOR_BG_LIGHT,
    HOVER_COLOR_BG_DARK
  );

  const borderColor = useColorModeValue("gray.50", HOVER_COLOR_BG_DARK);

  const { uid, media, isVerify, onClick, ...stackProps } = props;

  const selectMedia = useSetRecoilState(rejectedMediaIdsState);
  const isSelectedMedia = useRecoilValue(isSelectedMediaIdQuery(media.id));

  const handleClickMedia = (e: React.MouseEvent) => {
    e.stopPropagation();

    // rejected & deleted 无法点击
    if ([Status.Rejected, Status.Deleted].includes(props.media.status)) return;

    selectMedia((curr) => {
      return _.xor(curr, [media.id]);
    });
  };

  return (
    <Stack
      minW={CARD_WIDTH}
      spacing={0}
      _hover={{ bg: hoverColor }}
      borderWidth="1px"
      borderColor={isSelectedMedia ? SELECTED_COLOR_BG : borderColor}
      borderStyle="solid"
      cursor="pointer"
      {...stackProps}>
      <AspectRatio ratio={10 / 16}>
        <ZStack onClick={handleClickMedia}>
          <MediaView media={media} />

          {/* 遮罩层 */}
          <Stack h="100%">
            <HStack p={2}>
              {/* ShortVideo Icon */}
              {media.type === MediaType.ShortVideo && (
                <Box
                  p={1}
                  color="white">
                  <BsCameraVideo size="16px" />
                </Box>
              )}

              <Spacer />
              {media.riskScore !== 11000 && media.riskScore > 7000 && (
                <Tag
                  bg="red"
                  color="white">
                  NSFW
                </Tag>
              )}
              {media.autoStatus === AutoStatus.Pending && (
                <Tag colorScheme="green">AI</Tag>
              )}
              {media.riskScore === 11000 && (
                <Tag colorScheme="gray">AI FAILED</Tag>
              )}
            </HStack>

            <Spacer />
          </Stack>

          <Center
            h="100%"
            _hover={{ bg: "blackAlpha.100" }}>
            {isSelectedMedia && <RejectMark w="128px" />}
          </Center>
        </ZStack>
      </AspectRatio>

      {/* Info */}
      <HStack
        p={2}
        // borderTop="1px"
        borderColor={borderColor}
        borderStyle="solid"
        alignItems="center"
        bg={media.status === Status.Rejected ? "red.400" : undefined}>
        <FullScreenButton media={media}>
          <Link>#{media.id}</Link>
        </FullScreenButton>

        <Spacer />
        {isVerify && <Text>{getGestureTitle(media.position)}</Text>}
        <StatusTag
          statusType={StatusType.Media}
          status={media.status}
        />

        {uid && (
          <UserIdContainer
            uid={uid}
            isExternal>
            U
          </UserIdContainer>
        )}
      </HStack>
    </Stack>
  );
}
