import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getAuth, signOut } from "firebase/auth";
import { useRecoilValue } from "recoil";
import { authAtom } from "states";
import { getErrorMessage } from "utils";

export function UserMenu() {
  const toast = useToast();

  const user = useRecoilValue(authAtom);

  const handleSignOut = async () => {
    try {
      await signOut(getAuth());
    } catch (error) {
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  if (process.env.REACT_APP_DEBUG) {
    return <Text>Debug Mode</Text>;
  }

  if (!user) {
    return <></>;
  }

  return (
    // 使用box包裹menu，消除css警告
    <Box
      p={2}
      ml={2}>
      <Menu isLazy>
        <MenuButton
          w="100%"
          _hover={{ opacity: 0.8 }}>
          <Avatar
            size="sm"
            name={user.displayName ?? undefined}
            src={user.photoURL ?? undefined}
          />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => console.log("show current user info")}>
            {user.email}
          </MenuItem>
          <MenuDivider />
          <MenuItem>
            <Text
              fontSize="medium"
              onClick={handleSignOut}>
              Sign Out
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
