import { Device } from "interfaces";
import _ from "lodash";
import { atom, DefaultValue, selectorFamily } from "recoil";

export const editedDevicesState = atom<Device[]>({
  key: "devices/edited",
  default: [],
});

export const editDeviceQuery = selectorFamily({
  key: "devices/edit",
  get:
    (deviceId: string) =>
    ({ get }) => {
      if (!deviceId) return;

      const editedDevices = get(editedDevicesState);

      return editedDevices.find((p) => p.id === deviceId);
    },
  set:
    (deviceId: string) =>
    ({ get, set }, newValue) => {
      const editedDevices = get(editedDevicesState);

      const devices = _.cloneDeep(editedDevices);

      if (!newValue || newValue instanceof DefaultValue) {
        _.remove(devices, (d) => d.id === deviceId);
      } else {
        const index = editedDevices.findIndex((p) => p.id === deviceId);
        if (index !== -1) {
          devices[index] = newValue;
        } else {
          devices.push(newValue);
        }
      }

      set(editedDevicesState, devices);
    },
});
