// getEnumKeys 获取enum的keys
export function getEnumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function getEnumKey<O extends object, V extends string | number>(
  obj: O,
  value: V
): string {
  return Object.keys(obj)[Object.values(obj).indexOf(value)];
}
