/* API */

import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, BasicProfile, ListParams, Timestamps } from "interfaces";

export const fetchRelationshipInstantChat = (params: InstantChatParams) => {
  return axiosInstance.post<InstantChatRelationshipsResponse>(
    ApiURL.RelationshipInstantChatSearch,
    params
  );
};

/* Statues */

/* Params */

export interface InstantChatParams extends ListParams {
  fromUid?: string;
  toUid?: string;
}

/* Response */
export interface InstantChatRelationship extends Timestamps {
  id: string;
  fromUser: BasicProfile;
  toUser: BasicProfile;
}

type InstantChatRelationshipsResponse = APIResponse<InstantChatRelationship[]>;
