import { Stack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { DeviceStatus } from "enums";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./filter-selector";

export const deviceStatusState = atom({
  key: "device_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const deviceStatuses = [
  DeviceStatus.Normal,
  DeviceStatus.Banned,
  DeviceStatus.Underage,
];

export function DeviceStatusFilter() {
  const filterOptions = deviceStatuses.map((status) => {
    const option: FilterSelectorOption = {
      title: DeviceStatus[status],
      value: status,
    };

    return option;
  });

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Status
      </Text>
      <FilterSelector
        options={filterOptions}
        state={deviceStatusState}
      />
    </Stack>
  );
}
