import { CacheKey } from "enums";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states";
import { getErrorMessage } from "utils";

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

const firebaseApp = initializeApp(config);

export const auth = getAuth(firebaseApp);

// 获取idToken
export const getIdToken = async () => {
  // 获取是否需要强制刷新token的标识
  const isForce = window.localStorage.getItem(CacheKey.RefreshIdToken) !== null;

  try {
    // 获取idToken，因auth hooks强制拦截等待firebase初始化，此处强制转为User | null 为User
    const idToken = await auth.currentUser?.getIdToken(isForce);

    // 移出标识
    window.localStorage.removeItem(CacheKey.RefreshIdToken);
    return idToken;
  } catch (error) {
    setRecoil(errorState, getErrorMessage(error));
  }
};
