import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { createPhoneModel, deletePhoneModel, editPhoneModel } from "apis";
import dayjs from "dayjs";
import { Platform } from "enums";
import { PhoneModel, PhoneModelToCreate, PhoneModelToUpdate } from "interfaces";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { searchPageState, timestampAtom } from "states";
import { getEnumKeys, getErrorMessage } from "utils";
import { platformState } from "views/components";

// PhoneModelModalContent
export function PhoneModelModalContent(props: PhoneModelModalContentProps) {
  const [phoneModalToCreate, setPhoneModalToCreate] =
    useState<PhoneModelToCreate>({
      platform: props.phoneModel?.platform ?? Platform.iOS,
      model: props.phoneModel?.model ?? "",
      marketingName: props.phoneModel?.marketingName ?? "",
    });

  const handleChangePlatform = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {
      target: { value },
    } = event;
    const platform = parseInt(value) as Platform;
    setPhoneModalToCreate((currVal) => {
      return { ...currVal, platform };
    });
  };

  const handleChangeModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setPhoneModalToCreate((currVal) => {
      return { ...currVal, model: value };
    });
  };

  const handleChangeMarketingName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event;
    setPhoneModalToCreate((currVal) => {
      return { ...currVal, marketingName: value };
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        {props.phoneModel ? "Edit" : "Create"} Phone Model
      </ModalHeader>

      <ModalBody>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Platform</FormLabel>
            <Select
              defaultValue={phoneModalToCreate.platform}
              onChange={handleChangePlatform}>
              {getEnumKeys(Platform).map((key, index) => (
                <option
                  key={index}
                  value={Platform[key]}>
                  {key}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Model</FormLabel>
            <Input
              // type="date"
              placeholder="Model"
              defaultValue={phoneModalToCreate.model}
              onChange={handleChangeModel}
            />
            <FormHelperText>
              Example: iOS: iPhone3,1, Android: M2012K11AC
            </FormHelperText>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Marketing Name</FormLabel>
            <Input
              // type="date"
              placeholder="Marketing Name"
              defaultValue={phoneModalToCreate.marketingName}
              onChange={handleChangeMarketingName}
            />
            <FormHelperText>
              Example: iOS: iPhone 4S, Android: Mi11 Pro
            </FormHelperText>
          </FormControl>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          onClick={props.onClose}>
          Close
        </Button>

        {/* Create Button */}
        {!props.phoneModel && (
          <CreateButton
            phoneModalToCreate={phoneModalToCreate}
            callback={props.onClose}
          />
        )}

        {props.phoneModel && (
          <DeleteButton
            id={props.phoneModel.id}
            callback={props.onClose}
          />
        )}

        {/* Save Button */}
        {props.phoneModel && (
          <EditButton
            phoneModelToUpdate={{
              ...phoneModalToCreate,
              id: props.phoneModel.id,
            }}
            callback={props.onClose}
          />
        )}
      </ModalFooter>
    </ModalContent>
  );
}

interface PhoneModelModalContentProps {
  phoneModel?: PhoneModel;
  onClose?: () => void;
}

interface CreateButtonProps {
  phoneModalToCreate: PhoneModelToCreate;
  callback?: () => void;
}

function CreateButton(props: CreateButtonProps) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setPlatform = useSetRecoilState(platformState);
  const setPage = useSetRecoilState(searchPageState);
  const refresh = useSetRecoilState(timestampAtom);

  const handleCreate = async () => {
    setIsSubmitting(true);

    try {
      await createPhoneModel(props.phoneModalToCreate);
      toast({
        title: "Create Phone Modal Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh(dayjs().valueOf());
      setPage(1);
      // 切换至对应的platform tab
      setPlatform(props.phoneModalToCreate.platform);
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Create Phone Modal Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleCreate}>
      Create
    </Button>
  );
}

interface EditButtonProps {
  phoneModelToUpdate: PhoneModelToUpdate;
  callback?: () => void;
}

function EditButton(props: EditButtonProps) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refresh = useSetRecoilState(timestampAtom);

  const handleSave = async () => {
    setIsSubmitting(true);

    try {
      await editPhoneModel(props.phoneModelToUpdate);

      toast({
        title: "Edit Phone Modal Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh(dayjs().valueOf());
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Edit Phone Modal Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleSave}>
      Save
    </Button>
  );
}

interface DeleteButtonProps {
  id: string;
  callback?: () => void;
}

function DeleteButton(props: DeleteButtonProps) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refresh = useSetRecoilState(timestampAtom);

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await deletePhoneModel(props.id);

      toast({
        title: "Delete Phone Modal Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh(dayjs().valueOf());
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Delete Phone Modal Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Button
      ml={3}
      colorScheme="red"
      isLoading={isSubmitting}
      onClick={handleDelete}>
      Delete
    </Button>
  );
}
