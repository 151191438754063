import { Box, BoxProps } from "@chakra-ui/react";
import { Media } from "interfaces";
import React from "react";
import { useSetRecoilState } from "recoil";
import { modalMediaViewState } from "states";

interface FullScreenButtonProps {
  media: Media;
}

export function FullScreenButton(props: FullScreenButtonProps & BoxProps) {
  const { media, children, ...restProps } = props;

  const setMedia = useSetRecoilState(modalMediaViewState);

  const handleClick = (e: React.MouseEvent) => {
    // 阻止事件向下传递
    e.stopPropagation();
    setMedia(media);
  };

  return (
    <Box
      cursor="pointer"
      onClick={handleClick}
      w="100%"
      {...restProps}>
      {children}
    </Box>
  );
}
