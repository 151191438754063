import { Link, Stack } from "@chakra-ui/react";
import { PrivacyOption, Provider } from "enums";
import { useRecoilValue } from "recoil";
import { profileMoreInfoQuery } from "states";
import { DeviceView, EmptyView, GeoView } from "views/components";
import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { Caption } from "./details-caption";
import { Label } from "./details-label";
import { ProfileFiltersSection } from "./filters-section";

export function ProfileMoreInfoView({ uid }: { uid: string }) {
  const profileMoreInfo = useRecoilValue(profileMoreInfoQuery(uid));

  if (!profileMoreInfo) return <EmptyView />;

  return (
    <Stack spacing={8}>
      <Stack>
        <Caption title="Accounts" />
        <CustomSimpleGrid
          spacing={8}
          columns={4}>
          {profileMoreInfo.accounts.map((account) => (
            <Label
              key={account.provider}
              title={Provider[account.provider]}
              value={[
                account.identifier,
                account.isUse && `(${account.isUse ? "Current" : undefined})`,
              ].join(" ")}
            />
          ))}
        </CustomSimpleGrid>
      </Stack>

      <Stack>
        <Caption title="Device" />
        <CustomSimpleGrid
          spacing={8}
          columns={4}>
          <Label title={"Current"}>
            <DeviceView device={profileMoreInfo.device.curr} />
          </Label>
          <Label title="Register">
            <DeviceView device={profileMoreInfo.device.register} />
          </Label>
          <Label
            title="Total"
            value={profileMoreInfo.device.count}
          />
        </CustomSimpleGrid>
      </Stack>

      <Stack>
        <Caption title="Location" />
        <CustomSimpleGrid
          spacing={8}
          columns={4}>
          <Label title={"Current"}>
            <GeoView geo={profileMoreInfo.location.curr} />
          </Label>
          <Label title="Register">
            <GeoView geo={profileMoreInfo.location.register} />
          </Label>
          <Label title="Total">
            <Link>{profileMoreInfo.location.count}</Link>
          </Label>
        </CustomSimpleGrid>
      </Stack>
      {/* Privacy Setting */}
      <Stack>
        <Caption title="Privacy Settings" />
        <CustomSimpleGrid
          spacing={8}
          columns={4}>
          <Label
            title="Partner"
            value={PrivacyOption[profileMoreInfo.privacySettings.pairPartner]}
          />
          <Label
            title="Age"
            value={PrivacyOption[profileMoreInfo.privacySettings.viewAge]}
          />
          <Label
            title="Profile"
            value={PrivacyOption[profileMoreInfo.privacySettings.viewProfile]}
          />
        </CustomSimpleGrid>
      </Stack>

      <ProfileFiltersSection
        seekingFilter={profileMoreInfo.seekingFilter}
        discoverFilter={profileMoreInfo.discoverFilter}
      />
    </Stack>
  );
}
