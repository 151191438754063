import {
  APIResponse,
  Secret,
  SecretsListParams,
  SecretToCreate,
  SecretToUpdate,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const createSecret = (secretToCreate: SecretToCreate) => {
  return axiosInstance.post(ApiURL.SecretsCreate, secretToCreate);
};

export const editSecret = (secretToUpdate: SecretToUpdate) => {
  return axiosInstance.post(ApiURL.SecretsEdit, secretToUpdate);
};

export const fetchSecrets = (params: SecretsListParams) => {
  return axiosInstance.post<APIResponse<Secret[]>>(
    ApiURL.SecretsSearch,
    params
  );
};
