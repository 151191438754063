import { Select, Stack, Text } from "@chakra-ui/react";
import { atom, useRecoilState } from "recoil";

export const passedStatusState = atom({
  key: "passed_status",
  default: "",
});

// TODO: 实现细节
export function PassedStatusFilter() {
  const [value, setValue] = useRecoilState(passedStatusState);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Status
      </Text>
      <Select
        size="sm"
        defaultValue={value}
        onChange={handleChange}>
        <option value="all">All</option>
        <option value="0">Passed</option>
        <option value="1">Rewind</option>
      </Select>
    </Stack>
  );
}
