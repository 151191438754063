import { Suspense } from "react";
import { useRecoilValue } from "recoil";

import { aboutMesQuery, aboutMesRequestIdState } from "states";

import { NextSubmitButton } from "./next-submit-button";

export function SubmitAboutMeButton() {
  return (
    <Suspense fallback={<></>}>
      <SubmitAboutMeView />
    </Suspense>
  );
}

function SubmitAboutMeView() {
  const aboutMes = useRecoilValue(aboutMesQuery);

  return (
    <NextSubmitButton
      bios={aboutMes}
      requestIdState={aboutMesRequestIdState}
    />
  );
}
