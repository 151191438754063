import { Box, Select } from "@chakra-ui/react";
import { RecoilState, useRecoilState } from "recoil";

export interface FilterSelectorOption {
  title: string;
  value: number;
}

export interface FilterSelectorProps {
  options: FilterSelectorOption[];
  placeholder?: string;
  state: RecoilState<number>;
  onReset?: () => void;
}

export function FilterSelector(props: FilterSelectorProps) {
  const [value, setValue] = useRecoilState(props.state);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setValue(+newValue);
    props.onReset && props.onReset();
  };

  return (
    <Box>
      <Select
        size="sm"
        placeholder={props.placeholder}
        defaultValue={value}
        onChange={handleChange}>
        {props.options.map((item, index) => (
          <option
            key={index}
            value={item.value}>
            {item.title}
          </option>
        ))}
      </Select>
    </Box>
  );
}
