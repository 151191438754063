import { AspectRatio } from "@chakra-ui/react";
import { useVideo } from "react-use";

interface VideoPlayerProps {
  url: string;
}

export function VideoPlayer(props: VideoPlayerProps) {
  // const { observe, inView } = useInView({
  //   trackVisibility: true,
  //   delay: 200,
  //   rootMargin: "-156px 0px", // 部分遮挡video player时，切换播放和暂停状态
  // });

  const [video] = useVideo(
    <video
      src={props.url}
      autoPlay
      muted
      loop
    />
  );

  return <AspectRatio ratio={10 / 16}>{video}</AspectRatio>;
}
