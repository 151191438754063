import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { submitPendingReports } from "apis";
import { ReportedProfile } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { reportedProfilesQuery } from "states";
import { CustomTable, CustomTr, ReportsList } from "views/components";
import { BasicUserInfoView } from "views/components/profile/BasicUserInfoView";

export function ReportedProfilesPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <ReportsTable />
    </Suspense>
  );
}

function ReportsTable() {
  const reportedProfiles = useRecoilValue(reportedProfilesQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>User</Th>
          <Th>Report Records</Th>
        </Tr>
      </Thead>

      <Tbody>
        {reportedProfiles.map((data, i) => (
          <ReportsTableItem key={i} data={data} />
        ))}
      </Tbody>
    </CustomTable>
  );
}

interface ReportsTableItemProps {
  data: ReportedProfile;
}

function ReportsTableItem(props: ReportsTableItemProps) {
  const handleClick = () => {
    // 调用接口消除report记录的pending状态
    submitPendingReports({
      beReportedUids: [props.data.user.id],
    });
  };

  return (
    <CustomTr>
      <Td w="156px">
        <BasicUserInfoView onClick={handleClick} userInfo={props.data.user} />
      </Td>

      <Td>
        <ReportsList data={props.data.beReporteds} />
      </Td>
    </CustomTr>
  );
}
