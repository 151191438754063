import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, PageParams, Timestamp, Timestamps } from "interfaces";

export enum CaptchaStatus {
  Active,
  Expired,
}

export enum Source {
  Phone = "PHONE",
  Email = "EMAIL",
}

export interface CaptchaToCreate {
  source: Source;
  account: string;
  code: string;
  expiryTime: Timestamp;
}

export interface CaptchaToUpdate extends CaptchaToCreate {
  id: string;
}
export interface Captcha extends CaptchaToUpdate, Timestamps {
  status: CaptchaStatus;
}

export interface CaptchasParams extends PageParams {}

export const searchCaptchas = (params: CaptchasParams) => {
  return axiosInstance.post<APIResponse<Captcha[]>>(
    ApiURL.CaptchaSearch,
    params
  );
};

export const addCaptcha = (captcha: CaptchaToCreate) => {
  return axiosInstance.post(ApiURL.CaptchaAdd, captcha);
};

export const editCaptcha = (captcha: CaptchaToUpdate) => {
  return axiosInstance.post(ApiURL.CaptchaEdit, captcha);
};

export const deleteCaptcha = (id: string) => {
  return axiosInstance.post(ApiURL.CaptchaDelete, { id });
};
