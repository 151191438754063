import { Button } from "@chakra-ui/react";
import _ from "lodash";
import { useSetRecoilState } from "recoil";
import { searchPageState } from "states";
import { PaginationContentProps } from "./PaginationContent";

export function PaginationPrevButton(props: PaginationContentProps) {
  const setPage = useSetRecoilState(props.state ?? searchPageState);

  const handleClickPre = () => {
    setPage((curr) => {
      const _page = _.cloneDeep(curr);
      return Math.max(_page - 1, 1);
    });
  };

  return <Button onClick={handleClickPre}>Pre</Button>;
}
