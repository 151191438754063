/* subscriptions */

import { fetchSubscriptions } from "apis";
import { SubscriptionsParams } from "interfaces";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchPageSizeState,
  searchPageState,
  searchUIDState,
} from "states/search";
import { getErrorMessage } from "utils";
import { platformsQuery, searchTimestampsQuery } from "views/components";

export const subscriptionsRespQuery = selector({
  key: "subscriptions/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const uid = get(searchUIDState);

    const params: SubscriptionsParams = {
      page: page,
      pageSize: pageSize,
    };

    if (!uid) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;

      const platforms = get(platformsQuery);
      params.platforms = platforms;
    } else {
      params.uid = uid;
    }

    try {
      const res = await fetchSubscriptions(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const subscriptionsQuery = selector({
  key: "subscriptions",
  get: ({ get }) => {
    return get(subscriptionsRespQuery)?.data ?? [];
  },
});

export const subscriptionsTotalQuery = selector({
  key: "subscriptions/total",
  get: ({ get }) => {
    return get(subscriptionsRespQuery)?.page?.total ?? 0;
  },
});
