import { Grid, GridItem, Stack, StackProps } from "@chakra-ui/react";
import React from "react";

export const ZStack = (props: StackProps) => {
  const { children, ...stackProps } = props;

  return (
    <Stack {...stackProps} w="100%" h="100%">
      <Grid
        w="100%"
        h="100%"
        justifyItems="center"
        alignItems="center">
        {React.Children.map(children, (children, index) => (
          <GridItem w="100%" h="100%" gridArea="1/1/1/1" zIndex={index}>
            {children}
          </GridItem>
        ))}
      </Grid>
    </Stack>

  );
};
