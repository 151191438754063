import { Td } from '@chakra-ui/react';
import { Platform } from 'enums';
import { PhoneModel } from 'interfaces';
import { timestampMsToDateStr } from 'utils';
import { CustomTr } from 'views/components';
import { EditPhoneModelButton } from './edit-model-button';

// PhoneModelsTableCell
export function PhoneModelsTableCell(props: PhoneModelsTableCellProps) {
  return (
    <CustomTr>
      <Td>
        <EditPhoneModelButton phoneModel={props.phoneModel} />
      </Td>

      <Td>{Platform[props.phoneModel.platform]}</Td>
      <Td>{props.phoneModel.model}</Td>
      <Td>{props.phoneModel.marketingName}</Td>

      <Td>{timestampMsToDateStr(props.phoneModel.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.phoneModel.updatedAt)}</Td>
    </CustomTr>
  );
}

interface PhoneModelsTableCellProps {
  phoneModel: PhoneModel;
}