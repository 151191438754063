import { Box, Tag } from "@chakra-ui/react";
import { RelationshipTag } from "enums";

export function RelationshipsTagView({ tag }: { tag?: RelationshipTag }) {
  return (
    <Box>
      {tag && (
        <Tag
          size="sm"
          colorScheme={tag === RelationshipTag.Single ? "green" : undefined}>
          {RelationshipTag[tag]}
        </Tag>
      )}
    </Box>
  );
}
