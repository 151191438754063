import { Flex, Spacer, useColorModeValue } from "@chakra-ui/react";
import { DEFAULT_COLOR_BG_DARK, DEFAULT_COLOR_BG_LIGHT } from "constant";
import { RouterURI } from "router/router.uri";
import { Logo } from "../other";
import { SidebarCapital } from "./sidebar-capital";
import { SidebarNavLink } from "./sidebar-nav-link";

export function Sidebar() {
  const bg = useColorModeValue(DEFAULT_COLOR_BG_LIGHT, DEFAULT_COLOR_BG_DARK);

  return (
    <Flex
      as="aside"
      h="100vh"
      direction="column"
      bg={bg}>
      <Logo />

      <Flex
        flex="1"
        flexDirection="column"
        overflow="auto"
        p={4}>
        <SidebarNavLink to={RouterURI.Dashboard} />

        <>
          <SidebarCapital title="In Review" />

          <SidebarNavLink to={RouterURI.NormalProfiles} />
          <SidebarNavLink to={RouterURI.ConfirmedProfiles} />
          <SidebarNavLink to={RouterURI.SuspiciousProfiles} />
          <SidebarNavLink to={RouterURI.BannedProfiles} />
          <SidebarNavLink to={RouterURI.ReportedProfiles} />
        </>

        <>
          <SidebarCapital title="Content Management" />

          <SidebarNavLink to={RouterURI.AboutMe} />
          <SidebarNavLink to={RouterURI.SeekingDescriptions} />
          <SidebarNavLink to={RouterURI.Photos} />
        </>

        <>
          <SidebarCapital title="Analytics" />

          <SidebarNavLink to={RouterURI.Devices} />
          <SidebarNavLink to={RouterURI.Profiles} />
          <SidebarNavLink to={RouterURI.Seekings} />
          <SidebarNavLink to={RouterURI.UnfinishedProfiles} />
        </>

        <>
          <SidebarCapital title="Relationships" />

          <SidebarNavLink to={RouterURI.Passed} />
          <SidebarNavLink to={RouterURI.Likes} />
          <SidebarNavLink to={RouterURI.Matches} />
          <SidebarNavLink to={RouterURI.Gifts} />
          <SidebarNavLink to={RouterURI.InstantChats} />
          <SidebarNavLink to={RouterURI.Blocks} />
        </>

        <>
          <SidebarCapital title="Payment Management" />

          <SidebarNavLink to={RouterURI.Subscriptions} />
          <SidebarNavLink to={RouterURI.InAppProducts} />
          <SidebarNavLink to={RouterURI.Orders} />
          <SidebarNavLink to={RouterURI.Flows} />
          <SidebarNavLink to={RouterURI.Transfers} />
          <SidebarNavLink to={RouterURI.Notifications} />
        </>

        <>
          <SidebarCapital title="App Manage" />

          <SidebarNavLink to={RouterURI.GenderRequests} />
          <SidebarNavLink to={RouterURI.PhoneModels} />
          <SidebarNavLink to={RouterURI.AppSecrets} />
          <SidebarNavLink to={RouterURI.Captchas} />
        </>

        <>
          <SidebarCapital title="Settings" />
          <SidebarNavLink to={RouterURI.Members} />
        </>

        <Spacer />
      </Flex>
    </Flex>
  );
}
