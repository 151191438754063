import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { errorState, globalToastState } from "states";

export function GlobalErrorHandler() {
  const [error, setError] = useRecoilState(errorState);
  const [globalToast, setGlobalToast] = useRecoilState(globalToastState);

  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast({
        status: "error",
        title: error,
        onCloseComplete: () => {
          setError("");
        },
      });
    }
    if (globalToast) {
      toast({
        ...globalToast,
        onCloseComplete: () => setGlobalToast(undefined),
      });
    }
  });

  return <></>;
}
