import { Button, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { MemberModalContent } from './member-modal-content';

// CreateMemberButton
export function CreateMemberButton() {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Button */}
      <Button colorScheme="pink" onClick={onOpen}>
        New Member
      </Button>

      {/* Modal */}
      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <MemberModalContent onClose={onClose} />
      </Modal>
    </>
  );
}
