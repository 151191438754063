import { Link, Text } from "@chakra-ui/react";
import { Link as ReactRouter } from "react-router-dom";
import { RouterURI } from "router/router.uri";

interface UserIdLinkProps {
  uid: string;
}

export function UserIdLink(props: UserIdLinkProps) {
  return (
    <Link
      as={ReactRouter}
      isExternal
      to={RouterURI.Profiles + "/" + props.uid}
      onClick={(e) => e.stopPropagation()} // 禁用点击冒泡，防止点击用户名时，选中profile
    >
      <Text size="md">#{props.uid.slice(-8)}</Text>
    </Link>
  );
}
