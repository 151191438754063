import {
  AspectRatio,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateMediaSource } from "apis";
import { Media } from "interfaces";
import { Suspense, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentMediaIdAtom,
  mediaSourceToUpdateState,
  multiMediaSourcesState,
} from "states";
import { getErrorMessage } from "utils";
import { Loading, MediaView, ZStack } from "views/components";

export function MultiMediaSourcesModalViewWrapper() {
  const [currentMediaId, setCurrentMediaId] =
    useRecoilState(currentMediaIdAtom);

  const handleClose = () => {
    setCurrentMediaId(undefined);
  };

  return (
    <Modal
      isCentered
      isOpen={currentMediaId ? true : false}
      onClose={handleClose}>
      <ModalOverlay onClick={handleClose} />

      <ModalContent>
        <ModalHeader>Multi Sources for: {currentMediaId}</ModalHeader>

        <MultiMediaSourcesViewWrapper />
      </ModalContent>
    </Modal>
  );
}

function MultiMediaSourcesViewWrapper() {
  return (
    <Suspense
      fallback={
        <ModalBody>
          <Loading />
        </ModalBody>
      }>
      <MultiMediaSourcesView />
    </Suspense>
  );
}

function MultiMediaSourcesView() {
  const medias = useRecoilValue(multiMediaSourcesState);
  const setCurrentMediaId = useSetRecoilState(currentMediaIdAtom);

  const handleCancel = () => {
    setCurrentMediaId(undefined);
  };

  return (
    <>
      <ModalBody>
        {medias.length === 0 && <Text>No Sources</Text>}
        <HStack>
          {medias.map((media, index) => (
            <MediaSourceView
              key={index}
              media={media}
            />
          ))}
        </HStack>
      </ModalBody>

      <ModalFooter>
        {medias.length > 0 && (
          <HStack>
            <Button onClick={handleCancel}>Cancel</Button>

            <SaveMediaSourceButton />
          </HStack>
        )}
      </ModalFooter>
    </>
  );
}

interface MediaSourceViewProps {
  media: Media;
}

function MediaSourceView(props: MediaSourceViewProps) {
  const setMedias = useSetRecoilState(multiMediaSourcesState);

  const handleClick = () => {
    setMedias((currVal) => {
      const index = currVal.findIndex((media) => media.id === props.media.id);
      if (index === -1) {
        return currVal;
      }
      return currVal.map((media) => {
        if (media.id === props.media.id) {
          return { ...media };
        } else {
          return { ...media, isMarked: false };
        }
      });
    });
  };

  return (
    <AspectRatio
      ratio={10 / 16}
      w="156px"
      onClick={handleClick}>
      <ZStack style={{ cursor: "pointer" }}>
        <MediaView media={props.media} />
      </ZStack>
    </AspectRatio>
  );
}

function SaveMediaSourceButton() {
  const toast = useToast();

  const mediaSourceToUpdate = useRecoilValue(mediaSourceToUpdateState);
  const setCurrentMediaId = useSetRecoilState(currentMediaIdAtom);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (!mediaSourceToUpdate) {
      toast({
        title: "No Changes, keep current source",
        status: "warning",
      });
    } else {
      try {
        await updateMediaSource(mediaSourceToUpdate.id);
        toast({
          title: "Change Media Source Success",
          description: "Effective in 5 minutes",
          status: "success",
        });
      } catch (error) {
        toast({
          title: "Change Media Source Failed",
          description: getErrorMessage(error),
          status: "error",
        });
      }
    }

    setCurrentMediaId(undefined);
    setIsSubmitting(false);
  };

  return (
    <Button
      colorScheme="pink"
      onClick={handleSubmit}
      isLoading={isSubmitting}>
      Save
    </Button>
  );
}
