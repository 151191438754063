export function randomString(length: number) {
  length = length || 32;

  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length;

  let n = "";
  for (let i = 0; i < length; i++) {
    n += t.charAt(Math.floor(Math.random() * a));
  }

  return n;
}
