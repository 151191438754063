import { RiskStatus } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { pendingProfilesQuery, pendingProfilesRequestIdState } from "states";
import { NextSubmitButton } from "./next-submit-button";

export function SubmitProfilesButton({
  riskStatus,
}: {
  riskStatus: RiskStatus;
}) {
  return (
    <Suspense>
      <SubmitButtonContent riskStatus={riskStatus} />
    </Suspense>
  );
}

function SubmitButtonContent({ riskStatus }: { riskStatus: RiskStatus }) {
  const pendingProfiles = useRecoilValue(pendingProfilesQuery(riskStatus));

  return (
    <NextSubmitButton
      profiles={pendingProfiles}
      requestIdState={pendingProfilesRequestIdState}
    />
  );
}
