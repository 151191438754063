import { Link, LinkProps, Text } from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterURI } from "router/router.uri";

interface SeekingIdProps {
  seekingId: string;
}

export function SeekingId(props: SeekingIdProps & LinkProps) {
  // 仅给Link传递LinkProps
  const { seekingId, ...restProps } = props;

  if (!seekingId) {
    return <></>;
  }

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.SeekingDescriptions + "/" + seekingId}
      // isExternal={props.isExternal}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      {...restProps}>
      <Text fontSize={props.size}>#{seekingId}</Text>
    </Link>
  );
}

export function SeekingIdContainer(props: SeekingIdProps & LinkProps) {
  const { seekingId, children, ...restProps } = props;

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.Seekings + "/" + seekingId}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      {...restProps}>
      {children}
    </Link>
  );
}
