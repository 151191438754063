import {
  HStack,
  Spacer,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  HOVER_COLOR_BG_DARK,
  HOVER_COLOR_BG_LIGHT,
  SELECTED_COLOR_BG,
  SELECTED_COLOR_CONTENT,
} from "constant";
import { StatusType } from "enums";
import { BasicProfile, ProfileText, Seeking } from "interfaces";
import _ from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  isSelectedSeekingIdQuery,
  rejectedSeekingIdsState,
  seekingQuery,
} from "states";
import { EmptyView, SeekingDescriptionView, StatusTag } from "views/components";
import { BasicUserInfoView } from "../profile/BasicUserInfoView";

export function SeekingView({ id }: { id: string }) {
  const seeking = useRecoilValue(seekingQuery(id));

  if (!seeking) return <EmptyView />;

  return <SeekingListCell seeking={seeking} />;
}

export function SeekingListCell({ seeking }: { seeking: Seeking }) {
  return (
    <Stack>
      <HStack>
        {/* Post */}
        <SeekingDescriptionSection description={seeking.description} />
        <SeekingOwnerAndPartnerSection
          owner={seeking.owner}
          partner={seeking.partner}
        />
      </HStack>
    </Stack>
  );
}

function SeekingDescriptionSection({
  description,
}: {
  description: ProfileText;
}) {
  const bg = useColorModeValue("gray.50", "gray.900");
  const hoverColor = useColorModeValue(
    HOVER_COLOR_BG_LIGHT,
    HOVER_COLOR_BG_DARK
  );

  const isSelected = useRecoilValue(isSelectedSeekingIdQuery(description.id));
  const selectSeeking = useSetRecoilState(rejectedSeekingIdsState);

  const handleSelect = () => {
    selectSeeking((curr) => _.xor(curr, [description.id]));
  };

  return (
    <Stack
      cursor="pointer"
      _hover={{
        borderColor: isSelected ? SELECTED_COLOR_CONTENT : hoverColor,
      }}
      borderWidth="1px"
      borderColor={isSelected ? SELECTED_COLOR_CONTENT : bg}
      p={4}
      borderRadius={14}
      bg={isSelected ? SELECTED_COLOR_BG : bg}
      color={isSelected ? SELECTED_COLOR_CONTENT : undefined}
      onClick={handleSelect}
      h="400px"
      w="400px">
      {/* id & statues */}
      <HStack>
        <Text>#{description.id}</Text>
        <StatusTag
          status={description.status}
          statusType={StatusType.Seeking}
        />
      </HStack>

      <Spacer />

      <SeekingDescriptionView description={description} />
      <Spacer />
      <HStack alignItems="flex-start">
        <StatusTag
          status={description.status}
          statusType={StatusType.Text}
        />
      </HStack>
    </Stack>
  );
}

function SeekingOwnerAndPartnerSection({
  owner,
  partner,
}: {
  owner?: BasicProfile;
  partner?: BasicProfile;
}) {
  const bg = useColorModeValue("gray.50", "gray.900");
  return (
    <Stack
      p={4}
      borderRadius={14}
      bg={bg}
      h="400px">
      <Stack>
        <HStack>
          <Tag>Owner</Tag>
          <Spacer />
        </HStack>
        <BasicUserInfoView userInfo={owner} />
      </Stack>
      <Stack>
        <HStack>
          <Tag>Partner</Tag>
          <Spacer />
        </HStack>
        <BasicUserInfoView userInfo={partner} />
      </Stack>
    </Stack>
  );
}
