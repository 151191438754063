import { HStack, Link, Stack, Tag, Text } from "@chakra-ui/react";
import { Geo, GeoType } from "interfaces";

export function GeoView({ geo }: { geo?: Geo }) {
  if (!geo) return <>--</>;

  return (
    <>
      <Stack spacing={0}>
        <HStack>
          <Link>{[geo.city, geo.country].join(", ")}</Link>
          <Tag size="sm">{GeoType[geo.type]}</Tag>
        </HStack>
        <Text
          fontSize="14px"
          opacity={0.5}>
          {[geo.latitude, geo.longitude].join(", ")}
        </Text>
      </Stack>
    </>
  );
}
