import { Text } from "@chakra-ui/react";

interface SidebarCapitalProps {
  title: string;
}

export function SidebarCapital(props: SidebarCapitalProps) {
  return (
    <Text
      px={2}
      pt={4}
      fontSize={12}
      fontWeight="semibold"
      opacity="0.4">
      {props.title}
    </Text>
  );
}
