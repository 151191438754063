import { RelationshipTag } from "enums";
import { atom, selector } from "recoil";
import { CheckboxFilter, FilterOption } from "./CheckboxFilter";

const relationshipStatuses = [RelationshipTag.Single, RelationshipTag.Couple];

const relationshipStatusValuesState = atom<number[]>({
  key: "rel_statues/values",
  default: [],
});

export const relationshipsStatusesQuery = selector({
  key: "rel_statues",
  get: ({ get }) => {
    const statues = get(relationshipStatusValuesState);

    if (statues.length > 0) {
      return statues.map((index) => relationshipStatuses[index]);
    } else {
      // 重要，一定要拷贝一下，若直接返回relationshipStatuses数组，不会触发数据刷新
      return [...relationshipStatuses];
    }
  },
});

export function RelationshipsTagFilter() {
  const options = relationshipStatuses.map((status, index) => {
    const opt: FilterOption = {
      title: RelationshipTag[status],
      value: index,
    };
    return opt;
  });

  return (
    <CheckboxFilter
      title="Relationship"
      state={relationshipStatusValuesState}
      options={options}
    />
  );
}
