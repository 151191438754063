import { fetchDiscoverProfiles } from "apis";
import { selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "../common";
import { searchPageSizeState } from "../search";

export const discoverProfilesRespQuery = selectorFamily({
  key: "discoverProfiles/resp",
  get:
    (uid: string) =>
    async ({ get }) => {
      try {
        const res = await fetchDiscoverProfiles({
          page: 1,
          pageSize: get(searchPageSizeState),
          uid: uid,
        });
        return res.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const discoverProfilesQuery = selectorFamily({
  key: "discoverProfiles",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(discoverProfilesRespQuery(uid))?.data ?? [];
    },
});

export const discoverProfilesCountQuery = selectorFamily({
  key: "discoverProfiles/count",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(discoverProfilesRespQuery(uid))?.page?.total ?? 0;
    },
});
