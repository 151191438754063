import { Gender, SeekingJoinWay, Status } from "enums";
import {
  APIResponse,
  ListParams,
  PageParams,
  ProfileText,
  Seeking,
  SeekingMember,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export interface SeekingDescriptionsParams extends ListParams {
  gender?: Gender;
  status?: Status;
}
// fetchSeekingDescriptions 获取待审核的招募描述
export function fetchSeekingDescriptions(params: SeekingDescriptionsParams) {
  return axiosInstance.post<APIResponse<ProfileText[]>>(
    ApiURL.SeekingDescriptionsSearch,
    params
  );
}

export interface SeekingsParams extends ListParams {
  uid?: string;
  gender?: Gender;
  status?: Status;
}

// fetchSeekings 获取指定
export function fetchSeekings(params: SeekingsParams) {
  return axiosInstance.post<APIResponse<Seeking[]>>(
    ApiURL.SeekingsSearch,
    params
  );
}

// fetchSeeking 获取指定id的seeking
export function fetchSeeking(id: string) {
  return axiosInstance.post<APIResponse<Seeking>>(
    ApiURL.SeekingDetails + "/" + id
  );
}

export interface SeekingMembersParams extends PageParams {
  aid?: string;
  gid?: string;
  joinWays?: SeekingJoinWay[];
}

// fetchSeekingMembers 获取指定招募的组成员
export function fetchSeekingMembers(params: SeekingMembersParams) {
  return axiosInstance.post<APIResponse<SeekingMember[]>>(
    ApiURL.SeekingMembersSearch,
    params
  );
}
