import { Link, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Member } from 'interfaces';
import { MemberModalContent } from './member-modal-content';

interface MemberIdLinkProps {
  member: Member;
}

// EditMemberButton
export function MemberIdLink(props: MemberIdLinkProps) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link onClick={onOpen}>
        {props.member.id}
      </Link>

      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <MemberModalContent onClose={onClose} member={props.member} />
      </Modal>
    </>
  );
}


