import { IconButton, Tooltip, useClipboard, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BiCopyAlt } from "react-icons/bi";

export function CopyButton(props: CopyButtonProps) {
  const { hasCopied, onCopy } = useClipboard(props.value);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copy success",
        status: "success",
      });
    }
  }, [hasCopied, toast]);
  return (
    <Tooltip label={props.value}>
      <IconButton
        aria-label={props.value}
        onClick={onCopy}
        icon={<BiCopyAlt />}
      />
    </Tooltip>
  );
}
interface CopyButtonProps {
  value: string;
}
