import { Box, Link, LinkProps, Stack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/router.uri";
import { relationshipCountQuery } from "states";
import { RelationshipDirection } from "../search";
import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { Caption } from "./details-caption";
import { Label } from "./details-label";

export function RelationshipCountView({ uid }: { uid: string }) {
  const relationshipCount = useRecoilValue(relationshipCountQuery(uid));

  return (
    <Stack>
      <Caption title="Relationships" />
      <CustomSimpleGrid>
        <Label
          title="Liked"
          value={relationshipCount?.liked}>
          <RelationshipLink
            value={relationshipCount?.liked}
            to={RouterURI.Likes}
            uid={uid}
            direction={RelationshipDirection.From}
          />
        </Label>

        <Label title="Gift Sent">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Gifts}
            value={relationshipCount?.giftSent}
            direction={RelationshipDirection.From}
          />
        </Label>

        <Label title="Instant Chat Sent">
          <RelationshipLink
            uid={uid}
            to={RouterURI.InstantChats}
            value={relationshipCount?.instantChatSent}
            direction={RelationshipDirection.From}
          />
        </Label>

        <Label title="Matches">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Matches}
            value={relationshipCount?.matches}
            direction={RelationshipDirection.From}
          />
        </Label>

        <Label title="Passed">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Passed}
            value={relationshipCount?.passed}
            direction={RelationshipDirection.From}
          />
        </Label>

        <Label title="Blocked">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Blocks}
            value={relationshipCount?.blocked}
            direction={RelationshipDirection.From}
          />
        </Label>
      </CustomSimpleGrid>

      <CustomSimpleGrid>
        <Label title="Liked Me">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Likes}
            value={relationshipCount?.likedMe}
            direction={RelationshipDirection.To}
          />
        </Label>

        <Label title="Gift Received">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Gifts}
            value={relationshipCount?.giftReceived}
            direction={RelationshipDirection.To}
          />
        </Label>

        <Label title="Instant Chat Received">
          <RelationshipLink
            uid={uid}
            to={RouterURI.InstantChats}
            value={relationshipCount?.instantChatReceived}
            direction={RelationshipDirection.To}
          />
        </Label>

        {/* 占位，对齐数据 */}
        <Box />

        <Label title="Passed Me">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Passed}
            value={relationshipCount?.passedMe}
            direction={RelationshipDirection.From}
          />
        </Label>
        <Label title="Blocked Me">
          <RelationshipLink
            uid={uid}
            to={RouterURI.Blocks}
            value={relationshipCount?.blockedMe}
            direction={RelationshipDirection.From}
          />
        </Label>
      </CustomSimpleGrid>
    </Stack>
  );
}

type RelationshipLinkProps = {
  value: string | number | undefined;
  to: RouterURI;
  uid: string;
  direction: RelationshipDirection;
};

// example: /passed?uid="abc"
function RelationshipLink(props: RelationshipLinkProps & LinkProps) {
  const { value, to, uid, direction, ...linkProps } = props;

  return (
    <Link
      isExternal
      as={RouterLink}
      to={to + `?uid="${uid}"&direction=${direction}`}
      {...linkProps}>
      {value}
    </Link>
  );
}
