import {
  AspectRatio,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { CARD_WIDTH } from "constant";
import { RelationshipTag } from "enums";
import { DiscoverProfile } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { discoverProfilesCountQuery, discoverProfilesQuery } from "states";
import { timestampMsToAge } from "utils";
import { Pagination, UserIdContainer } from "views/components";

export function DiscoverView({ uid }: { uid: string }) {
  return (
    <Stack>
      <Suspense fallback={<Spinner />}>
        <ProfileWrap uid={uid} />
      </Suspense>
      <Pagination totalState={discoverProfilesCountQuery(uid)} />
    </Stack>
  );
}
export function ProfileWrap({ uid }: { uid: string }) {
  const profiles = useRecoilValue(discoverProfilesQuery(uid));

  const generateSubTitle = (profile: DiscoverProfile) => {
    let strArray: string[] = [];

    // 年龄
    if (profile.birthday) {
      const age = timestampMsToAge(profile.birthday);
      strArray.push(age.toString());
    }

    // single / couple
    if (profile.relationshipTag) {
      strArray.push(RelationshipTag[profile.relationshipTag]);
    }

    // distance / city
    if (profile.distance < 1) {
      strArray.push("Less than 1 km");
    } else if (profile.distance > 50 && profile.currInfo?.geo?.city) {
      strArray.push(profile.currInfo?.geo?.city);
    } else {
      strArray.push(profile.distance + " km");
    }

    return strArray.join(", ");
  };

  return (
    <Wrap>
      {profiles.map((profile) => (
        <WrapItem key={profile.id}>
          <UserIdContainer
            uid={profile.id}
            isExternal>
            <Stack
              spacing="10px"
              w={CARD_WIDTH}>
              <AspectRatio ratio={10 / 16}>
                <Image
                  src={profile.avatar?.url}
                  objectFit="cover"
                />
              </AspectRatio>
              <Stack spacing="5px">
                <Heading size="xs">{profile.name}</Heading>
                <Text
                  fontSize={12}
                  opacity={0.5}>
                  {generateSubTitle(profile)}
                </Text>
              </Stack>
            </Stack>
          </UserIdContainer>
        </WrapItem>
      ))}
    </Wrap>
  );
}
