import { fetchProfile } from "apis";
import dayjs from "dayjs";
import { ProfileToUpdate } from "interfaces";
import _ from "lodash";
import { atom, DefaultValue, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "../common";

export const profileRequestIdState = atom({
  key: "profile/requestId",
  default: dayjs().valueOf(),
});

export const profileRespQuery = selectorFamily({
  key: "profile/resp",
  get:
    (uid: string) =>
    async ({ get }) => {
      get(profileRequestIdState);

      try {
        const resp = await fetchProfile(uid);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const profileQuery = selectorFamily({
  key: "profile",
  get:
    (uid: string) =>
    ({ get }) =>
      get(profileRespQuery(uid))?.data,
});

export const editedProfilesState = atom<ProfileToUpdate[]>({
  key: "profiles/edited",
  default: [],
});

export const editProfileQuery = selectorFamily({
  key: "profile/edit",
  get:
    (uid: string) =>
    ({ get }) => {
      const editedProfiles = get(editedProfilesState);

      return editedProfiles.find((p) => p.id === uid);
    },
  set:
    (uid: string) =>
    ({ get, set }, newValue) => {
      const editedProfiles = get(editedProfilesState);

      const profiles = _.cloneDeep(editedProfiles);

      if (!newValue || newValue instanceof DefaultValue) {
        _.remove(profiles, (p) => p.id === uid);
      } else {
        const index = editedProfiles.findIndex((p) => p.id === uid);
        if (index !== -1) {
          profiles[index] = newValue;
        } else {
          profiles.push(newValue);
        }
      }

      set(editedProfilesState, profiles);
    },
});