import { fetchRelationshipCount } from "apis";
import { selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "../common";
import { profileRequestIdState } from "./profile";

export const relationshipCountRespQuery = selectorFamily({
  key: "relationshipCount/resp",
  get:
    (uid: string) =>
    async ({ get }) => {
      get(profileRequestIdState);

      try {
        const resp = await fetchRelationshipCount(uid);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const relationshipCountQuery = selectorFamily({
  key: "relationshipCount",
  get:
    (uid: string) =>
    ({ get }) =>
      get(relationshipCountRespQuery(uid))?.data,
});
