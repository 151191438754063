import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { deleteProfile } from "apis";
import dayjs from "dayjs";
import { Profile } from "interfaces";
import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState, profileRequestIdState } from "states";
import { getErrorMessage } from "utils";

export function DeleteProfileButton({ profile }: { profile: Profile }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const setRequestId = useSetRecoilState(profileRequestIdState);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await deleteProfile(profile.id);
      // 刷新数据
      setRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <MenuItem color="red" onClick={onOpen}>
        Delete
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Profile
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button isLoading={isSubmitting} onClick={handleSubmit} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
