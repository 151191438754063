import { Link, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/router.uri";
import { profileQuery } from "states";
import { timestampMsToDateStr } from "utils";
import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { Caption } from "./details-caption";
import { Label } from "./details-label";

export function ProfilePaymentView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));

  if (!profile) return <></>;

  const { iapOrder } = profile;

  return (
    <>
      <Caption title="Payment" />
      <CustomSimpleGrid>
        {/* Sub */}
        <Label title="Subscription">
          {iapOrder.productId ? (
            <PaymentLink
              value={iapOrder.productId}
              to={RouterURI.Subscriptions}
              uid={uid}
            />
          ) : (
            <>--</>
          )}
        </Label>

        <Label title="originalTransactionId">
          {iapOrder.productId ? (
            <PaymentLink
              value={iapOrder.originalTransactionId}
              to={RouterURI.Orders}
              uid={uid}
              tab={3}
            />
          ) : (
            <>--</>
          )}
        </Label>

        <Label
          title="Purchase Date"
          value={
            iapOrder.purchaseDate
              ? timestampMsToDateStr(iapOrder.purchaseDate)
              : "--"
          }
        />
        <Label
          title="Expires Date"
          value={
            iapOrder.expiresDate
              ? timestampMsToDateStr(iapOrder.expiresDate)
              : "--"
          }
        />
      </CustomSimpleGrid>

      <CustomSimpleGrid>
        <Label title="Gifts Flows">
          <PaymentLink
            value={iapOrder.giftFlowCount}
            to={RouterURI.Flows}
            uid={uid}
            tab={1}
          />
        </Label>
        <Label title="Gifts Orders">
          <PaymentLink
            value={iapOrder.giftOrderCount}
            to={RouterURI.Orders}
            uid={uid}
            tab={1}
          />
        </Label>

        <Label title="Gifts">
          <PaymentLink
            value={iapOrder.buyGiftCount}
            to={RouterURI.InAppProducts}
            uid={uid}
            tab={1}
          />
        </Label>
      </CustomSimpleGrid>

      <CustomSimpleGrid>
        <Label title="Boosts Flows">
          <PaymentLink
            value={iapOrder.boostFlowCount}
            to={RouterURI.Flows}
            uid={uid}
            tab={0}
          />
        </Label>
        <Label title="Boosts Orders">
          <PaymentLink
            value={iapOrder.boostOrderCount}
            to={RouterURI.Orders}
            uid={uid}
            tab={0}
          />
        </Label>

        <Label title="Boosts">
          <PaymentLink
            value={iapOrder.buyBoostCount}
            to={RouterURI.InAppProducts}
            uid={uid}
            tab={0}
          />
        </Label>
      </CustomSimpleGrid>

      <CustomSimpleGrid>
        <Label title="Instant Chats Flows">
          <PaymentLink
            value={iapOrder.instantChatFlowCount}
            to={RouterURI.Flows}
            uid={uid}
            tab={2}
          />
        </Label>
        <Label title="Instant Chats Orders">
          <PaymentLink
            value={iapOrder.instantChatOrderCount}
            to={RouterURI.Orders}
            uid={uid}
            tab={2}
          />
        </Label>

        <Label title="Instant Chats (IAP)">
          <PaymentLink
            value={iapOrder.buyInstantChatCount}
            to={RouterURI.InAppProducts}
            uid={uid}
            tab={2}
          />
        </Label>

        <Label title="Instant Chats (Reward)">
          <PaymentLink
            value={iapOrder.giveInstantChatCount}
            to={RouterURI.InAppProducts}
            uid={uid}
            tab={2}
          />
        </Label>
      </CustomSimpleGrid>
    </>
  );
}

type PaymentLinkProps = {
  value: string | number | undefined;
  uid: string;
  to: RouterURI;
  tab?: number; // 0: boosts, 1: gifts, 2: instant chats, 3: subscription
};

export function PaymentLink(props: PaymentLinkProps & LinkProps) {
  const { value, uid, to, tab, ...linkProps } = props;

  return (
    <Link
      isExternal
      as={RouterLink}
      to={to + `?uid="${uid}"&tab=${tab ?? 0}`}
      {...linkProps}>
      {value}
    </Link>
  );
}

type TransferLinkProps = {
  value: string | number | undefined;
  originalTransactionId: string;
};

export function TransferLink(props: TransferLinkProps & LinkProps) {
  const { value, originalTransactionId, ...linkProps } = props;

  return (
    <Link
      isExternal
      as={RouterLink}
      to={
        RouterURI.Transfers +
        `?original_transaction_id="${originalTransactionId}"`
      }
      {...linkProps}>
      {value}
    </Link>
  );
}
