import { fetchUserBoosts, fetchUserGifts, fetchUserInstantChats } from "apis";
import { VirtualProductListParams } from "interfaces";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { searchPageSizeState, searchPageState, searchUIDState } from "states";
import { getErrorMessage } from "utils";
import { searchTimestampsQuery } from "views/components";
import { errorState } from "../common";

/* Instant Chats */

export const instantChatsRespQuery = selector({
  key: "instantChats/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const uid = get(searchUIDState);

    const params: VirtualProductListParams = {
      page: page,
      pageSize: pageSize,
      uid: uid,
    };

    if (!uid) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;
    }

    try {
      const res = await fetchUserInstantChats(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const instantChatsQuery = selector({
  key: "instantChats",
  get: ({ get }) => {
    return get(instantChatsRespQuery)?.data ?? [];
  },
});

export const instantChatsTotalQuery = selector({
  key: "instantChats/total",
  get: ({ get }) => {
    return get(instantChatsRespQuery)?.page?.total ?? 0;
  },
});

/* Gifts */

export const giftsRespQuery = selector({
  key: "gifts/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const uid = get(searchUIDState);

    const params: VirtualProductListParams = {
      page: page,
      pageSize: pageSize,
      uid: uid,
    };

    if (!uid) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;
    }

    try {
      const res = await fetchUserGifts(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const giftsQuery = selector({
  key: "gifts",
  get: ({ get }) => {
    return get(giftsRespQuery)?.data ?? [];
  },
});

export const giftsTotalQuery = selector({
  key: "gifts/total",
  get: ({ get }) => {
    return get(giftsRespQuery)?.page?.total ?? 0;
  },
});

/* Boosts */

export const boostsRespQuery = selector({
  key: "boosts/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const uid = get(searchUIDState);

    const params: VirtualProductListParams = {
      page: page,
      pageSize: pageSize,
    };

    if (!uid) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;
    } else {
      params.uid = uid;
    }

    try {
      const res = await fetchUserBoosts(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const boostsQuery = selector({
  key: "boosts",
  get: ({ get }) => {
    return get(boostsRespQuery)?.data ?? [];
  },
});

export const boostsTotalQuery = selector({
  key: "boosts/total",
  get: ({ get }) => {
    return get(boostsRespQuery)?.page?.total ?? 0;
  },
});
