import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth } from "lib/firebase";
import { atom } from "recoil";
import { getErrorMessage } from "utils";

export const authAtom = atom<User | null | undefined>({
  key: "auth",
  default: undefined,
  effects: [
    ({ setSelf }) => {
      onAuthStateChanged(
        auth,
        async (user) => {
          if (!user) {
            // 退出登录/未登录
            setSelf(null);
          } else {
            // 获取角色权限

            const result = await user.getIdTokenResult();
            const { role } = result.claims;

            try {
              const roles = role as string[];

              if (!role || !roles.includes("unicorn")) {
                console.log("missing permission, role: " + roles);
                signOut(getAuth());
                setSelf(null);
              } else {
                console.log("auth success, uid: ", user.uid);

                // recoil会直接修改user对象，将其变更为只读，使用以下方式修复
                // 参照：https://github.com/firebase/firebase-js-sdk/issues/5722
                const _user = JSON.parse(JSON.stringify(user)) as User;

                setSelf(_user);
              }
            } catch (error) {
              console.log("invalid role: ", error);
              setSelf(null);
            }
          }
        },
        (error) => {
          console.log("auth error: ", getErrorMessage(error));
          setSelf(null);
        }
      );
    },
  ],
});
