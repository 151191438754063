import { MediaType } from "enums";
import { APIResponse, Media, MediasParams } from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const fetchMedia = (params: MediasParams) => {
  return axiosInstance.post<APIResponse<Media[]>>(ApiURL.MediaSearch, {
    ...params,
    types: [MediaType.Image, MediaType.ShortVideo],
  });
};

export const fetchMultiSourcesBy = (mediaId: string) => {
  return axiosInstance.post<APIResponse<Media[]>>(ApiURL.MediaSourcesSearch, {
    id: mediaId,
  });
};

export const updateMediaSource = (id: string) => {
  return axiosInstance.post(ApiURL.UpdateMediaSource, { id });
};
