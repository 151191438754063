import { Box, HStack, Stack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import {
  boostsQuery,
  boostsTotalQuery,
  giftsQuery,
  giftsTotalQuery,
  instantChatsQuery,
  instantChatsTotalQuery,
} from "states";
import {
  CustomTabs,
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
  SearchUIDInput,
  tabState,
} from "views/components";
import { InAppProductsView } from "./components/InAppProductsView";

const configuration = [
  {
    name: "Boosts",
    component: <InAppProductsView state={boostsQuery} />,
    totalState: boostsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
  {
    name: "Gifts",
    component: <InAppProductsView state={giftsQuery} />,
    totalState: giftsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
  {
    name: "Instant Chats",
    component: <InAppProductsView state={instantChatsQuery} />,
    totalState: instantChatsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
];

export function InAppProductsPage() {
  const tabIndex = useRecoilValue(tabState);

  const currentTab =
    configuration[Math.min(tabIndex, configuration.length - 1)];

  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={currentTab.totalState} />
      </HeaderBox>

      <HStack>
        {currentTab.trainingItems.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      <CustomTabs tabs={configuration.map((item) => item.name)}>
        {configuration.map((item) => (
          <Box
            overflowX="scroll"
            key={item.name}>
            {item.component}
          </Box>
        ))}
      </CustomTabs>

      <Pagination totalState={currentTab.totalState} />
    </Stack>
  );
}
