export enum BodyType {
  "Thin" = 1,
  "Average" = 2,
  "Athletic" = 4,
  "Jacked" = 8,
  "Curvy" = 16,
  "Full figured" = 32,
  "A little extra" = 64,
  "A lot extra" = 128,
}

export enum Ethnicity {
  Asian = 1,
  Black = 2,
  "Hispanic / Latin" = 4,
  Indian = 8,
  "Middle Eastern" = 16,
  "Native American" = 32,
  "Pacific Islander" = 64,
  White = 128,
  Other = 256,
  "Prefer not to say" = 512,
}

export enum RelationshipStatus {
  Single = 1,
  Married = 2,
  "In a relationship" = 4,
  Divorced = 8,
  "It’s complicated" = 16,
  "Prefer not to say" = 32,
}

export enum Kink {
  "BDSM" = 1,
  "Fetish" = 2,
  "Role play" = 4,
  "Watch others" = 8,
  "Switch" = 16,
  "Too kinky to put here" = 32,
  "Dominant" = 64,
  "Submissive" = 128,
  "Flogger" = 256,
  "Polyamory" = 512,
}

// PrivacyOption 可见权限控制枚举
export enum PrivacyOption {
  Default = 0,
  Everyone = 1,
  Friends = 2,
  OnlyMe = 4,
}

// filter 中设置数据排列方式
export enum SortBy {
  "Last Login" = 1,
  Distance = 2,
}

// filter 中设置展示unicorns或couples
export enum ShowMe {
  Singles = 2,
  Couples = 4,
}
