import { fetchAdminMembers } from "apis";
import dayjs from "dayjs";
import { Member } from "interfaces";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const memberQuery = selectorFamily<Member, string>({
  key: "memberQuery",
  get: (memberId) => async () => {
    let members: Member[] = [];

    try {
      const res = await fetchAdminMembers({ id: memberId });
      members = res.data.data ?? [];
    } catch (error) {
      // TODO: catch error
    }

    return members[0];
  },
});

export const currentMemberIdState = atom<string>({
  key: "currentMemberIdState",
  default: "",
});

export const currentMemberQuery = selector({
  key: "currentMemberQuery",
  get: async ({ get }) => {
    let members: Member[] = [];

    try {
      const resp = await fetchAdminMembers({ id: get(currentMemberIdState) });
      members = resp.data.data ?? [];
    } catch (error) {
      // TODO: catch error
    }

    return members[0];
  },
});

export const membersRequestIdState = atom({
  key: "members/requestId",
  default: dayjs().valueOf(),
});

export const membersPageState = atom({
  key: "members/page",
  default: 1,
});

export const membersRespQuery = selector({
  key: "members/resp",
  get: async ({ get }) => {
    get(membersRequestIdState);

    try {
      const resp = await fetchAdminMembers({
        page: get(membersPageState),
        pageSize: get(searchPageSizeState),
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const membersQuery = selector({
  key: "members",
  get: ({ get }) => get(membersRespQuery)?.data ?? [],
});

export const membersCountQuery = selector({
  key: "members/count",
  get: ({ get }) => get(membersRespQuery)?.page?.total ?? 0,
});
