import { ProfileText } from "interfaces";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import { profileQuery, profileRequestIdState } from "states";
import { NextSubmitButton } from "./next-submit-button";

export function SubmitProfileButton({ uid }: { uid: string }) {
  const profileDetail = useRecoilValue(profileQuery(uid));

  if (!profileDetail) return <></>;

  let photos = profileDetail.photos;
  if (profileDetail.verify) {
    const _photos = _.cloneDeep(photos);
    _photos.push(profileDetail.verify);
    photos = _photos;
  }

  const texts: ProfileText[] = [];
  if (profileDetail.aboutMe) texts.push(profileDetail.aboutMe);
  if (profileDetail.seekingDescription)
    texts.push(profileDetail.seekingDescription);

  return (
    <NextSubmitButton
      profiles={[profileDetail]}
      bios={texts}
      requestIdState={profileRequestIdState}
    />
  );
}
