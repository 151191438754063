import {
  Avatar,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Gender, RelationshipTag, SexualOrientation, StatusType } from "enums";
import { User } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { profilesCountQuery, profilesPageState, profilesQuery } from "states";
import { timestampMsToAge, timestampMsToDateStr } from "utils";
import {
  CustomTable,
  CustomTr,
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
  ProfileStatusFilter,
  RelationshipsTagFilter,
  RiskStatusFilter,
  RiskStatusTag,
  StatusTag,
  UserIdContainer,
} from "views/components";

export function ProfilesPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={profilesCountQuery} />
        <Spacer />
      </HeaderBox>

      <HStack alignItems="flex-end">
        <RelationshipsTagFilter />
        <ProfileStatusFilter />
        <RiskStatusFilter />
        <DateFilter />
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <UsersTable />
      </Suspense>

      {/* Pagination */}
      <Pagination state={profilesPageState} totalState={profilesCountQuery} />
    </Stack>
  );
}

function UsersTable() {
  const users = useRecoilValue(profilesQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>Avatar</Th>
          <Th>Name</Th>
          <Th>Age</Th>
          <Th>Gender</Th>
          <Th>Sexual</Th>
          <Th>Relationship</Th>
          <Th>City</Th>
          <Th>Country</Th>
          <Th>Status</Th>
          <Th>Risk Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {users.map((user) => (
          <UsersTableItem key={user.id} user={user} />
        ))}
      </Tbody>
    </CustomTable>
  );
}

function UsersTableItem({ user }: { user: User }) {
  return (
    <CustomTr>
      <Td>
        <UserIdContainer uid={user.id} isExternal>
          <Avatar
            name={user.name}
            src={user.avatar?.thumbnailUrl ?? user.avatar?.url}
          />
        </UserIdContainer>
      </Td>
      <Td>
        <UserIdContainer uid={user.id} isExternal>
          {user.name && <Text>{user.name}</Text>}
        </UserIdContainer>
      </Td>
      <Td>{user.birthday ? timestampMsToAge(user.birthday) : "--"}</Td>
      <Td>{user.gender ? Gender[user.gender] : "--"}</Td>
      <Td>
        {user.sexualOrientation
          ? SexualOrientation[user.sexualOrientation]
          : "--"}
      </Td>
      <Td>
        {user.relationshipTag ? RelationshipTag[user.relationshipTag] : "--"}
      </Td>
      <Td>{user.currInfo?.geo.city}</Td>
      <Td>{user.currInfo?.geo.country}</Td>
      <Td>
        <StatusTag status={user.status} statusType={StatusType.Profile} />
      </Td>
      <Td>
        <RiskStatusTag riskStatus={user.riskStatus} />
      </Td>
      <Td>{timestampMsToDateStr(user.createdAt)}</Td>
      <Td>{timestampMsToDateStr(user.updatedAt)}</Td>
    </CustomTr>
  );
}
