import { Box, Spacer, Stack } from "@chakra-ui/react";
import { RiskStatus } from "enums";
import { Outlet, useLocation } from "react-router-dom";
import { RecoilValueReadOnly } from "recoil";
import { RouterURI } from "router/router.uri";
import {
  aboutMesCountQuery,
  mediasCountQuery,
  pendingProfilesCountQuery,
  reportedProfilesCountQuery,
} from "states";
import { seekingDescriptionsCountQuery } from "states/seeking/description";
import {
  GenderTabs,
  HeaderBox,
  MainHeader,
  OpenAllReports,
  SubmitAboutMeButton,
  SubmitMediaButton,
  SubmitProfilesButton,
  SubmitSeekingsButton,
} from "views/components";

export function ReviewLayout() {
  const location = useLocation();

  const configuration = configurations.find(
    (conf) => conf.uri === location.pathname
  );

  if (!configuration) return <></>;

  return (
    <Stack>
      <GenderTabs />
      <HeaderBox>
        <MainHeader totalState={configuration?.totalState} />

        <Spacer />
        {configuration.trainingItems &&
          configuration.trainingItems.map((element, index) => (
            <Box key={index}>{element}</Box>
          ))}
      </HeaderBox>

      <Stack overflowX="scroll">
        <Outlet />
      </Stack>
    </Stack>
  );
}

type Configuration = {
  uri: RouterURI;
  totalState: RecoilValueReadOnly<number>;
  trainingItems?: JSX.Element[];
};

export const configurations: Configuration[] = [
  {
    uri: RouterURI.NormalProfiles,
    totalState: pendingProfilesCountQuery(RiskStatus.Normal),
    trainingItems: [<SubmitProfilesButton riskStatus={RiskStatus.Normal} />],
  },
  {
    uri: RouterURI.ConfirmedProfiles,
    totalState: pendingProfilesCountQuery(RiskStatus.Confirmed),
    trainingItems: [<SubmitProfilesButton riskStatus={RiskStatus.Confirmed} />],
  },
  {
    uri: RouterURI.SuspiciousProfiles,
    totalState: pendingProfilesCountQuery(RiskStatus.Suspicious),
    trainingItems: [
      <SubmitProfilesButton riskStatus={RiskStatus.Suspicious} />,
    ],
  },
  {
    uri: RouterURI.BannedProfiles,
    totalState: pendingProfilesCountQuery(RiskStatus.Banned),
    trainingItems: [<SubmitProfilesButton riskStatus={RiskStatus.Banned} />],
  },
  {
    uri: RouterURI.ReportedProfiles,
    totalState: reportedProfilesCountQuery,
    trainingItems: [<OpenAllReports />],
  },
  {
    uri: RouterURI.AboutMe,
    totalState: aboutMesCountQuery,
    trainingItems: [<SubmitAboutMeButton />],
  },
  {
    uri: RouterURI.SeekingDescriptions,
    totalState: seekingDescriptionsCountQuery,
    trainingItems: [<SubmitSeekingsButton />],
  },
  {
    uri: RouterURI.Photos,
    totalState: mediasCountQuery,
    trainingItems: [<SubmitMediaButton />],
  },
];
