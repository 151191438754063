/* API */

import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, BasicProfile, ListParams, Timestamps } from "interfaces";

export const fetchRelationshipMatch = (params: MatchParams) => {
  return axiosInstance.post<MatchRelationshipsResponse>(
    ApiURL.RelationshipMatchSearch,
    params
  );
};

/* Statues */
export enum MatchStatus {
  Matched = 0,
  Unmatched = 1,
}

/* Params */

export interface MatchParams extends ListParams {
  fromUid?: string;
  toUid?: string;
  status?: MatchStatus;
}

/* Response */
export interface MatchRelationship extends Timestamps {
  id: string;
  fromUser: BasicProfile;
  toUser: BasicProfile;
  status: MatchStatus;
}

type MatchRelationshipsResponse = APIResponse<MatchRelationship[]>;
