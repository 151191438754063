/* Order */

import { fetchOrders } from "apis";
import { ProductType } from "enums";
import { OrdersParams } from "interfaces";
import { selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchOriginalTransactionIdState,
  searchPageSizeState,
  searchPageState,
  searchUIDState,
} from "states/search";
import { getErrorMessage } from "utils";
import { platformsQuery, searchTimestampsQuery } from "views/components";

const ordersRespQuery = selectorFamily({
  key: "orders/resp",
  get:
    (productType: ProductType) =>
    async ({ get }) => {
      const page = get(searchPageState);
      const pageSize = get(searchPageSizeState);

      const params: OrdersParams = {
        productSubclass: [productType],
        page: page,
        pageSize: pageSize,
      };

      const originalTransactionId = get(searchOriginalTransactionIdState);
      const uid = get(searchUIDState);

      if (!!originalTransactionId) {
        params.originalTransactionId = originalTransactionId;
      } else if (!!uid) {
        params.uid = uid;
      } else {
        const dateRange = get(searchTimestampsQuery);

        params.createdStart = dateRange.createdStart;
        params.createdEnd = dateRange.createdEnd;
        params.platforms = get(platformsQuery);
      }

      try {
        const resp = await fetchOrders(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const subscriptionOrdersQuery = selector({
  key: "orders/subscription",
  get: ({ get }) => {
    return get(ordersRespQuery(ProductType.Subscription))?.data ?? [];
  },
});

export const subscriptionOrdersTotalQuery = selector({
  key: "orders/subscription/total",
  get: ({ get }) => {
    const resp = get(ordersRespQuery(ProductType.Subscription));

    return resp?.page?.total ?? 0;
  },
});

export const boostOrdersQuery = selector({
  key: "orders/boost",
  get: ({ get }) => {
    return get(ordersRespQuery(ProductType.Boost))?.data ?? [];
  },
});

export const boostOrdersTotalQuery = selector({
  key: "orders/boost/total",
  get: ({ get }) => {
    const resp = get(ordersRespQuery(ProductType.Boost));

    return resp?.page?.total ?? 0;
  },
});

export const giftOrdersQuery = selector({
  key: "orders/gift",
  get: ({ get }) => {
    return get(ordersRespQuery(ProductType.Gift))?.data ?? [];
  },
});

export const giftOrdersTotalQuery = selector({
  key: "orders/gift/total",
  get: ({ get }) => {
    const resp = get(ordersRespQuery(ProductType.Gift));

    return resp?.page?.total ?? 0;
  },
});

export const instantChatOrdersQuery = selector({
  key: "orders/instant_chat",
  get: ({ get }) => {
    return get(ordersRespQuery(ProductType.InstantChat))?.data ?? [];
  },
});

export const instantChatOrdersTotalQuery = selector({
  key: "orders/instant_chat/total",
  get: ({ get }) => {
    const resp = get(ordersRespQuery(ProductType.InstantChat));

    return resp?.page?.total ?? 0;
  },
});
