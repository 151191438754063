import { StateType } from "interfaces";
import { Location } from "react-router-dom";
import { RouterURI } from "router/router.uri";

export function getFromStateFrom(location: Location) {
  let from: string;

  if (location.state !== null) {
    // 获取登录后需重定向的路径
    from = (location.state as StateType).from.pathname;
  } else {
    from = RouterURI.Home;
  }

  return from;
}
