import {
  Box,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Status, StatusType } from "enums";
import { ProfileText } from "interfaces";
import _ from "lodash";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { timestampMsToDateStr } from "utils";
import { CustomTable, CustomTd, CustomTr } from "../custom";
import { CustomHighlighter, StatusTag } from "../other";
import { BasicUserInfoView } from "./BasicUserInfoView";

type AboutMeListTableProps = {
  state: RecoilValueReadOnly<ProfileText[]>;
  rejectedState: RecoilState<string[]>;
};

export function ProfileTextsTable(props: AboutMeListTableProps) {
  const profileTexts = useRecoilValue(props.state);

  return (
    <TableContainer>
      <CustomTable>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Content</Th>
            <Th>UID</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>

        <Tbody>
          {profileTexts.map((aboutMe) => (
            <ProfileTextTableCell
              key={aboutMe.id}
              text={aboutMe}
              rejectedState={props.rejectedState}
            />
          ))}
        </Tbody>
      </CustomTable>
    </TableContainer>
  );
}

interface ProfileTextTableCellProps {
  text: ProfileText;
  rejectedState: RecoilState<string[]>;
}

function ProfileTextTableCell(props: ProfileTextTableCellProps) {
  return (
    <CustomTr>
      <Td w={1}>{props.text.id}</Td>
      <ProfileTextTdView
        profileText={props.text}
        rejectedState={props.rejectedState}
      />
      <Td>
        <BasicUserInfoView userInfo={props.text.user} />
      </Td>
      <Td>
        <StatusTag
          status={props.text.status}
          statusType={StatusType.Text}
        />
      </Td>
      <Td>{timestampMsToDateStr(props.text.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.text.updatedAt)}</Td>
    </CustomTr>
  );
}

function ProfileTextTdView({
  profileText,
  rejectedState,
}: {
  profileText: ProfileText;
  rejectedState: RecoilState<string[]>;
}) {
  const [selectedIds, setSelectedIds] = useRecoilState(rejectedState);

  const isSelected = selectedIds.includes(profileText.id);

  const handleClick = () => {
    if ([Status.Rejected, Status.Deleted].includes(profileText.status)) return;

    setSelectedIds((currVal) => {
      return _.xor(currVal, [profileText.id]);
    });
  };

  return (
    <CustomTd
      isSelected={isSelected}
      onClick={handleClick}>
      <Box
        wordBreak="break-all"
        w="400px">
        <CustomHighlighter text={profileText.content} />
      </Box>
    </CustomTd>
  );
}
