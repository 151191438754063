export const highlightWords = [
  "Spoil",
  "Sugar",
  "daddy",
  "SD",
  "sugar baby",
  "SB",
  "Customers",
  "business",
  "money",
  "sell",
  "content",
  "$",
  "pay",
  "p2p",
  "p4p",
  "SERIOUS INQUIRIES ONLY",
  "sc:",
  "snap",
  "text",
  "kik",
  "sugar",
  "babybabe",
  "daddy",
  "dad",
  "mommy",
  "boy",
  "girl",
  "kid",
  "teen",
  "cash",
  "money",
  "cashapp",
  "venmo",
  "paypal",
  "onlyfans",
  "Whatsapp",
  "escort",
  "price",
  "pay",
  "paying pig",
  "sell",
  "selling",
  "spoiled",
  "spoil",
  "$",
  "financial",
  "dollar",
  "paying",
  "charge",
  "prostitutes",
  "snap",
  "gmail",
  "hangout",
  "kik",
  "twitter",
  "fuck",
  "penis",
  "pussy",
  "piss",
  "squirt",
  "cock",
  "bitch",
  "breast",
  "nipple",
  "tits",
  "sex",
  "nude",
  "dick",
  "asshole",
  "blowinggangbang",
  "genitalorgasms",
  "masturbationblowjob",
  "suck",
  "naked",
  "masturbation",
  "blowjob",
  "chat",
];
