import { Tag } from "@chakra-ui/react";
import { RiskStatus } from "enums";

export function RiskStatusTag({ riskStatus }: { riskStatus: RiskStatus }) {
  let colorScheme: string | undefined;

  switch (riskStatus) {
    case RiskStatus.Normal:
      colorScheme = "blue";
      break;
    case RiskStatus.Confirmed:
      colorScheme = "green";
      break;
    case RiskStatus.Suspicious:
      colorScheme = "red";
      break;
    case RiskStatus.Banned:
      colorScheme = "gray";
      break;
    case RiskStatus.Underage:
      colorScheme = "orange";
      break;
  }
  return (
    <Tag colorScheme={colorScheme}>{RiskStatus[riskStatus].toUpperCase()}</Tag>
  );
}
