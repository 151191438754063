import { getIpDetails } from "apis";
import { CIDRDetectStatus } from "enums";
import { CIDRDetectResult } from "interfaces";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "./common";

export const detectCIDRResultsState = atom<CIDRDetectResult[]>({
  key: "detectCIDRResultsState",
  default: [],
});

export const numRejectedCIDRsState = selector({
  key: "numRejectedCIDRsState",
  get: ({ get }) => {
    const rejectedCIDRs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Rejected;
    });

    return rejectedCIDRs.length;
  },
});

export const numInvalidCIDRsState = selector({
  key: "numInvalidCIDRsState",
  get: ({ get }) => {
    const cidrs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Invalided;
    });

    return cidrs.length;
  },
});

export const numValidCIDRsState = selector({
  key: "numValidCIDRsState",
  get: ({ get }) => {
    const cidrs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Valid;
    });

    return cidrs.length;
  },
});

export const ipDetailsRespQuery = selectorFamily({
  key: "ipDetails/Resp",
  get:
    (ip: string) =>
    async ({ get }) => {
      try {
        const res = await getIpDetails(ip);
        return res.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const ipDetailsQuery = selectorFamily({
  key: "ipDetails",
  get:
    (ip: string) =>
    ({ get }) => {
      return get(ipDetailsRespQuery(ip))?.data;
    },
});
