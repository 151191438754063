import { Center } from "@chakra-ui/react";
import { StateType } from "interfaces";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "states/auth";
import { Loading } from "views/components";

// Redirect them to the /login page, but save the current location they were
// trying to go to when they were redirected. This allows us to send them
// along to that page after they login, which is a nicer user experience
// than dropping them off on the home page.

export function RequireAuth({ children }: { children: JSX.Element }) {
  // let auth = useAuth();
  const user = useRecoilValue(authAtom);

  let location = useLocation();

  // debug 模式直接进入页面
  if (process.env.REACT_APP_DEBUG) {
    return children;
  }

  if (user === undefined) {
    return (
      <Center h="100vh">
        <Loading />
      </Center>
    );
  }

  if (user === null) {
    let state: StateType = { from: location };
    return (
      <Navigate
        to="/login"
        state={state}
      />
    );
  }

  return children;
}
