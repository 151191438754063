import { Box, HStack, Stack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import {
  boostOrdersQuery,
  boostOrdersTotalQuery,
  giftOrdersQuery,
  giftOrdersTotalQuery,
  instantChatOrdersQuery,
  instantChatOrdersTotalQuery,
  subscriptionOrdersQuery,
  subscriptionOrdersTotalQuery,
} from "states";
import {
  CustomTabs,
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
  PlatformsFilter,
  SearchUIDInput,
  tabState,
} from "views/components";
import { OrdersView } from "./components/orders-table";

const configuration = [
  {
    name: "Boosts",
    component: <OrdersView state={boostOrdersQuery} />,
    totalState: boostOrdersTotalQuery,
    trainingItems: [PlatformsFilter, DateFilter, SearchUIDInput],
  },
  {
    name: "Gifts",
    component: <OrdersView state={giftOrdersQuery} />,
    totalState: giftOrdersTotalQuery,
    trainingItems: [PlatformsFilter, DateFilter, SearchUIDInput],
  },
  {
    name: "Instant Chats",
    component: <OrdersView state={instantChatOrdersQuery} />,
    totalState: instantChatOrdersTotalQuery,
    trainingItems: [PlatformsFilter, DateFilter, SearchUIDInput],
  },
  {
    name: "Subscriptions",
    component: <OrdersView state={subscriptionOrdersQuery} />,
    totalState: subscriptionOrdersTotalQuery,
    trainingItems: [PlatformsFilter, DateFilter, SearchUIDInput],
  },
];

export function OrdersPage() {
  const tabIndex = useRecoilValue(tabState);

  const currentTab =
    configuration[Math.min(tabIndex, configuration.length - 1)];

  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={currentTab.totalState} />
      </HeaderBox>

      <HStack>
        {currentTab.trainingItems.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      <CustomTabs tabs={configuration.map((item) => item.name)}>
        {configuration.map((item) => (
          <Box
            overflowX="scroll"
            key={item.name}>
            {item.component}
          </Box>
        ))}
      </CustomTabs>

      <Pagination totalState={currentTab.totalState} />
    </Stack>
  );
}
