export enum VerifyGesture {
  Yeah = 1,
  Up = 2,
  Ok = 4,
  Hi = 8,
}

interface IVerifyGesture {
  title: string;
  gesture: VerifyGesture;
}

const gestures: IVerifyGesture[] = [
  { title: "✌️", gesture: VerifyGesture.Yeah },
  { title: "☝️", gesture: VerifyGesture.Up },
  { title: "👌", gesture: VerifyGesture.Ok },
  { title: "👉", gesture: VerifyGesture.Hi },
];

export function getGestureTitle(gesture?: VerifyGesture) {
  return gestures.find((item) => {
    return item.gesture === gesture;
  })?.title;
}
