import {
  Avatar,
  Heading,
  HStack,
  LinkProps,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HOVER_COLOR_BG_LIGHT } from "constant";
import { Gender } from "enums";
import { BasicProfile } from "interfaces";
import { timestampMsToAge } from "utils";
import { UserIdContainer } from "views/components";

export function BasicUserInfoView(props: BasicUserInfoViewProps & LinkProps) {
  const hoverColor = useColorModeValue(HOVER_COLOR_BG_LIGHT, "gray.800");

  const { userInfo, ...linkProps } = props;

  if (!userInfo) return <>--</>;

  return (
    <UserIdContainer
      uid={userInfo.id}
      isExternal
      {...linkProps}>
      <HStack
        _hover={{ bg: hoverColor }}
        p={2}
        borderRadius={15}>
        <Avatar
          name={userInfo.name}
          src={userInfo.avatar?.url}
        />
        <Stack spacing={0}>
          <Heading size="xs">{userInfo.name}</Heading>
          <Text
            opacity={0.5}
            fontSize="12px">
            {[
              userInfo.birthday && timestampMsToAge(userInfo.birthday),
              userInfo.gender && Gender[userInfo.gender],
            ].join(" ")}
          </Text>
        </Stack>
      </HStack>
    </UserIdContainer>
  );
}

export type BasicUserInfoViewProps = {
  userInfo?: BasicProfile;
};
