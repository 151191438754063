import {
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Device } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { devicesCountQuery, devicesPageState, devicesQuery } from "states";
import { timestampMsToDateStr } from "utils";
import {
  CopyButton,
  CustomTable,
  CustomTr,
  DateFilter,
  DeviceId,
  DeviceStatusTag,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";

export function DevicesPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={devicesCountQuery} />
        <Spacer />
        {/* Filters */}
      </HeaderBox>

      <HStack>
        {/* <DeviceStatusFilter /> */}
        <DateFilter />
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <DevicesTable />
      </Suspense>

      <Pagination
        state={devicesPageState}
        totalState={devicesCountQuery}
      />
    </Stack>
  );
}

function DevicesTable() {
  const devices = useRecoilValue(devicesQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>UUID / ASSID</Th>
          <Th>Hardware</Th>
          <Th>App</Th>
          <Th>FCM</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {devices.map((device) => (
          <DevicesTableItem
            key={device.id}
            device={device}
          />
        ))}
      </Tbody>
    </CustomTable>
  );
}

function DevicesTableItem({ device }: { device: Device }) {
  return (
    <CustomTr>
      <Td>{device.id}</Td>
      <Td>
        <DeviceId deviceId={device.imei} />
      </Td>
      <Td>
        <Stack>
          <Text>{device.hardware || "--"}</Text>
          <Text>{device.os}</Text>
        </Stack>
      </Td>

      <Td>
        <Stack>
          <Text>{device.packageBundleId || "--"}</Text>
          <Text>
            {device.packageVersion} ({device.packageBuild})
          </Text>
        </Stack>
      </Td>
      <Td>
        {device.token ? <CopyButton value={device.token} /> : <Text>--</Text>}
      </Td>
      <Td>{<DeviceStatusTag status={device.status} />}</Td>
      <Td>{timestampMsToDateStr(device.createdAt)}</Td>
      <Td>{timestampMsToDateStr(device.updatedAt)}</Td>
    </CustomTr>
  );
}
