import { Heading } from "@chakra-ui/react";

export function Caption({ title }: { title: string }) {
  return (
    <Heading
      size="sm"
      opacity={0.5}>
      {title}
    </Heading>
  );
}
