import { Stack } from "@chakra-ui/react";
import { ProfileText } from "interfaces";
import { CustomHighlighter } from "../other/CustomHighlighter";

export function SeekingDescriptionView({
  description,
}: {
  description: ProfileText;
}) {
  return (
    <Stack>
      <CustomHighlighter text={description.content} />
    </Stack>
  );
}
