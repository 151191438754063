import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, ProfileText, SearchAboutParams } from "interfaces";

// fetchPendingAbout 获取待审核的about me / bio内容
export const fetchAboutMe = (params: SearchAboutParams) => {
  return axiosInstance.post<APIResponse<ProfileText[]>>(
    ApiURL.AboutMeSearch,
    params
  );
};
