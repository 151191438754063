// Default
export const DEFAULT_COLOR_BG_LIGHT = "gray.50";
export const DEFAULT_COLOR_BG_DARK = "gray.900";

// Hover
export const HOVER_COLOR_BG_LIGHT = "gray.100";
export const HOVER_COLOR_BG_DARK = "gray.700";

// Selected
export const SELECTED_COLOR_BG = "red.100";
export const SELECTED_COLOR_CONTENT = "red";
