import { Stack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { Status } from "enums";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./filter-selector";

export const aboutMeStatusState = atom({
  key: "about_me_status",
  default: Status.Pending,
  effects: [syncEffect({ refine: number() })],
});

export const aboutMeStatuses = [
  Status.Pending,
  Status.Approved,
  Status.Rejected,
  Status.Deleted,
];

export function AboutMeStatusFilter() {
  const filterOptions = aboutMeStatuses.map((status) => {
    const option: FilterSelectorOption = {
      title: Status[status],
      value: status,
    };
    return option;
  });

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Status
      </Text>
      <FilterSelector
        options={filterOptions}
        state={aboutMeStatusState}
      />
    </Stack>
  );
}
