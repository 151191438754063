import { Stack } from "@chakra-ui/react";
import { Profile } from "interfaces";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { ProfilesCard } from "views/components";

export function ProfileList({
  state,
}: {
  state: RecoilValueReadOnly<Profile[]>;
}) {
  const profiles = useRecoilValue(state);

  return (
    <Stack spacing={2}>
      {profiles.map((profile) => (
        <ProfilesCard
          key={profile.id}
          profile={profile}
        />
      ))}
    </Stack>
  );
}
