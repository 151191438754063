export enum Status {
  Deleted = -1,
  Pending = 0,
  Approved = 1,
  Rejected = 10,
}

export enum SeekingStatus {
  Deleted = -1,
  Pending = 0,
  Approved = 1,
  Rejected = 10,
  // 以下为相对base status外的状态值
  PartnerDeclined = 2, // partner 拒绝
}

export enum ProfileStatus {
  Deleted = -1, // 已删除
  Pending = 0, // 待审核
  Approved = 1, // 审核通过
  Banned = 10, // 封禁
  // 以下为相对base status外的状态值
  New = 2, // 未完成注册
  Rejected = 3, // 审核拒绝
}

export enum ReportStatus {
  Pending,
  Checked,
}

export enum DeviceStatus {
  Normal = 0,
  Banned = 1,
  Underage = 2,
}

export enum CIDRStatus {
  Disabled,
  Enabled,
}

export enum CIDRDetectStatus {
  Invalided,
  Valid,
  Rejected,
}

// AutoStatus 自动审核的状态
// NSFW分数低于0.01的媒体，将自动审核通过
export enum AutoStatus {
  Ignored = 0, // NSFW分数大于等于0.01，需人工审核
  Pending = 1, // NSFW分数小于0.01，需人工二次审核
  Checked = 2, // 已完成二次审核
}

export enum InvitationStatus {
  Deleted = -1,
  Pending = 0,
  Accepted = 1,
  Declined = 2,
}

// status tag展示首字母
// 因为各种status相同含义对应的枚举值完全不同，需要指定特定status进行区分
export enum StatusType {
  Media,
  Text,
  Profile,
  Seeking,
}

// 用户自定义的性别标签的审核状态
export enum GenderTagStatus {
  Pending,
  Approved,
  Rejected,
}

export enum RiskStatus {
  Normal = 0,
  Confirmed = 1,
  Suspicious = 2,
  Banned = 3,
  Underage = 4,
}
