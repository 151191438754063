import { Tag, TagProps, Tooltip } from "@chakra-ui/react";
import { DeviceStatus } from "enums";

interface StatusTagProps {
  status: DeviceStatus;
}

export function DeviceStatusTag(props: StatusTagProps & TagProps) {
  
  const { status, ...tagProps } = props;

  if (status === undefined) return <></>;

  let colorScheme: string | undefined;

  switch (status) {
    case DeviceStatus.Normal:
      colorScheme = "green";
      break;
    case DeviceStatus.Banned:
      colorScheme = "gray";
      break;
    case DeviceStatus.Underage:
      colorScheme = "orange";
      break;
  }

  return (
    <Tooltip label={DeviceStatus[status]}>
      <Tag
        colorScheme={colorScheme}
        {...tagProps}>
        {DeviceStatus[status]}
      </Tag>
    </Tooltip>
  );
}
