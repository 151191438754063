import {
  APIResponse,
  Member,
  MembersListParams,
  MemberToCreate,
  MemberToUpdate,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const createMember = (body: MemberToCreate) => {
  return axiosInstance.post(ApiURL.AdminUserAdd, body);
};

export const deleteMember = (id: string) => {
  return axiosInstance.post(ApiURL.AdminUserDelete, { id });
};

export const editMember = (member: MemberToUpdate) => {
  return axiosInstance.post(ApiURL.AdminUserEdit, member);
};

export const fetchAdminMembers = (params: MembersListParams) => {
  return axiosInstance.post<APIResponse<Member[]>>(
    ApiURL.AdminUserSearch,
    params
  );
};
