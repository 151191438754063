import {
  APIResponse,
  CIDR,
  CIDRDetectResult,
  CIDRListParams,
  CIDRToCreate,
  CIDRToUpdate,
  IPDetails,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const fetchCIDRList = (params: CIDRListParams) => {
  return axiosInstance.post<APIResponse<CIDR[]>>(
    ApiURL.BlockedIPSearch,
    params
  );
};

export const createCIDR = (body: CIDRToCreate) => {
  return axiosInstance.post(ApiURL.BlockedIPCreate, body);
};

export const editCIDR = (cidrToUpdate: CIDRToUpdate) => {
  return axiosInstance.post(ApiURL.BlockedIPEdit, cidrToUpdate);
};

export const detectCIDR = (ips: string[]) => {
  if (ips.length === 0) {
    return;
  }

  return axiosInstance.post<APIResponse<CIDRDetectResult[]>>(
    ApiURL.BlockedIPDetect,
    { ips }
  );
};

export const getIpDetails = (ip: string) => {
  return axiosInstance.post<APIResponse<IPDetails>>(ApiURL.IpDetails, { ip });
};
