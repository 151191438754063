import { searchCaptchas } from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "./common";
import { searchPageSizeState, searchPageState } from "./search";

export const captchasRequestIdState = atom({
  key: "captchas/requestId",
  default: dayjs().valueOf(),
});

export const captchasRepsQuery = selector({
  key: "captchas/resp",
  get: async ({ get }) => {
    get(captchasRequestIdState);

    try {
      const res = await searchCaptchas({
        page: get(searchPageState),
        pageSize: get(searchPageSizeState),
      });
      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const captchasQuery = selector({
  key: "captchas",
  get: ({ get }) => {
    return get(captchasRepsQuery)?.data ?? [];
  },
});

export const captchasCountQuery = selector({
  key: "captchas/count",
  get: ({ get }) => {
    return get(captchasRepsQuery)?.page.total ?? 0;
  },
});
