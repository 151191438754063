import {
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { RecoilState, useRecoilState } from "recoil";

export enum SelectType {
  Radio = "radio",
  Checkbox = "checkbox",
}

export type FilterOption = {
  title: string;
  value: number;
};

type CheckboxFilterProps = {
  title: string;
  state: RecoilState<number[]>;
  options: FilterOption[];
};

export function CheckboxFilter(props: CheckboxFilterProps) {
  const selectType = SelectType.Checkbox;

  const [statues, setStatuses] = useRecoilState(props.state);

  const [localStatus, setLocalStatues] = useState<string | string[]>(
    selectType === SelectType.Checkbox ? [] : ""
  );

  const menuitemOptions = props.options.map((opt) => (
    <MenuItemOption
      key={opt.value}
      value={opt.value.toString()}>
      {opt.title}
    </MenuItemOption>
  ));

  const handleApply = () => {
    if (selectType === SelectType.Checkbox) {
      const values = localStatus as string[];
      setStatuses(values.map((v) => +v));
    } else {
      // radio
    }
  };

  const getMenuButtonTitle = () => {
    if (statues.length === 0 || statues.length === props.options.length) {
      return "All";
    } else {
      return statues.map((value) => props.options[value].title).join(", ");
    }
  };

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        {props.title}
      </Text>
      <Menu
        closeOnSelect={false}
        onClose={handleApply}>
        <MenuButton>
          <HStack
            minW="100px"
            borderWidth="1px"
            px="3"
            py="1"
            fontSize="14px">
            <Text>{getMenuButtonTitle()}</Text>
            <Spacer />
            <Icon as={IoChevronDown} />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type={selectType}
            onChange={setLocalStatues}>
            {menuitemOptions}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Stack>
  );
}
