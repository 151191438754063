import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { editProfile } from "apis";
import dayjs from "dayjs";
import { DayjsKey, DeviceStatus, RiskStatus } from "enums";
import { Device, Profile } from "interfaces";
import _ from "lodash";
import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { editDeviceQuery, editProfileQuery, errorState } from "states";
import { getEnumKeys, getErrorMessage } from "utils";

type BirthdayEditorProps = {
  profile: Profile;
  device?: Device;
};

export function BirthdayEditor({ profile, device }: BirthdayEditorProps) {
  // hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // states
  const [editedProfile, setEditedProfile] = useRecoilState(
    editProfileQuery(profile.id)
  );
  const setEditedDevice = useSetRecoilState(editDeviceQuery(device?.id ?? ""));

  const updatedProfile = editedProfile ?? profile;

  const [birthday, setBirthday] = useState(updatedProfile.birthday);
  const [riskStatus, setRiskStatus] = useState(updatedProfile.riskStatus);

  const handleChangeBirthday = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBirthday = dayjs(e.target.value, DayjsKey.BirthdayValueFormat);

    setBirthday(newBirthday.valueOf());
  };

  const handleChangeRiskStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRiskStatus(+e.target.value as RiskStatus);
  };

  // handlers
  const handleSubmit = async () => {
    // 菊花转
    setIsSubmitting(true);

    try {
      // api
      await editProfile({ ...updatedProfile, birthday, riskStatus });

      // 更新本地数据
      setEditedProfile((curr) => {
        let p = _.cloneDeep(curr);

        if (p) {
          p.birthday = birthday;
          p.riskStatus = riskStatus;
        } else {
          p = { ...updatedProfile, birthday, riskStatus };
        }

        return p;
      });

      // 更改本地设备状态
      if (device) {
        let targetDeviceStatus: DeviceStatus;

        if ([RiskStatus.Normal, RiskStatus.Confirmed].includes(riskStatus)) {
          targetDeviceStatus = DeviceStatus.Normal;
        } else if (
          [RiskStatus.Suspicious, RiskStatus.Banned].includes(riskStatus)
        ) {
          targetDeviceStatus = DeviceStatus.Banned;
        } else {
          // underage
          targetDeviceStatus = DeviceStatus.Underage;
        }

        setEditedDevice((curr) => {
          let d = _.cloneDeep(curr);

          if (d) {
            // 更改设备状态值后放入缓存中
            d.status = targetDeviceStatus;
          } else {
            // 当前device修改状态后放入本地缓存中
            d = { ...device, status: targetDeviceStatus };
          }
          
          return d;
        });
      }

      // 关闭modal
      onClose();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Change Birthday</MenuItem>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Gender</ModalHeader>

          <ModalBody>
            <Stack>
              <FormControl isRequired>
                <Input
                  placeholder="Birthday"
                  defaultValue={
                    birthday &&
                    dayjs(birthday).format(DayjsKey.BirthdayValueFormat)
                  }
                  onChange={handleChangeBirthday}
                />
                <FormHelperText>
                  Format: {DayjsKey.BirthdayValueFormat}
                </FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Risk Status</FormLabel>
                <Select
                  defaultValue={updatedProfile.riskStatus}
                  onChange={handleChangeRiskStatus}>
                  {getEnumKeys(RiskStatus).map((key, index) => (
                    <option
                      key={index}
                      value={RiskStatus[key]}>
                      {key}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                onClick={onClose}
                variant="outline">
                Cancel
              </Button>

              <Button
                onClick={handleSubmit}
                isLoading={isSubmitting}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
