import {
  APIResponse,
  ChannelListParams,
  GenderStatusListParams,
  ReportedChannel,
  ReportedProfile,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

// fetchPendingReports 获取被举报的用户
export const fetchPendingReportedProfiles = (
  params: GenderStatusListParams
) => {
  return axiosInstance.post<APIResponse<ReportedProfile[]>>(
    ApiURL.ReportedProfilesSearch,
    params
  );
};

interface ReportsToSubmit {
  beReportedUids: string[];
}

// submitPendingReports 将指定uid的用户的举报记录标记为checked
export const submitPendingReports = (data: ReportsToSubmit) => {
  return axiosInstance.post(ApiURL.SubmitReportedProfiles, data);
};

// fetchPendingReportedChannels 获取被举报的聊天会话
export const fetchPendingReportedChannels = (params: ChannelListParams) => {
  return axiosInstance.post<APIResponse<ReportedChannel[]>>(
    ApiURL.PendingReportedChannelsSearch,
    params
  );
};

// 提交审核的被举报channel的id
interface ReportedChannelsToSubmit {
  ids: number[];
}

// submitPendingReportedChannels 审核被举报的channels
export const submitPendingReportedChannels = (
  data: ReportedChannelsToSubmit
) => {
  return axiosInstance.post(ApiURL.SubmitPendingReportedChannels, data);
};
