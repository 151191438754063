import {
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Gender, RelationshipTag, SexualOrientation } from "enums";
import { Profile } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  incompleteProfilesCountQuery,
  incompleteProfilesPageState,
  incompleteProfilesQuery,
} from "states";
import { timestampMsToAge, timestampMsToDateStr } from "utils";
import {
  CustomTable,
  CustomTr,
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";

export function UnfinishedProfilesPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={incompleteProfilesCountQuery} />
        <Spacer />
      </HeaderBox>

      <HStack>
        <DateFilter />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <ProfilesTable />
      </Suspense>

      {/* Pagination */}
      <Pagination
        state={incompleteProfilesPageState}
        totalState={incompleteProfilesCountQuery}
      />
    </Stack>
  );
}

function ProfilesTable() {
  const incompleteProfiles = useRecoilValue(incompleteProfilesQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>Couple</Th>
          <Th>Name</Th>
          <Th>Age</Th>
          <Th>Gender</Th>
          <Th>Sexual</Th>
          <Th>Media</Th>
          <Th>About Me</Th>
          <Th>Verify</Th>
          <Th>City</Th>
          <Th>Country</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {incompleteProfiles.map((profile) => (
          <ProfilesTableItem
            key={profile.id}
            profile={profile}
          />
        ))}
      </Tbody>
    </CustomTable>
  );
}

function ProfilesTableItem({ profile }: { profile: Profile }) {
  return (
    <CustomTr>
      <Td>
        {profile.relationshipTag
          ? RelationshipTag[profile.relationshipTag]
          : "--"}
      </Td>
      <Td>{profile.name ? profile.name : "--"}</Td>
      <Td>{profile.birthday ? timestampMsToAge(profile.birthday) : "--"}</Td>
      <Td>
        {profile.gender ? Gender[profile.gender] : "--"}
        {profile.genderMore ? ` (${profile.genderMore})` : undefined}
      </Td>
      <Td>
        {profile.sexualOrientation
          ? SexualOrientation[profile.sexualOrientation]
          : "--"}
      </Td>
      <Td>{profile.photos.length}</Td>
      <Td>{profile.aboutMe ? "Finished" : "--"}</Td>
      <Td>{profile.verify ? "Finished" : "--"}</Td>
      <Td>{profile.currInfo?.geo?.city ?? "--"}</Td>
      <Td>{profile.currInfo?.geo?.country ?? "--"}</Td>
      <Td>{timestampMsToDateStr(profile.createdAt)}</Td>
      <Td>{timestampMsToDateStr(profile.updatedAt)}</Td>
    </CustomTr>
  );
}
