import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { editProfile } from "apis";
import { Gender } from "enums";
import { Profile } from "interfaces";
import _ from "lodash";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { editProfileQuery, errorState } from "states";
import { getEnumKeys, getErrorMessage } from "utils";

type GenderEditorButtonProps = {
  profile: Profile;
  fullText?: boolean;
};

export function GenderEditorButton({
  profile,
  fullText,
}: GenderEditorButtonProps) {
  // hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // states
  const [editedProfile, setEditedProfile] = useRecoilState(
    editProfileQuery(profile.id)
  );

  const updatedProfile = editedProfile ?? profile;

  const [gender, setGender] = useState(updatedProfile.gender);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGender(+e.target.value as Gender);
  };

  // handleSubmit 提交更新
  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // 请求api
      await editProfile({ ...updatedProfile, gender });

      // 更新本地数据
      setEditedProfile((curr) => {
        let p = _.cloneDeep(curr);

        if (p) {
          p.gender = gender;
        } else {
          p = { ...updatedProfile, gender };
        }

        return p;
      });

      // 关闭modal
      onClose();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        pointerEvents="auto"
        size="xs"
        variant="outline">
        Edit{fullText ? " Gender" : undefined}
      </Button>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Gender</ModalHeader>

          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Gender</FormLabel>
              <Select
                defaultValue={gender}
                onChange={handleChange}>
                {getEnumKeys(Gender).map((key, index) => (
                  <option
                    key={index}
                    value={Gender[key]}>
                    {key}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                onClick={onClose}
                variant="outline">
                Cancel
              </Button>

              <Button
                onClick={handleSubmit}
                isLoading={isSubmitting}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
