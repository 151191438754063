import { fetchMedia, fetchMultiSourcesBy } from "apis";
import dayjs from "dayjs";
import { MediaType, Status } from "enums";
import { Media, MediasParams } from "interfaces";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  genders,
  genderState,
  mediaStatusState,
  searchTimestampsQuery,
} from "views/components";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const mediasPageState = atom({
  key: "medias/page",
  default: 1,
});

export const mediasRequestIdState = atom({
  key: "medias/requestId",
  default: dayjs().valueOf(),
});

export const mediasRespQuery = selector({
  key: "medias/resp",
  get: async ({ get }) => {
    get(mediasRequestIdState);

    const params: MediasParams = {
      page: get(mediasPageState),
      pageSize: get(searchPageSizeState),
      gender: genders[get(genderState)],
      status: get(mediaStatusState),
      types: [MediaType.Image, MediaType.ShortVideo],
    };

    if (Status.Pending !== get(mediaStatusState)) {
      const searchTimestamps = get(searchTimestampsQuery);
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    }

    try {
      const resp = await fetchMedia(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const mediasQuery = selector({
  key: "medias",
  get: ({ get }) => get(mediasRespQuery)?.data ?? [],
});

export const mediasCountQuery = selector({
  key: "medias/count",
  get: ({ get }) => get(mediasRespQuery)?.page?.total ?? 0,
});

export const modalMediaViewState = atom<Media | undefined>({
  key: "modalMediaViewState",
  default: undefined,
});

export const currentMediaIdAtom = atom<string | undefined>({
  key: "currentMediaIdAtom",
  default: undefined,
});

export const multiMediaSourcesState = atom<Media[]>({
  key: "multiMediaSourcesState",
  default: selector({
    key: "multiMediaSources/default",
    get: async ({ get }) => {
      const mediaId = get(currentMediaIdAtom);

      if (!mediaId) {
        return [];
      }

      try {
        const res = await fetchMultiSourcesBy(mediaId);
        return res.data.data ?? [];
      } catch (error) {
        return [];
      }
    },
  }),
});

export const mediaSourceToUpdateState = selector({
  key: "mediaSourceToUpdateState",
  get: async ({ get }) => {
    return get(multiMediaSourcesState).find((source) => source);
  },
});
