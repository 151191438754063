import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { createMember, deleteMember, editMember } from "apis";
import dayjs from "dayjs";
import { SearchParamKey } from "enums";
import { Member, MemberToCreate, MemberToUpdate } from "interfaces";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getErrorMessage } from "utils";

// MemberModalContent
export function MemberModalContent(props: MemberModalContentProps) {
  const [memberToCreate, setMemberToCreate] = useState<MemberToCreate>({
    name: props.member?.name,
    email: props.member?.email,
  });

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setMemberToCreate((currVal) => {
      return { ...currVal, name: value };
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setMemberToCreate((currVal) => {
      return { ...currVal, email: value };
    });
  };

  return (
    <ModalContent>
      <ModalHeader>{props.member ? "Edit" : "Create"} Member</ModalHeader>

      <ModalBody>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              // type="date"
              placeholder="Name"
              defaultValue={memberToCreate.name}
              onChange={handleChangeName}
            />
          </FormControl>

          <FormControl
            isRequired
            isDisabled={!!props.member}>
            <FormLabel>Email</FormLabel>
            <Input
              // type="date"
              placeholder="Email"
              defaultValue={memberToCreate.email}
              onChange={handleChangeEmail}
            />
          </FormControl>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          onClick={props.onClose}>
          Close
        </Button>

        {/* Create Button */}
        {!props.member && (
          <CreateButton
            memberToCreate={memberToCreate}
            callback={props.onClose}
          />
        )}

        {props.member && (
          <DeleteButton
            id={props.member.id}
            callback={props.onClose}
          />
        )}

        {/* Save Button */}
        {props.member && (
          <EditButton
            memberToUpdate={{
              name: memberToCreate.name,
              id: props.member.id,
            }}
            callback={props.onClose}
          />
        )}
      </ModalFooter>
    </ModalContent>
  );
}

interface MemberModalContentProps {
  member?: Member;
  onClose?: () => void;
}

interface CreateButtonProps {
  memberToCreate: MemberToCreate;
  callback?: () => void;
}

function CreateButton(props: CreateButtonProps) {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCreate = async () => {
    setIsSubmitting(true);

    try {
      await createMember(props.memberToCreate);
      toast({
        title: "Create Member Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Create Member Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const refresh = () => {
    // 设定search params时间戳，触发review 列表自动拉取数据
    searchParams.set(SearchParamKey.Timestamp, dayjs().valueOf().toString());
    searchParams.delete(SearchParamKey.Page);
    setSearchParams(searchParams);
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleCreate}>
      Create
    </Button>
  );
}

interface EditButtonProps {
  memberToUpdate: MemberToUpdate;
  callback?: () => void;
}

function EditButton(props: EditButtonProps) {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);

    try {
      await editMember(props.memberToUpdate);

      toast({
        title: "Edit Member Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Edit Member Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const refresh = () => {
    // 设定search params时间戳，触发review 列表自动拉取数据
    searchParams.set(SearchParamKey.Timestamp, dayjs().valueOf().toString());
    setSearchParams(searchParams);
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleSave}>
      Save
    </Button>
  );
}

interface DeleteButtonProps {
  id: string;
  callback?: () => void;
}

function DeleteButton(props: DeleteButtonProps) {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await deleteMember(props.id);

      toast({
        title: "Delete Member Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Delete Member Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const refresh = () => {
    // 设定search params时间戳，触发review 列表自动拉取数据
    searchParams.set(SearchParamKey.Timestamp, dayjs().valueOf().toString());
    setSearchParams(searchParams);
  };

  return (
    <Button
      ml={3}
      colorScheme="red"
      isLoading={isSubmitting}
      onClick={handleDelete}>
      Delete
    </Button>
  );
}
