export enum Gender {
  Woman = 1,
  Man = 2,
  NonBinary = 4,
}

export enum SexualOrientation {
  Straight = 1,
  Bisexual = 2,
  Gay = 4,
  Lesbian = 8,
  Queer = 16,
  Asexual = 32,
  Demisexual = 64,
  Pansexual = 128,
  Questioning = 256,
}

// enum SexualOrientation_Deprecated {
//   "Straight Man" = 1,
//   "Bisexual Man" = 2,
//   Gay = 4,
//   Lesbian = 8,
//   "Bisexual Woman" = 16,
//   "Straight Woman" = 32,
// }

// // 老版本gender枚举
// enum Gender_Deprecated {
//   Woman = 0,
//   Man = 1,
// }
