import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { RouterURI } from "router/router.uri";
import {
  searchEmailState,
  searchIMEIState,
  searchUIDState,
} from "states/search";

interface SearchBarForm {
  type: string;
  keyword: string;
}

enum SearchType {
  Email,
  UID,
  Device,
}

const searchTypes = [SearchType.Email, SearchType.UID, SearchType.Device];

export function SearchBar() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit } = useForm<SearchBarForm>();

  const setSearchEmail = useSetRecoilState(searchEmailState);
  const setSearchUID = useSetRecoilState(searchUIDState);
  const setSearchIMEI = useSetRecoilState(searchIMEIState);

  const resetSearchEmail = useResetRecoilState(searchEmailState);
  const resetSearchUID = useResetRecoilState(searchUIDState);
  const resetSearchIMEI = useResetRecoilState(searchIMEIState);

  const handleSearch = handleSubmit(async (data) => {
    onClose();

    resetSearchEmail();
    resetSearchUID();
    resetSearchIMEI();

    const { type, keyword } = data;

    const searchType = parseInt(type) as SearchType;

    switch (searchType) {
      case SearchType.Email:
        setSearchEmail(keyword);
        navigate(RouterURI.Profiles);
        return;
      case SearchType.UID:
        setSearchUID(keyword);
        navigate(RouterURI.Profiles);
        return;
      case SearchType.Device:
        setSearchIMEI(keyword);
        navigate(RouterURI.Devices);
        return;
    }
  });

  return (
    <Box>
      <IconButton
        aria-label="Search"
        variant="ghost"
        icon={<BsSearch />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />

        <ModalContent>
          <ModalBody>
            <form onSubmit={handleSearch}>
              <HStack>
                <Controller
                  name="type"
                  defaultValue={SearchType.Email.toString()}
                  control={control}
                  render={({ field }) => (
                    <Select size="lg" w="144px" variant="flushed" {...field}>
                      {searchTypes.map((type, index) => (
                        <option value={type.toString()} key={index}>
                          {SearchType[type]}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                <Controller
                  name="keyword"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <InputGroup size="lg" flex={1} variant="flushed" {...field}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />

                      <Input type="text" placeholder="Search" />
                    </InputGroup>
                  )}
                />
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
