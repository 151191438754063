import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  addCaptcha,
  Captcha,
  CaptchaStatus,
  CaptchaToCreate,
  CaptchaToUpdate,
  deleteCaptcha,
  editCaptcha,
  Source,
} from "apis";
import dayjs from "dayjs";
import React, { Suspense, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { BsChevronCompactDown } from "react-icons/bs";
import { FiDelete } from "react-icons/fi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  captchasCountQuery,
  captchasQuery,
  captchasRequestIdState,
  errorState,
} from "states";
import { getErrorMessage, timestampMsToDateStr } from "utils";
import {
  CustomTable,
  CustomTr,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";

export function CaptchasPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={captchasCountQuery} />
        <Spacer />

        <AddCaptchaButton />
      </HeaderBox>
      <Text opacity={0.5}>
        Set a fixed verification code for the test account.
      </Text>

      <Suspense fallback={<Spinner />}>
        <CaptchasTable />
      </Suspense>

      <Pagination totalState={captchasCountQuery} />
    </Stack>
  );
}

function CaptchasTable() {
  const captchas = useRecoilValue(captchasQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Source</Th>
          <Th>Account</Th>
          <Th>Code</Th>
          <Th>Expiry Date</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        {captchas.map((captcha) => (
          <CaptchaTr
            captcha={captcha}
            key={captcha.id}
          />
        ))}
      </Tbody>
    </CustomTable>
  );
}

function CaptchaTr({ captcha }: { captcha: Captcha }) {
  return (
    <CustomTr>
      <Td>{captcha.id}</Td>
      <Td>{captcha.source}</Td>
      <Td>{captcha.account}</Td>
      <Td>{captcha.code}</Td>
      <Td>{timestampMsToDateStr(captcha.expiryTime)}</Td>
      <Td>
        <CaptchaStatusTag status={captcha.status} />
      </Td>

      <Td>{timestampMsToDateStr(captcha.createdAt)}</Td>
      <Td>{timestampMsToDateStr(captcha.updatedAt)}</Td>
      <Td>
        <CaptchaActions captcha={captcha} />
      </Td>
    </CustomTr>
  );
}

function CaptchaStatusTag({ status }: { status: CaptchaStatus }) {
  return (
    <Tag colorScheme={status === CaptchaStatus.Active ? "green" : undefined}>
      {CaptchaStatus[status]}
    </Tag>
  );
}

function CaptchaActions({ captcha }: { captcha: Captcha }) {
  return (
    <Menu>
      <MenuButton
        colorScheme="gray"
        as={Button}
        rightIcon={<BsChevronCompactDown />}>
        Actions
      </MenuButton>
      <MenuList>
        <EditCaptchaMenuItem captcha={captcha} />
        <MenuDivider />
        <DeleteCaptchaMenuItem id={captcha.id} />
      </MenuList>
    </Menu>
  );
}

const dayjsFormat = "MM/DD/YYYY HH:mm:ss";

function AddCaptchaButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      source: Source.Email,
      account: "",
      code: "",
      expiryTime: dayjs().format(dayjsFormat),
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    const captchaToCreate: CaptchaToCreate = {
      source: values.source,
      account: values.account,
      code: values.code,
      expiryTime: dayjs(values.expiryTime).valueOf(),
    };

    try {
      await addCaptcha(captchaToCreate);
      updateRequestId(dayjs().valueOf());

      reset();
      onClose();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  });

  return (
    <>
      <Button onClick={onOpen}>Add Captcha</Button>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>Create New Captcha</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Source</FormLabel>
                  <Select
                    {...register("source")}
                    disabled>
                    <option value={Source.Phone}>{Source.Phone}</option>
                    <option value={Source.Email}>{Source.Email}</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Account</FormLabel>
                  <Input
                    {...register("account")}
                    type="text"
                    placeholder="Account"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Code</FormLabel>
                  <Input
                    {...register("code")}
                    type="text"
                    placeholder="Code"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Expiry Time</FormLabel>
                  <Input
                    {...register("expiryTime")}
                    type="text"
                    placeholder="Expiry Time"
                  />
                  <FormHelperText>Format: {dayjsFormat}</FormHelperText>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  reset();
                  onClose();
                }}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formState.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

function DeleteCaptchaMenuItem({ id }: { id: string }) {
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await deleteCaptcha(id);
      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <MenuItem
      closeOnSelect={false}
      icon={isSubmitting ? <Spinner size="sm" /> : <FiDelete />}
      color="red"
      onClick={handleDelete}>
      {isSubmitting ? "Deleting..." : "Delete"}
    </MenuItem>
  );
}

function EditCaptchaMenuItem({ captcha }: { captcha: Captcha }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const updateRequestId = useSetRecoilState(captchasRequestIdState);

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      source: captcha.source,
      account: captcha.account,
      code: captcha.code,
      expiryTime: dayjs(captcha.expiryTime).format(dayjsFormat),
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    onClose();

    const captchaToUpdate: CaptchaToUpdate = {
      id: captcha.id,
      source: values.source,
      account: values.account,
      code: values.code,
      expiryTime: dayjs(values.expiryTime).valueOf(),
    };

    try {
      await editCaptcha(captchaToUpdate);

      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  });

  return (
    <>
      <MenuItem
        icon={<AiOutlineEdit />}
        onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>Edit Captcha</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Provider</FormLabel>
                  <Select
                    {...register("source")}
                    disabled>
                    <option value={Source.Email}>{Source.Email}</option>
                    <option value={Source.Phone}>{Source.Phone}</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Account</FormLabel>
                  <Input
                    {...register("account")}
                    type="text"
                    placeholder="Account"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Code</FormLabel>
                  <Input
                    {...register("code")}
                    type="text"
                    placeholder="Code"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Expiry Time</FormLabel>
                  <Input
                    {...register("expiryTime")}
                    type="text"
                    placeholder="Expiry Time"
                  />
                  <FormHelperText>Format: {dayjsFormat}</FormHelperText>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onClose}
                colorScheme="gray"
                mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={formState.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
