import { Gender, RelationshipTag, SexualOrientation } from "enums";
import { Profile, ProfileToUpdate } from "interfaces";
import { timestampMsToAge } from "./day";

export function generateProfileSubInfo(profile: Profile | ProfileToUpdate) {
  return [
    profile.birthday && timestampMsToAge(profile.birthday),
    profile.relationshipTag && RelationshipTag[profile.relationshipTag],
    profile.gender && Gender[profile.gender],
    profile.genderMore,
    profile.sexualOrientation && SexualOrientation[profile.sexualOrientation],
    profile.currInfo?.geo && profile.currInfo?.geo.city,
    profile.currInfo?.geo && profile.currInfo?.geo.country,
  ].join(" ");
}
