import {
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Gender, Status, StatusType } from "enums";
import { Seeking } from "interfaces";
import _ from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  isSelectedSeekingIdQuery,
  rejectedSeekingIdsState,
  seekingsQuery,
} from "states";
import { milesToKilometers, timestampMsToDateStr } from "utils";

import { CustomTable, CustomTd, CustomTr } from "../custom";
import { CustomHighlighter, SeekingId, StatusTag } from "../other";
import { BasicUserInfoView } from "../profile";

export function SeekingsTableView() {
  const seekings = useRecoilValue(seekingsQuery);

  return (
    <TableContainer>
      <CustomTable>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Description</Th>
            <Th>Owner</Th>
            <Th>Partner</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {seekings.map((seeking) => (
            <SeekingsTableRow
              key={seeking.id}
              seeking={seeking}
            />
          ))}
        </Tbody>
      </CustomTable>
    </TableContainer>
  );
}

function SeekingsTableRow({ seeking }: { seeking: Seeking }) {
  const isSelected = useRecoilValue(isSelectedSeekingIdQuery(seeking.id));
  const selectSeeking = useSetRecoilState(rejectedSeekingIdsState);

  const handleSelect = () => {
    if ([Status.Deleted, Status.Rejected].includes(seeking.description.status))
      return;

    selectSeeking((curr) => _.xor(curr, [seeking.id]));
  };

  return (
    <CustomTr>
      <Td>
        <SeekingId seekingId={seeking.id} />
      </Td>

      <CustomTd
        isSelected={isSelected}
        onClick={handleSelect}>
        <CustomHighlighter text={seeking.description.content} />
        <Text
          opacity={0.5}
          fontSize="14">
          {seeking.genders
            .map((g) => Gender[g])
            .concat([
              `${seeking.minAge} - ${seeking.maxAge}${
                seeking.maxAge === 55 ? "+" : ""
              }`,
              seeking.city,
              `${seeking.distance}mi / ${Math.ceil(
                milesToKilometers(seeking.distance)
              )}km`,
            ])
            .join(", ")}
        </Text>
      </CustomTd>

      <Td>
        <BasicUserInfoView userInfo={seeking.owner} />
      </Td>
      <Td>
        <BasicUserInfoView userInfo={seeking.partner} />
      </Td>

      <Td>
        <StatusTag
          size="sm"
          statusType={StatusType.Text}
          status={seeking.status}
        />
      </Td>

      <Td>{timestampMsToDateStr(seeking.createdAt)}</Td>
      <Td>{timestampMsToDateStr(seeking.updatedAt)}</Td>
    </CustomTr>
  );
}
