import { Spacer, Spinner, Stack } from "@chakra-ui/react";
import { Suspense } from "react";
import { secretsCountQuery, secretsPageState } from "states";
import {
  HeaderBox,
  MainHeader,
  Pagination,
  PlatformTabs,
} from "views/components";
import { CreateSecretButton } from "./create-secret-button";
import { SecretsTable } from "./secrets-table";

export function SecretsPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={secretsCountQuery} />
        <Spacer />

        <CreateSecretButton />
      </HeaderBox>

      <PlatformTabs />

      <Pagination
        state={secretsPageState}
        totalState={secretsCountQuery}
      />

      <Suspense fallback={<Spinner />}>
        <SecretsTable />
      </Suspense>

      <Pagination
        state={secretsPageState}
        totalState={secretsCountQuery}
      />
    </Stack>
  );
}
