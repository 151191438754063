import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { mediasQuery, mediasRequestIdState } from "states";
import { NextSubmitButton } from "./next-submit-button";

export function SubmitMediaButton() {
  return (
    <Suspense fallback={<></>}>
      <SubmitMediaView />
    </Suspense>
  );
}

function SubmitMediaView() {
  const medias = useRecoilValue(mediasQuery);

  return (
    <NextSubmitButton photos={medias} requestIdState={mediasRequestIdState} />
  );
}
