import { Button, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { PhoneModelModalContent } from './model-modal-content';

// CreatePhoneModelButton
export function CreatePhoneModelButton() {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Button */}
      <Button colorScheme="pink" onClick={onOpen}>
        New Model
      </Button>

      {/* Modal */}
      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <PhoneModelModalContent onClose={onClose} />
      </Modal>
    </>
  );
}
