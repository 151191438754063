import { fetchUserReports } from "apis";
import { selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import { getErrorMessage } from "utils";

export const userReportsRespQuery = selectorFamily({
  key: "user/reports/resp",
  get: (uid: string) => async () => {
    try {
      const res = await fetchUserReports({
        page: 1,
        pageSize: 10,
        reportedUid: uid,
      });
      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const userReportsQuery = selectorFamily({
  key: "user/reports",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(userReportsRespQuery(uid))?.data ?? [];
    },
});

export const userReportsCountQuery = selectorFamily({
  key: "user/reports/count",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(userReportsRespQuery(uid))?.page.total ?? 0;
    },
});
