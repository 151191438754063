import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, Message, Session, SessionType } from "interfaces";

export type SessionsParams = {
  uid: string;
  pageSize: number;
  lastId?: string;
};

export const fetchSessions = (params: SessionsParams) => {
  return axiosInstance.post<APIResponse<Session[]>>(
    ApiURL.UserSessionsSearch,
    params
  );
};

export type MessagesParams = {
  from: string;
  type: SessionType;
  to: string;
  pageSize: number;
  lastId?: string;
};

export const fetchMessages = (params: MessagesParams) => {
  return axiosInstance.post<APIResponse<Message[]>>(
    ApiURL.UserMessagesSearch,
    params
  );
};
