import { Box, HStack, Stack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import {
  boostFlowsQuery,
  boostFlowsTotalQuery,
  giftFlowsQuery,
  giftFlowsTotalQuery,
  instantChatFlowsQuery,
  instantChatFlowsTotalQuery,
} from "states";
import {
  CustomTabs,
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
  SearchUIDInput,
  tabState,
} from "views/components";
import { FlowsView } from "./components/flows-table";

const configuration = [
  {
    name: "Boosts",
    component: <FlowsView state={boostFlowsQuery} />,
    totalState: boostFlowsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
  {
    name: "Gifts",
    component: <FlowsView state={giftFlowsQuery} />,
    totalState: giftFlowsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
  {
    name: "Instant Chats",
    component: <FlowsView state={instantChatFlowsQuery} />,
    totalState: instantChatFlowsTotalQuery,
    trainingItems: [DateFilter, SearchUIDInput],
  },
];

export function FlowsPage() {
  const tabIndex = useRecoilValue(tabState);

  const currentTab =
    configuration[Math.min(tabIndex, configuration.length - 1)];

  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={currentTab.totalState} />
      </HeaderBox>

      <HStack>
        {currentTab.trainingItems.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      <CustomTabs tabs={configuration.map((item) => item.name)}>
        {configuration.map((item) => (
          <Box overflowX="scroll" key={item.name}>
            {item.component}
          </Box>
        ))}
      </CustomTabs>

      <Pagination totalState={currentTab.totalState} />
    </Stack>
  );
}
