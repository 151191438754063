import {
  SimpleGrid,
  SimpleGridProps,
  useColorModeValue,
} from "@chakra-ui/react";

export function CustomSimpleGrid(props: SimpleGridProps) {
  const bgColor = useColorModeValue("gray.50", "gray.900");

  const { children, ...simpleGridProps } = props;
  return (
    <SimpleGrid
      p={4}
      bg={bgColor}
      borderRadius={15}
      spacing={4}
      columns={4}
      {...simpleGridProps}>
      {children}
    </SimpleGrid>
  );
}
