import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  seekingDescriptionsQuery,
  seekingDescriptionsRequestIdState,
} from "states";
import { NextSubmitButton } from "./next-submit-button";

export function SubmitSeekingsButton() {
  return (
    <Suspense fallback={<></>}>
      <SubmitButtonContent />
    </Suspense>
  );
}

function SubmitButtonContent() {
  const seekingDescriptions = useRecoilValue(seekingDescriptionsQuery);

  return (
    <NextSubmitButton
      seekings={seekingDescriptions}
      requestIdState={seekingDescriptionsRequestIdState}
    />
  );
}
