import axios from "axios";
import { Page } from "interfaces";
import { auth, getIdToken } from "lib/firebase";
import { StatusCode } from "./code";

// axios 实例
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // 发起网路请求
    try {
      const idToken = await getIdToken();
      config.headers.set("authorization", idToken);
    } catch (err) {
      throw err;
    }

    return config;
  },
  (err) => {
    throw err;
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    /* 局部方法 */

    // 全局拦截错误码
    const {
      code,
      data,
      page,
      error,
    }: { code: StatusCode; data: any; error: string; page: Page } =
      response.data;

    switch (code) {
      case StatusCode.Ok:
        return { ...response, data: { data, page } };
      case StatusCode.NotLoggedIn:
        await auth.signOut();
        throw new Error(error);
      default:
        // 未知场景
        throw new Error(error);
    }
  },
  (error) => {
    // 原生http错误
    // 40x, 50x
    throw error;
  }
);
