import { Tag, TagProps, Tooltip } from "@chakra-ui/react";
import { ProfileStatus, SeekingStatus, Status, StatusType } from "enums";

interface StatusTagProps {
  statusType: StatusType;
  status: Status | ProfileStatus | SeekingStatus | undefined;
}

export function StatusTag(props: StatusTagProps & TagProps) {
  const { statusType, status, ...tagProps } = props;

  if (status === undefined) return <></>;

  let title: string;
  let colorScheme: string | undefined;

  switch (status) {
    case Status.Pending:
      colorScheme = "orange";
      break;
    case Status.Approved:
      colorScheme = "green";
      break;
    case Status.Rejected:
      colorScheme = "red";
      break;
    case Status.Deleted:
      colorScheme = "gray";
      break;
    default:
      // profile status new: blue
      colorScheme = "blue";
  }

  switch (statusType) {
    case StatusType.Profile:
      title = ProfileStatus[status];
      // status: 10 = banned (即将废弃，不处理)
      // status: 3 = reject
      // status: 2 = new
      if (status === ProfileStatus.Rejected) {
        colorScheme = "red";
      } else if (status === ProfileStatus.New) {
        colorScheme = "blue";
      }
      break;
    case StatusType.Media:
      title = Status[status];

      break;
    case StatusType.Text:
      title = Status[status];

      break;
    case StatusType.Seeking:
      title = SeekingStatus[status];

      break;
  }

  return (
    <Tooltip label={title}>
      <Tag
        colorScheme={colorScheme}
        as={
          [
            Status.Deleted,
            ProfileStatus.Deleted,
            SeekingStatus.Deleted,
          ].includes(status)
            ? "s" // 贯穿线
            : undefined
        }
        {...tagProps}
      >
        {title.slice(0, 1)}
      </Tag>
    </Tooltip>
  );
}
