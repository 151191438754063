import { fetchSecrets } from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { platforms, tabState } from "views/components";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const secretsRequestIdState = atom({
  key: "secrets/requestId",
  default: dayjs().valueOf(),
});

export const secretsPageState = atom({
  key: "secrets/page",
  default: 1,
});

export const secretsRespQuery = selector({
  key: "secrets/resp",
  get: async ({ get }) => {
    get(secretsRequestIdState);

    try {
      const resp = await fetchSecrets({
        page: get(secretsPageState),
        pageSize: get(searchPageSizeState),
        platform: platforms[get(tabState)],
      });

      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const secretsQuery = selector({
  key: "secrets",
  get: ({ get }) => get(secretsRespQuery)?.data ?? [],
});

export const secretsCountQuery = selector({
  key: "secrets/count",
  get: ({ get }) => get(secretsRespQuery)?.page?.total ?? 0,
});
