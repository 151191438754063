import { UseToastOptions } from "@chakra-ui/react";
import dayjs from "dayjs";
import { SearchParamKey } from "enums";
import { atom, RecoilValueReadOnly, selectorFamily } from "recoil";
import { searchPageSizeState } from "./search";

export const totalPagesQuery = selectorFamily({
  key: "totalPageState",
  get:
    (totalState: RecoilValueReadOnly<number>) =>
    ({ get }) => {
      return Math.ceil(get(totalState) / get(searchPageSizeState));
    },
});

export const timestampAtom = atom<number>({
  key: SearchParamKey.Timestamp,
  default: dayjs().valueOf(),
});

export const errorState = atom<string | undefined>({
  key: "toast/error",
  default: undefined,
});

export const globalToastState = atom<UseToastOptions | undefined>({
  key: "toast/global",
  default: undefined,
});
