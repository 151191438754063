import {
  Box,
  HStack,
  Spinner,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { MessagesParams } from "apis";
import { DEFAULT_COLOR_BG_DARK, DEFAULT_COLOR_BG_LIGHT } from "constant";
import { BasicProfile, Session, SessionType } from "interfaces";
import _ from "lodash";
import { Suspense, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { messagesQuery } from "states";
import { BasicUserInfoView } from "../BasicUserInfoView";
import { MessageView } from "./MessageView";

export function ChatView({
  from,
  session,
}: {
  from: string;
  session?: Session;
}) {
  if (!session) return <></>;

  return (
    <Stack>
      {/* Basic Info */}
      <UsersInfoView users={session.users} />

      <Suspense fallback={<Spinner />}>
        <MessagesView
          from={from}
          session={session}
        />
      </Suspense>
      {/* dummy */}
    </Stack>
  );
}

function UsersInfoView({ users }: { users: BasicProfile[] }) {
  const bg = useColorModeValue(DEFAULT_COLOR_BG_LIGHT, DEFAULT_COLOR_BG_DARK);

  return (
    <HStack
      borderRadius="24px"
      w="100%"
      bg={bg}
      position="sticky"
      top={0}
      left={0}
      right={0}
      p={2}
      zIndex={4}>
      {users.map((user) => (
        <BasicUserInfoView
          key={user.id}
          userInfo={user}
        />
      ))}
    </HStack>
  );
}

function MessagesView({ from, session }: { from: string; session: Session }) {
  const to =
    session.type === SessionType.P2P ? session.users[0].id : session.id;

  const params: MessagesParams = {
    from,
    to,
    type: session.type,
    pageSize: 30,
  };

  const messages = useRecoilValue(messagesQuery(params));

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    // 因为视频类型的高度需在加载视频资源后确定，需要延时滚动
    _.delay(
      () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" }),
      100
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Stack
      p={2}
      spacing={4}>
      {_.sortBy(messages, "id").map((message) => (
        <MessageView
          key={message.id}
          from={from}
          message={message}
        />
      ))}
      <Box ref={messagesEndRef} />
    </Stack>
  );
}
