/* Flows */

import { fetchFlows } from "apis";
import { FlowParamType, ProductType } from "enums";
import { FlowsParams } from "interfaces";
import { selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchPageSizeState,
  searchPageState,
  searchUIDState,
} from "states/search";
import { getErrorMessage } from "utils";
import { searchTimestampsQuery } from "views/components";

// 根据商品类型，组合商品流水记录类型
const getProductRecordTypes = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Boost:
      return [
        FlowParamType.BoostPurchase,
        FlowParamType.BoostConsume,
        FlowParamType.BoostReward,
      ];
    case ProductType.Gift:
      return [
        FlowParamType.GiftPurchase,
        FlowParamType.GiftConsume,
        FlowParamType.GiftReward,
      ];
    case ProductType.InstantChat:
      return [
        FlowParamType.InstantChatPurchase,
        FlowParamType.InstantChatConsume,
      ];
    default:
      return [];
  }
};

const flowsRespQuery = selectorFamily({
  key: "flows/resp",
  get:
    (productType: ProductType) =>
    async ({ get }) => {
      const page = get(searchPageState);
      const pageSize = get(searchPageSizeState);

      const params: FlowsParams = {
        types: getProductRecordTypes(productType),
        page: page,
        pageSize: pageSize,
      };

      const uid = get(searchUIDState);

      if (!uid) {
        const dateRange = get(searchTimestampsQuery);

        params.createdStart = dateRange.createdStart;
        params.createdEnd = dateRange.createdEnd;
      } else {
        params.uid = uid;
      }

      try {
        const resp = await fetchFlows(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const boostFlowsQuery = selector({
  key: "flows/boost",
  get: ({ get }) => {
    return get(flowsRespQuery(ProductType.Boost))?.data ?? [];
  },
});

export const boostFlowsTotalQuery = selector({
  key: "flows/boost/total",
  get: ({ get }) => {
    const resp = get(flowsRespQuery(ProductType.Boost));

    return resp?.page?.total ?? 0;
  },
});

export const giftFlowsQuery = selector({
  key: "flows/gift",
  get: ({ get }) => {
    return get(flowsRespQuery(ProductType.Gift))?.data ?? [];
  },
});

export const giftFlowsTotalQuery = selector({
  key: "flows/gift/total",
  get: ({ get }) => {
    const resp = get(flowsRespQuery(ProductType.Gift));

    return resp?.page?.total ?? 0;
  },
});

export const instantChatFlowsQuery = selector({
  key: "flows/instant_chat",
  get: ({ get }) => {
    return get(flowsRespQuery(ProductType.InstantChat))?.data ?? [];
  },
});

export const instantChatFlowsTotalQuery = selector({
  key: "flows/instant_chat/total",
  get: ({ get }) => {
    const resp = get(flowsRespQuery(ProductType.InstantChat));

    return resp?.page?.total ?? 0;
  },
});
