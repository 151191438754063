import { Stack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { Platform } from "enums";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./filter-selector";
import { CheckboxFilter, FilterOption } from "./statues/CheckboxFilter";

export const platforms = [Platform.iOS, Platform.Android, Platform.Console];

export const platformValuesState = atom<number[]>({
  key: "platform/values",
  default: [],
});

export const platformsQuery = selector({
  key: "platforms",
  get: ({ get }) => {
    const platformValues = get(platformValuesState);

    if (platformValues.length > 0) {
      return platformValues.map((i) => platforms[i]);
    } else {
      return [...platforms];
    }
  },
});

export const platformState = atom<number>({
  key: "platform",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function PlatformFilter() {
  const options = platforms.map((status) => {
    const option: FilterSelectorOption = {
      title: Platform[status],
      value: status,
    };

    return option;
  });

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Platform
      </Text>
      <FilterSelector
        options={options}
        state={platformState}
      />
    </Stack>
  );
}

export function PlatformsFilter() {
  const options = platforms.map((item, index) => {
    const opt: FilterOption = {
      title: Platform[item],
      value: index,
    };
    return opt;
  });

  return (
    <CheckboxFilter
      title="Platforms"
      state={platformValuesState}
      options={options}
    />
  );
}
