import { HStack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { ReviewStatus } from "enums";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./filter-selector";

export const approvedStatusState = atom({
  key: "approved_status",
  default: ReviewStatus.WaitingForReview,
  effects: [syncEffect({ refine: number() })],
});

export const approvedStatuses = [
  ReviewStatus.WaitingForReview,
  ReviewStatus.Checked,
];

export function ApprovedStatusFilter() {
  const filterOptions = approvedStatuses.map((item) => {
    const option: FilterSelectorOption = {
      title: ReviewStatus[item],
      value: item,
    };

    return option;
  });

  return (
    <HStack>
      <Text>Approved By: </Text>
      <FilterSelector
        options={filterOptions}
        state={approvedStatusState}
      />
    </HStack>
  );
}
