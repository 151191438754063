import { RiskStatus } from "enums";
import { atom, selector } from "recoil";
import { CheckboxFilter, FilterOption } from "./CheckboxFilter";

const riskStatuses = [
  RiskStatus.Normal,
  RiskStatus.Confirmed,
  RiskStatus.Suspicious,
  RiskStatus.Banned,
];

const riskStatusValuesState = atom<number[]>({
  key: "risk_statues/values",
  default: [],
});

export const riskStatusesQuery = selector({
  key: "risk_statuses",
  get: ({ get }) => {
    const statues = get(riskStatusValuesState);

    if (statues.length > 0) {
      return statues.map((index) => riskStatuses[index]);
    } else {
      // 重要，一定要拷贝一下，若直接返回relationshipStatuses数组，不会触发数据刷新
      return [...riskStatuses];
    }
  },
});

export function RiskStatusFilter() {
  const options = riskStatuses.map((status, index) => {
    const opt: FilterOption = {
      title: RiskStatus[status],
      value: index,
    };
    return opt;
  });

  return (
    <CheckboxFilter
      title="Risk Status"
      state={riskStatusValuesState}
      options={options}
    />
  );
}
