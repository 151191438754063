import { Table, TableProps } from "@chakra-ui/react";

export function CustomTable(props: TableProps) {
  const { children, ...tableProps } = props;
  return (
    <Table
      variant="simple"
      colorScheme="gray"
      {...tableProps}>
      {children}
    </Table>
  );
}
