import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { editProfile } from "apis";
import { DeviceStatus, RiskStatus } from "enums";
import { Device, Profile } from "interfaces";
import _ from "lodash";
import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { editDeviceQuery, editProfileQuery, errorState } from "states";
import { getErrorMessage } from "utils";

type UnderageButtonProps = {
  profile: Profile;
  device?: Device;
};

export function UnderageButton({ profile, device }: UnderageButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [editedProfile, setProfileToUpdate] = useRecoilState(
    editProfileQuery(profile.id)
  );

  const setEditedDevice = useSetRecoilState(editDeviceQuery(device?.id ?? ""));

  const updatedProfile = editedProfile ?? profile;

  if (updatedProfile.riskStatus === RiskStatus.Underage) return <></>;

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // 请求接口
      await editProfile({ ...updatedProfile, riskStatus: RiskStatus.Underage });

      // 接口请求成功，
      // 创建/修改数据副本
      setProfileToUpdate((curr) => {
        let p = _.cloneDeep(curr);

        if (p) {
          p.riskStatus = RiskStatus.Underage;
        } else {
          p = {
            ...profile,
            riskStatus: RiskStatus.Underage,
          };
        }
        return p;
      });

      // 更改本地设备状态
      if (device) {
        setEditedDevice((curr) => {
          let d = _.cloneDeep(curr);

          if (d) {
            // 更改设备状态值后放入缓存中
            d.status = DeviceStatus.Underage;
          } else {
            // 当前device修改状态后放入本地缓存中
            d = { ...device, status: DeviceStatus.Underage };
          }
          return d;
        });
      }

      onClose();
    } catch (error) {
      // 接口请求失败
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Button
        pointerEvents="auto"
        size="xs"
        variant="outline"
        onClick={onOpen}>
        Underage
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold">
              Underage Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                variant="outline"
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                onClick={handleSubmit}
                ml={3}>
                Underage
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
