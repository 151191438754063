import { Stack, StackProps } from "@chakra-ui/react";
import { MediaType } from "enums";
import { Media } from "interfaces";
import { ImageView } from "./image-view";
import { VideoPlayer } from "./video-player";

interface MediaViewProps {
  media: Media;
}

export function MediaView(props: MediaViewProps & StackProps) {
  const { media, ...restProps } = props;

  return (
    <Stack bg="black" {...restProps}>
      {" "}
      {/* Video & Image 使用黑色背景 */}
      {media.type === MediaType.Image ? (
        <ImageView src={media.url} />
      ) : (
        <VideoPlayer url={media.url} />
      )}
    </Stack>
  );
}
