import { Stack, useColorModeValue } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { userReportsCountQuery, userReportsQuery } from "states/profile/report";
import { ReportsList } from "views/components";
import { Caption } from "./details-caption";

export function ProfileReportsView({ uid }: { uid: string }) {
  const bgColor = useColorModeValue("gray.50", "gray.900");

  const reports = useRecoilValue(userReportsQuery(uid));
  const reportsCount = useRecoilValue(userReportsCountQuery(uid));

  return (
    <>
      {reports.length > 0 && (
        <>
          <Caption title={"Reports" + reportsCount} />
          <Stack p={4} bg={bgColor} borderRadius={15}>
            <ReportsList data={reports} />
          </Stack>
        </>
      )}
    </>
  );
}
