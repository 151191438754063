import { Spinner } from "@chakra-ui/react";
import { RiskStatus } from "enums";
import { Suspense } from "react";
import { pendingProfilesQuery } from "states";
import { ProfileList } from "./components/profile";

export function NormalProfilesPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <ProfileList state={pendingProfilesQuery(RiskStatus.Normal)} />
    </Suspense>
  );
}
