import { Box, Icon } from "@chakra-ui/react";
import { localStorageEffect } from "lib/recoil";
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

const defaultWidth = 256;
const closedWidth = 64;

export const sidebarWidth = atom({
  key: "sidebar/width",
  default: defaultWidth,
  effects: [localStorageEffect("sidebar")],
});

export const isSidebarOpenQuery = selector({
  key: "sidebar",
  get: ({ get }) => {
    return get(sidebarWidth) === defaultWidth;
  },
});

export function SidebarToggle() {
  const updateWidth = useSetRecoilState(sidebarWidth);
  const isSidebarOpen = useRecoilValue(isSidebarOpenQuery);
  return (
    <Box
      onClick={() => {
        updateWidth((curr) => {
          let targetWidth = curr;
          isSidebarOpen ? (targetWidth = closedWidth) : (targetWidth = 256);

          return targetWidth;
        });
      }}
      _hover={{ opacity: 0.8 }}
      cursor="pointer"
      borderRadius="3px"
      w="24px"
      h="24px">
      <Icon
        as={
          isSidebarOpen
            ? TbLayoutSidebarRightExpand
            : TbLayoutSidebarRightCollapse
        }
        w="100%"
        h="100%"
      />
    </Box>
  );
}
