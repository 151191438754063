export enum MemberStatus {
  Pending = 0,
  Enabled = 1,
  Disabled = 99,
}

export enum MemberRole {
  Unknown = 0,
  Admin = 1,
  Member = 1,
}
