import { Center, Heading, Link, Stack } from "@chakra-ui/react";
import { Link as ReactRouter } from "react-router-dom";

export function NotFound() {
  return (
    <Center h="100vh">
      <Stack spacing={4}>
        <Heading>Page Not Found</Heading>
        <Link
          as={ReactRouter}
          to="/">
          Back to Home
        </Link>
      </Stack>
    </Center>
  );
}
