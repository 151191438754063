import { Button } from "@chakra-ui/react";
import _ from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { searchPageState, totalPagesQuery } from "states";
import { PaginationContentProps } from "./PaginationContent";

export function PaginationNextButton(props: PaginationContentProps) {
  const setPage = useSetRecoilState(props.state ?? searchPageState);
  const totalPages = useRecoilValue(totalPagesQuery(props.totalState));

  const handleClickNext = () => {
    setPage((curr) => {
      const _page = _.cloneDeep(curr);
      return Math.min(_page + 1, totalPages);
    });
  };

  return <Button onClick={handleClickNext}>Next</Button>;
}
