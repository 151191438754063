import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RouterURI } from "router/router.uri";
import { ChatsView, RequireAuth } from "views/components";
import { MainLayout, ReviewLayout } from "views/layouts";
import {
  AboutMePage,
  BannedProfilesPage,
  BlocksPage,
  CaptchasPage,
  ConfirmedProfilesPage,
  DashboardPage,
  DevicesPage,
  FlowsPage,
  GenderTagsPage,
  GiftsPage,
  InAppProductsPage,
  InstantChatsPage,
  LikesPage,
  LoginPage,
  MatchesPage,
  MediaListPage,
  MembersPage,
  NormalProfilesPage,
  NotFound,
  NotificationsPage,
  OrdersPage,
  PassedPage,
  PhoneModelsPage,
  ProfilePage,
  ProfilesPage,
  ReportedProfilesPage,
  SecretsPage,
  SeekingDescriptionsPage,
  SeekingPage,
  SeekingsPage,
  SubscriptionsPage,
  SuspiciousProfilesPage,
  TransfersPage,
  UnfinishedProfilesPage,
} from "views/pages";

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          }>
          <Route
            index
            element={<Navigate to={RouterURI.Dashboard} />}
          />

          <Route
            path={RouterURI.Dashboard}
            element={<DashboardPage />}
          />

          {/* Review */}
          <Route element={<ReviewLayout />}>
            {/* Profiles */}
            <Route
              path={RouterURI.NormalProfiles}
              element={<NormalProfilesPage />}
            />
            <Route
              path={RouterURI.ConfirmedProfiles}
              element={<ConfirmedProfilesPage />}
            />
            <Route
              path={RouterURI.SuspiciousProfiles}
              element={<SuspiciousProfilesPage />}
            />
            <Route
              path={RouterURI.BannedProfiles}
              element={<BannedProfilesPage />}
            />
            <Route
              path={RouterURI.ReportedProfiles}
              element={<ReportedProfilesPage />}
            />

            {/* Content */}
            <Route
              path={RouterURI.AboutMe}
              element={<AboutMePage />}
            />

            <Route
              path={RouterURI.SeekingDescriptions}
              element={<SeekingDescriptionsPage />}
            />

            <Route
              path={RouterURI.Photos}
              element={<MediaListPage />}
            />
          </Route>

          {/* Analytics */}
          <>
            <Route
              path={RouterURI.Devices}
              element={<DevicesPage />}
            />

            <Route path={RouterURI.Profiles}>
              <Route
                index
                element={<ProfilesPage />}
              />
              <Route
                path=":uid"
                element={<ProfilePage />}
              />
            </Route>

            <Route path={RouterURI.Seekings}>
              <Route
                index
                element={<SeekingsPage />}
              />
              <Route
                path=":seekingId"
                element={<SeekingPage />}
              />
            </Route>

            <Route
              path={RouterURI.UnfinishedProfiles}
              element={<UnfinishedProfilesPage />}
            />
          </>

          <>
            <Route
              path={RouterURI.Passed}
              element={<PassedPage />}
            />
            <Route
              path={RouterURI.Likes}
              element={<LikesPage />}
            />
            <Route
              path={RouterURI.Matches}
              element={<MatchesPage />}
            />
            <Route
              path={RouterURI.Gifts}
              element={<GiftsPage />}
            />
            <Route
              path={RouterURI.InstantChats}
              element={<InstantChatsPage />}
            />
            <Route
              path={RouterURI.Blocks}
              element={<BlocksPage />}
            />
          </>

          {/* Payment */}
          <Route
            path={RouterURI.Subscriptions}
            element={<SubscriptionsPage />}
          />
          <Route
            path={RouterURI.InAppProducts}
            element={<InAppProductsPage />}
          />
          <Route
            path={RouterURI.Orders}
            element={<OrdersPage />}
          />
          <Route
            path={RouterURI.Flows}
            element={<FlowsPage />}
          />
          <Route
            path={RouterURI.Transfers}
            element={<TransfersPage />}
          />
          <Route
            path={RouterURI.Notifications}
            element={<NotificationsPage />}
          />

          {/* Manage Section */}
          <Route
            path={RouterURI.PhoneModels}
            element={<PhoneModelsPage />}
          />

          <Route
            path={RouterURI.AppSecrets}
            element={<SecretsPage />}
          />
          <Route
            path={RouterURI.Captchas}
            element={<CaptchasPage />}
          />

          <Route
            path={RouterURI.GenderRequests}
            element={<GenderTagsPage />}
          />

          {/* Setting */}
          <Route
            path={RouterURI.Members}
            element={<MembersPage />}
          />
        </Route>

        <Route
          path="/login"
          element={<LoginPage />}
        />

        <Route
          path="/debug"
          element={<ChatsView uid={"1"} />}
        />

        <Route
          path="/*"
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  );
}
