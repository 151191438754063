import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Platform } from "enums";
import { Subscription } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { subscriptionsQuery } from "states";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView,
  TransferLink,
} from "views/components";

export function SubscriptionsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <SubscriptionsTableContent />
    </Suspense>
  );
}

function SubscriptionsTableContent() {
  const subscriptions = useRecoilValue(subscriptionsQuery);

  return (
    <>
      {subscriptions.length === 0 && <EmptyView />}
      {subscriptions.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>User</Th>
              <Th>Platform</Th>
              <Th>Original Transaction ID</Th>
              <Th>Product</Th>
              <Th>Purchase Date</Th>
              <Th>Expires Date</Th>
              <Th>Auto Renew</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <SubscriptionTableBody subscriptions={subscriptions} />
        </CustomTable>
      )}
    </>
  );
}

interface SubscriptionTableBodyProps {
  subscriptions: Subscription[];
}

function SubscriptionTableBody(props: SubscriptionTableBodyProps) {
  if (props.subscriptions.length === 0) return <EmptyView />;

  return (
    <Tbody>
      {props.subscriptions.map((subscription, index) => (
        <SubscriptionsTableCell
          key={index}
          subscription={subscription}
        />
      ))}
    </Tbody>
  );
}

interface SubscriptionsTableCellProps {
  subscription: Subscription;
}

function SubscriptionsTableCell(props: SubscriptionsTableCellProps) {
  const { subscription } = props;
  return (
    <CustomTr>
      <Td>{subscription.id}</Td>
      <Td>
        <BasicUserInfoView userInfo={subscription.user} />
      </Td>
      <Td>{Platform[subscription.platform]}</Td>
      <Td>
        <TransferLink
          value={subscription.originalTransactionId}
          originalTransactionId={subscription.originalTransactionId}
        />
      </Td>
      <Td>{subscription.productId}</Td>
      <Td>{timestampMsToDateStr(subscription.purchaseDate)}</Td>
      <Td>{timestampMsToDateStr(subscription.expiresDate)}</Td>
      <Td textTransform={"capitalize"}>
        {String(Boolean(subscription.autoRenew))}
      </Td>
      <Td>{subscription.notificationType}</Td>
      <Td>{timestampMsToDateStr(subscription.createdAt)}</Td>
      <Td>{timestampMsToDateStr(subscription.updatedAt)}</Td>
    </CustomTr>
  );
}
