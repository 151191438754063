import {
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  HOVER_COLOR_BG_DARK,
  HOVER_COLOR_BG_LIGHT,
  SELECTED_COLOR_BG,
  SELECTED_COLOR_CONTENT,
} from "constant";
import { Status, StatusType } from "enums";
import { ProfileText } from "interfaces";
import _ from "lodash";
import { RecoilState, useRecoilState } from "recoil";
import { timestampMsToDateStr } from "utils";
import { CustomHighlighter, StatusTag } from "views/components";

export enum ProfileTextType {
  AboutMe,
  Seeking,
}

type ProfileTextViewProps = {
  profileText: ProfileText;
  type: ProfileTextType;
  rejectedIdState: RecoilState<string[]>;
};

export function ProfileTextView(props: ProfileTextViewProps) {
  const { profileText, type, rejectedIdState } = props;

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const hoverColor = useColorModeValue(
    HOVER_COLOR_BG_LIGHT,
    HOVER_COLOR_BG_DARK
  );

  const [selectedIds, setSelectedIds] = useRecoilState(rejectedIdState);
  const isSelected = selectedIds.includes(profileText.id);

  if (!profileText) return <></>;

  const handleClick = () => {
    if ([Status.Rejected, Status.Deleted].includes(profileText.status)) return;

    setSelectedIds((currVal) => {
      return _.xor(currVal, [profileText.id]);
    });
  };

  return (
    <Stack
      maxW="50%"
      h="100%"
      justifyContent="center"
      flex={1}
      p={4}
      bg={isSelected ? SELECTED_COLOR_BG : bgColor}
      color={isSelected ? SELECTED_COLOR_CONTENT : undefined}
      _hover={{ bg: isSelected ? SELECTED_COLOR_BG : hoverColor }}
      borderRadius={14}
      cursor="pointer"
      onClick={handleClick}>
      <HStack>
        <Text
          fontSize={12}
          opacity={0.5}>
          {ProfileTextType[type]}
        </Text>
        <StatusTag
          status={profileText?.status}
          statusType={StatusType.Text}
        />
      </HStack>

      <Spacer />

      <CustomHighlighter text={profileText.content} />

      <Text
        opacity={0.5}
        fontSize={12}>
        {timestampMsToDateStr(profileText.updatedAt)}
      </Text>
      <Spacer />
    </Stack>
  );
}
