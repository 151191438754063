import { number, string } from "@recoiljs/refine";
import { SearchParamKey, Status } from "enums";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";

export const searchPageState = atom<number>({
  key: SearchParamKey.Page,
  default: 1,
  effects: [syncEffect({ refine: number() })],
});

export const searchPageSizeState = atom<number>({
  key: SearchParamKey.PageSize,
  default: parseInt(process.env.REACT_APP_PAGE_SIZE ?? "30"),
  effects: [syncEffect({ refine: number() })],
});

export const searchOriginalTransactionIdState = atom<string>({
  key: SearchParamKey.OriginalTransactionId,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const searchSubscriptionIdState = atom<number>({
  key: SearchParamKey.SubscriptionId,
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const statusState = atom<number>({
  key: SearchParamKey.Status,
  default: Status.Pending,
  effects: [syncEffect({ refine: number() })],
});

export const searchEmailState = atom({
  key: SearchParamKey.Email,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const searchUIDState = atom<string>({
  key: SearchParamKey.UID,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const searchIMEIState = atom({
  key: SearchParamKey.IMEI,
  default: "",
  effects: [syncEffect({ refine: string() })],
});
