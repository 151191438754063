import {
  Button,
  HStack,
  Link,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createCIDR, editCIDR } from "apis";
import { CIDRStatus } from "enums";
import { Suspense, useState } from "react";
import { useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState, ipDetailsQuery } from "states";
import { getErrorMessage } from "utils";
import { EmptyView } from "../other";
import { Label } from "./details-label";

export function IpLink({ ip, showFull }: { ip?: string; showFull?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (!ip) return <>--</>;

  return (
    <>
      <Link
        pointerEvents="auto"
        onClick={onOpen}>
        {showFull ? ip : ip.slice(-15)}
      </Link>

      <Modal
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>IP Quality Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Suspense fallback={<Spinner />}>
              <ModalView ip={ip} />
            </Suspense>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function ModalView({ ip }: { ip: string }) {
  const ipDetails = useRecoilValue(ipDetailsQuery(ip));

  if (!ipDetails) return <EmptyView />;

  return (
    <List spacing={4}>
      <Label title="IP">
        <HStack>
          <Text color={ipDetails.ipBlockId ? "red.400" : "green.400"}>
            {ipDetails.ip}
          </Text>
          <BanIpButton
            ip={ip}
            ipBlockId={ipDetails.ipBlockId}
          />
        </HStack>
      </Label>
      <Label title="ISP">
        <HStack>
          <Text color={ipDetails.ispBlockId ? "red.400" : "green.400"}>
            {ipDetails.isp}
          </Text>
        </HStack>
      </Label>
      <Label
        title="Connection Type"
        value={ipDetails.connectionType}
      />
      <Label
        title="Organization"
        value={ipDetails.organization}
      />
      <Label title="Proxy">
        <Tag colorScheme={ipDetails.proxy ? "red" : "green"}>
          {String(ipDetails.proxy)}
        </Tag>
      </Label>
      <Label title="VPN">
        <Tag colorScheme={ipDetails.vpn ? "red" : "green"}>
          {String(ipDetails.vpn)}
        </Tag>
      </Label>
      <Label title="Tor">
        <Tag colorScheme={ipDetails.tor ? "red" : "green"}>
          {String(ipDetails.tor)}
        </Tag>
      </Label>

      <Label title="Fraud Score">
        <Tag colorScheme={ipDetails.fraudScore > 70 ? "red" : "green"}>
          {String(ipDetails.fraudScore)}
        </Tag>
      </Label>

      <Label
        title="City"
        value={ipDetails.city}
      />
      <Label
        title="Country"
        value={ipDetails.countryCode}
      />
      <Label
        title="Timezone"
        value={ipDetails.timezone}
      />
    </List>
  );
}

function BanIpButton({ ip, ipBlockId }: { ip: string; ipBlockId: string }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBan = async () => {
    setIsSubmitting(true);

    try {
      if (ipBlockId) {
        // 取消拉黑IP
        await editCIDR({
          id: ipBlockId,
          ip: ip,
          mask: "0",
          status: CIDRStatus.Disabled,
        });
      } else {
        // 拉黑IP
        await createCIDR({
          ip: ip,
          mask: "0",
          status: CIDRStatus.Enabled,
        });
      }
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      size="xs"
      onClick={handleBan}
      isLoading={isSubmitting}>
      {ipBlockId ? "Unban" : "Ban"}
    </Button>
  );
}
