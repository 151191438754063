import { Tab, TabList, Tabs } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { Gender } from "enums";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const genderState = atom({
  key: "gender",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const genders = [Gender.Woman, Gender.Man, Gender.NonBinary];

export function GenderTabs() {
  const [genderTab, setGenderTab] = useRecoilState(genderState);

  return (
    <Tabs
      isLazy
      variant="soft-rounded"
      size="md"
      index={genderTab}
      onChange={setGenderTab}>
      <TabList>
        {genders.map((key, index) => (
          <Tab key={index}>{Gender[key]}</Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
