import { Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { RouterURI } from "router/router.uri";
import { searchIMEIState } from "states";

export function DeviceId(props: DeviceIdProps) {
  const navigate = useNavigate();
  const setSearchIMEI = useSetRecoilState(searchIMEIState);

  const handleClick = () => {
    setSearchIMEI(props.deviceId);
    navigate(RouterURI.Profiles);
  };

  return <Link onClick={handleClick}>{props.deviceId}</Link>;
}
interface DeviceIdProps {
  deviceId: string;
}
