import { Button } from "@chakra-ui/react";
import { submitUser } from "apis";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/router.uri";
import { reportedProfilesQuery } from "states";
import { openInNewTab } from "utils";

function OpenAllInNewTabButton() {
  const reports = useRecoilValue(reportedProfilesQuery);

  const openAllInNewTab = () => {
    if (!reports) {
      return;
    }

    reports.forEach((report) => {
      openInNewTab(RouterURI.Profiles + "/" + report.user.id);
    });

    submitUser({
      profileReportedUserIds: reports.map((r) => r.user.id),
    });
  };

  return <Button onClick={openAllInNewTab}>Open In New Tab</Button>;
}

export function OpenAllReports() {
  return (
    <Suspense>
      <OpenAllInNewTabButton />
    </Suspense>
  );
}
