import { ProfileStatus } from "enums";
import { atom, selector } from "recoil";
import { CheckboxFilter, FilterOption } from "./CheckboxFilter";

const profileStatuses = [
  ProfileStatus.Pending,
  ProfileStatus.Approved,
  ProfileStatus.Rejected,
  ProfileStatus.Deleted,
];

const profileStatusValuesState = atom<number[]>({
  key: "profile_statues/values",
  default: [],
});

export const profileStatusesQuery = selector({
  key: "profile_statuses",
  get: ({ get }) => {
    const statues = get(profileStatusValuesState);

    if (statues.length > 0) {
      return statues.map((index) => profileStatuses[index]);
    } else {
      // 重要，一定要拷贝一下，若直接返回relationshipStatuses数组，不会触发数据刷新
      return [...profileStatuses];
    }
  },
});

export function ProfileStatusFilter() {
  const options = profileStatuses.map((status, index) => {
    const opt: FilterOption = {
      title: ProfileStatus[status],
      value: index,
    };
    return opt;
  });

  return (
    <CheckboxFilter
      title="Status"
      state={profileStatusValuesState}
      options={options}
    />
  );
}
