import {
  HStack,
  Spinner,
  Stack,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MatchStatus } from "apis";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  matchRelationshipsCountQuery,
  matchRelationshipsQuery,
} from "states/relationship";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  HeaderBox,
  MainHeader,
  Pagination,
  SearchUIDInput,
} from "views/components";

export function MatchesPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={matchRelationshipsCountQuery} />
      </HeaderBox>

      {/* Filters */}
      <HStack alignItems="flex-end">
        <SearchUIDInput showDirection />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <MatchesTable />
      </Suspense>

      {/* Pagination */}
      <Pagination totalState={matchRelationshipsCountQuery} />
    </Stack>
  );
}

function MatchesTable() {
  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>From</Th>
          <Th>To</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>
      <MatchesTbody />
    </CustomTable>
  );
}

function MatchesTbody() {
  const relationships = useRecoilValue(matchRelationshipsQuery);

  return (
    <Tbody>
      {relationships.map((rel) => (
        <Tr key={rel.id}>
          <Td>{rel.id}</Td>
          <Td>
            <BasicUserInfoView userInfo={rel.fromUser} />
          </Td>
          <Td>
            <BasicUserInfoView userInfo={rel.toUser} />
          </Td>
          <Td>{MatchStatus[rel.status]}</Td>
          <Td>{timestampMsToDateStr(rel.createdAt)}</Td>
          <Td>{timestampMsToDateStr(rel.updatedAt)}</Td>
        </Tr>
      ))}
    </Tbody>
  );
}
