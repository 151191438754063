import { Td } from '@chakra-ui/react';
import { Platform } from 'enums';
import { Secret } from 'interfaces';
import { timestampMsToDateStr } from 'utils';
import { CustomTr } from 'views/components';
import { EditSecretButton } from './edit-secret-button';

// SecretsTableCell
export function SecretsTableCell(props: SecretsTableCellProps) {
  return (
    <CustomTr>
      <Td>
        <EditSecretButton secret={props.secret} />
      </Td>

      <Td>{Platform[props.secret.platform]}</Td>
      <Td>{props.secret.packageName}</Td>
      <Td>{props.secret.build}</Td>
      <Td>{props.secret.secret}</Td>

      <Td>{timestampMsToDateStr(props.secret.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.secret.updatedAt)}</Td>
    </CustomTr>
  );
}

interface SecretsTableCellProps {
  secret: Secret;
}