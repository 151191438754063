import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import { UserMenu } from "./other";
import { Breadcrumbs } from "./other/breadcrumbs";
import { ColorModeSwitcher } from "./other/color-mode-switcher";
import { SearchBar } from "./search/search-bar";

// MainNav 顶部导航
export function MainNav() {
  const bg = useColorModeValue("white", "gray.800");

  return (
    <Box
      as="header"
      w="100%"
      position="sticky"
      top={0}
      left={0}
      right={0}
      bg={bg}
      zIndex={10}
      py={2}
      px={4}
    >
      <HStack spacing={0} flex="1">
        <Breadcrumbs />

        <Spacer />
        <SearchBar />
        <ColorModeSwitcher />
        <UserMenu />
      </HStack>
    </Box>
  );
}
