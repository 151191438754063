import { HStack, Spacer, Spinner, Stack } from "@chakra-ui/react";
import { Status } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { seekingsCountQuery } from "states";

import {
  DateFilter,
  HeaderBox,
  MainHeader,
  SearchUIDInput,
  SeekingsTableView,
  SeekingStatusFilter,
  seekingStatusState
} from "views/components";

export function SeekingsPage() {
  const status = useRecoilValue(seekingStatusState);

  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={seekingsCountQuery} />
        <Spacer />
        {/* Filters */}
      </HeaderBox>

      <HStack>
        <SeekingStatusFilter />
        {status !== Status.Pending && <DateFilter />}
        <SearchUIDInput />
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <SeekingsTableView />
      </Suspense>
    </Stack>
  );
}
