import { Spacer, Spinner, Stack } from "@chakra-ui/react";
import { Suspense } from "react";
import { phonesCountQuery, phonesPageState } from "states";
import {
  HeaderBox,
  MainHeader,
  Pagination,
  PlatformTabs,
} from "views/components";
import { CreatePhoneModelButton } from "./create-model-button";
import { PhoneModelsTable } from "./models-table";

export function PhoneModelsPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={phonesCountQuery} />
        <Spacer />

        <CreatePhoneModelButton />
      </HeaderBox>

      <PlatformTabs />

      <Pagination
        state={phonesPageState}
        totalState={phonesCountQuery}
      />

      <Suspense fallback={<Spinner />}>
        <PhoneModelsTable />
      </Suspense>

      <Pagination
        state={phonesPageState}
        totalState={phonesCountQuery}
      />
    </Stack>
  );
}
