import {
  Spacer,
  Spinner,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { GenderTagStatus } from "enums";
import { GenderTag } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { gendersCountQuery, gendersPageState, gendersQuery } from "states";
import { timestampMsToDateStr } from "utils";
import {
  CustomTable,
  CustomTr,
  HeaderBox,
  MainHeader,
  Pagination,
  UserIdContainer,
} from "views/components";

export function GenderTagsPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={gendersCountQuery} />
        <Spacer />
      </HeaderBox>

      <Pagination
        state={gendersPageState}
        totalState={gendersCountQuery}
      />

      <Suspense fallback={<Spinner />}>
        <GenderTagsTable />
      </Suspense>

      {/* Pagination */}
      <Pagination
        state={gendersPageState}
        totalState={gendersCountQuery}
      />
    </Stack>
  );
}

function GenderTagsTable() {
  const genders = useRecoilValue(gendersQuery);
  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Gender Name</Th>
          <Th>From</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {genders.map((item) => (
          <GenderTagsTableItem
            key={item.id}
            genderTag={item}
          />
        ))}
      </Tbody>
    </CustomTable>
  );
}

interface GenderTagsTableItemProps {
  genderTag: GenderTag;
}

function GenderTagsTableItem(props: GenderTagsTableItemProps) {
  return (
    <CustomTr>
      <Td>{props.genderTag.id}</Td>
      <Td>{props.genderTag.name}</Td>
      <Td>
        <UserIdContainer uid={props.genderTag.uid}>
          <Text>{props.genderTag.userName}</Text>
        </UserIdContainer>
      </Td>
      <Td>{GenderTagStatus[props.genderTag.status]}</Td>
      <Td>{timestampMsToDateStr(props.genderTag.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.genderTag.updatedAt)}</Td>
    </CustomTr>
  );
}
