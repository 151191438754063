export enum ApiURL {
  // about
  AboutMeSearch = "/about/me/search",

  // seekings
  SeekingsSearch = "/seeking/search",
  SeekingDescriptionsSearch = "/seeking/search/review",
  SeekingDetails = "/seeking/details",
  SeekingMembersSearch = "/group/search",

  // Media
  PendingMediaListSearch = "/media/search/pending/review",
  MediaSearch = "/media/search",
  MediaSourcesSearch = "/media/spaces/url",
  UpdateMediaSource = "/media/recovery/spaces",

  // reports
  ReportedProfilesSearch = "/profile/report/search/review",
  SubmitReportedProfiles = "/profile/report/review",

  PendingReportedChannelsSearch = "/channel/profile/report/search/pending/review",
  SubmitPendingReportedChannels = "/channel/profile/report/review",

  // profiles
  ProfilesSearch = "/user/search",
  InReviewProfilesSearch = "/user/search/review",
  UnfinishedProfilesSearch = "/user/search/new",

  // user
  UserSearch = "/user/profile",
  UserMoreSearch = "/user/profile/more",
  UserRelationshipCountSearch = "/user/relationship/count",
  UserReportsSearch = "/profile/report/search",
  UserDiscoverSearch = "/discover/search",
  // Chats
  UserSessionsSearch = "/im/session/search",
  UserMessagesSearch = "/im/session/msg/search",

  DeleteProfile = "/user/delete",
  EditProfile = "/user/edit/profile",

  UserReview = "/user/review",

  // devices
  DevicesSearch = "/device/search",
  DevicesEdit = "/device/switch/disable/enable",

  // Relationship
  RelationshipPassedSearch = "/user/passed/search",
  RelationshipLikeSearch = "/user/like/search",
  RelationshipGiftSearch = "/user/give/gift/search",
  RelationshipMatchSearch = "/user/match/search",
  RelationshipInstantChatSearch = "/user/instant/chat/search",
  RelationshipBlockSearch = "/user/block/search",

  // Subscription
  SubscriptionsSearch = "/user/subscription/search",
  SubscriptionNotificationsSearch = "/user/subscription/callback/record/search",
  SubscriptionTransferRecordsSearch = "/user/subscription/transfer/record/search",

  // Products
  BoostsSearch = "/boost/search",
  GiftsSearch = "/gift/search",
  InstantChantsSearch = "/instant/chat/search",

  // Flow
  FlowsSearch = "/user/flow/search",

  // Orders
  OrdersSearch = "/iap/order/search",

  // manage members
  AdminUserAdd = "/member/add",
  AdminUserDelete = "/member/del",
  AdminUserEdit = "/member/edit",
  AdminUserSearch = "/member/search",
  AdminUserActivate = "/member/activation",

  // block ip
  BlockedIPSearch = "/ip/block/search",
  BlockedIPDetails = "/ip/block/details",
  BlockedIPEdit = "/ip/block/edit",
  BlockedIPCreate = "/ip/block/add",
  BlockedIPDetect = "/ip/block/check",

  IpDetails = "/ip/details/third",

  // Phone Model
  PhoneModelSearch = "/phone/model/search",
  PhoneModelCreate = "/phone/model/add",
  PhoneModelEdit = "/phone/model/edit",
  PhoneModelDelete = "/phone/model/del",

  // secrets
  SecretsSearch = "/config/secret/search",
  SecretsCreate = "/config/secret/add",
  SecretsEdit = "/config/secret/edit",

  GenderTagSearch = "/gender/more/search/pending/review",

  // Captcha
  CaptchaSearch = "/captcha/search",
  CaptchaAdd = "/captcha/add",
  CaptchaEdit = "/captcha/edit",
  CaptchaDelete = "/captcha/del",

  // settings
  AutoReviewStatusSearch = "/config/auto/audit/media",
  ToggleAutoReviewStatus = "/config/edit/auto/audit/media",
}
