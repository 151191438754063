import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Platform } from "enums";
import { SubscriptionNotification } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { notificationsQuery } from "states";
import { timestampMsToDateStr } from "utils";
import { CustomTable, CustomTr, EmptyView } from "views/components";

export function NotificationsView() {
  return (
    <Suspense fallback={<Spinner />}>
      <NotificationsTable />
    </Suspense>
  );
}
function NotificationsTable() {
  const notifications = useRecoilValue(notificationsQuery);

  return (
    <>
      {notifications.length === 0 && <EmptyView />}
      {notifications.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Platform</Th>
              <Th>Original Transaction ID</Th>
              <Th>Type</Th>
              <Th>Message Date</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {notifications.map((ns, index) => (
              <NotificationsTableCell
                notification={ns}
                key={index}
              />
            ))}
          </Tbody>
        </CustomTable>
      )}
    </>
  );
}

interface NotificationsTableCellProps {
  notification: SubscriptionNotification;
}

function NotificationsTableCell(props: NotificationsTableCellProps) {
  const { notification } = props;

  return (
    <CustomTr>
      <Td>{notification.id}</Td>
      <Td>{Platform[notification.platform]}</Td>
      <Td>{notification.originalTransactionId}</Td>
      <Td>{notification.type}</Td>
      <Td>{timestampMsToDateStr(notification.eventTime)}</Td>
      <Td>{timestampMsToDateStr(notification.createdAt)}</Td>
      <Td>{timestampMsToDateStr(notification.updatedAt)}</Td>
    </CustomTr>
  );
}
