import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { createSecret, editSecret } from "apis";
import dayjs from "dayjs";
import { Platform, SearchParamKey } from "enums";
import { Secret, SecretToCreate, SecretToUpdate } from "interfaces";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { searchPageState, timestampAtom } from "states";
import { getEnumKeys, getErrorMessage, randomString } from "utils";
import { platformState } from "views/components";

// SecretModalContent
export function SecretModalContent(props: SecretModalContentProps) {
  const [secretToCreate, setSecretToCreate] = useState<SecretToCreate>({
    platform: props.secret?.platform ?? Platform.iOS,
    build: props.secret?.build ?? "",
    secret: props.secret?.secret ?? "",
    packageName: props.secret?.packageName ?? "",
  });

  const handleChangePlatform = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {
      target: { value },
    } = event;
    const platform = parseInt(value) as Platform;
    setSecretToCreate((currVal) => {
      return { ...currVal, platform };
    });
  };

  const handleChangePackageName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event;
    setSecretToCreate((currVal) => {
      return { ...currVal, packageName: value };
    });
  };

  const handleChangeBuild = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSecretToCreate((currVal) => {
      return { ...currVal, build: value };
    });
  };

  const handleChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSecretToCreate((currVal) => {
      return { ...currVal, secret: value };
    });
  };

  const handleGenerateSecret = () => {
    const secret = randomString(16);
    setSecretToCreate((currVal) => {
      return { ...currVal, secret };
    });
  };

  const getBundleIdAndPackageNameLabel = () => {
    if (secretToCreate.platform === Platform.iOS) {
      return "Bundle ID";
    } else {
      return "Package Name";
    }
  };

  return (
    <ModalContent>
      <ModalHeader>{props.secret ? "Edit" : "Create"} Secret</ModalHeader>

      <ModalBody>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Platform</FormLabel>
            <Select
              defaultValue={secretToCreate.platform}
              onChange={handleChangePlatform}>
              {getEnumKeys(Platform).map((key, index) => (
                <option
                  key={index}
                  value={Platform[key]}>
                  {key}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{getBundleIdAndPackageNameLabel()}</FormLabel>
            <Input
              // type="date"
              placeholder={getBundleIdAndPackageNameLabel()}
              defaultValue={secretToCreate.packageName}
              onChange={handleChangePackageName}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Build</FormLabel>
            <Input
              // type="date"
              placeholder="Build"
              defaultValue={secretToCreate.build}
              onChange={handleChangeBuild}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Secret</FormLabel>
            <HStack>
              <Input
                // type="date"
                placeholder="Secret"
                defaultValue={secretToCreate.secret}
                onChange={handleChangeSecret}
              />
              <Button onClick={handleGenerateSecret}>Generate</Button>
            </HStack>
          </FormControl>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          onClick={props.onClose}>
          Close
        </Button>

        {/* Create Button */}
        {!props.secret && (
          <CreateButton
            secretToCreate={secretToCreate}
            callback={props.onClose}
          />
        )}

        {/* Save Button */}
        {props.secret && (
          <EditButton
            secretToUpdate={{ ...secretToCreate, id: props.secret.id }}
            callback={props.onClose}
          />
        )}
      </ModalFooter>
    </ModalContent>
  );
}

interface SecretModalContentProps {
  secret?: Secret;
  onClose?: () => void;
}

interface CreateButtonProps {
  secretToCreate: SecretToCreate;
  callback?: () => void;
}

function CreateButton(props: CreateButtonProps) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetPage = useResetRecoilState(searchPageState);
  const refresh = useSetRecoilState(timestampAtom);
  const setPlatform = useSetRecoilState(platformState);

  const handleCreate = async () => {
    setIsSubmitting(true);

    try {
      await createSecret(props.secretToCreate);
      toast({
        title: "Create Secret Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh(dayjs().valueOf());
      resetPage();
      setPlatform(props.secretToCreate.platform);
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Create Secret Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleCreate}>
      Create
    </Button>
  );
}

interface EditButtonProps {
  secretToUpdate: SecretToUpdate;
  callback?: () => void;
}

function EditButton(props: EditButtonProps) {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);

    try {
      await editSecret(props.secretToUpdate);

      toast({
        title: "Edit Secret Success",
        status: "success",
      });

      setIsSubmitting(false);

      props.callback && props.callback();

      // 刷新
      refresh();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Edit Secret Failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const refresh = () => {
    // 设定search params时间戳，触发review 列表自动拉取数据
    searchParams.set(SearchParamKey.Timestamp, dayjs().valueOf().toString());
    setSearchParams(searchParams);
  };

  return (
    <Button
      ml={3}
      colorScheme="pink"
      isLoading={isSubmitting}
      onClick={handleSave}>
      Save
    </Button>
  );
}
