import {
  HStack,
  Spacer,
  Spinner,
  Stack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Status } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { mediasCountQuery, mediasPageState, mediasQuery } from "states";
import {
  DateFilter,
  MediaStatusFilter,
  mediaStatusState,
  Pagination,
  ProfileMediaView,
} from "views/components";

export function MediaListPage() {
  const status = useRecoilValue(mediaStatusState);

  return (
    <Stack>
      <HStack>
        <MediaStatusFilter />
        {status !== Status.Pending && <DateFilter />}
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <MediaGrid />
      </Suspense>

      <Pagination
        state={mediasPageState}
        totalState={mediasCountQuery}
      />
    </Stack>
  );
}

export function MediaGrid() {
  const medias = useRecoilValue(mediasQuery);

  return (
    <Wrap>
      {medias.map((media) => (
        <WrapItem key={media.id}>
          <ProfileMediaView
            media={media}
            uid={media.user?.id}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}
