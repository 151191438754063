/* API */

import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, BasicProfile, ListParams, Timestamps } from "interfaces";

export const fetchRelationshipGift = (params: GiftParams) => {
  return axiosInstance.post<GiftRelationshipsResponse>(
    ApiURL.RelationshipGiftSearch,
    params
  );
};

/* Statues */
export enum GiftStatus {
  Sent = 0,
  Received = 1,
  Rejected = 2,
  Deleted = 3,
}

/* Params */

export interface GiftParams extends ListParams {
  fromUid?: string;
  toUid?: string;
  status?: GiftStatus;
}

/* Response */
export interface GiftRelationship extends Timestamps {
  id: string;
  fromUser: BasicProfile;
  toUser: BasicProfile;
  note: string;
  status: GiftStatus;
}

type GiftRelationshipsResponse = APIResponse<GiftRelationship[]>;
