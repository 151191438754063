import { fetchDevices } from "apis";
import dayjs from "dayjs";
import { DevicesListParams } from "interfaces";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { deviceStatusState, searchTimestampsQuery } from "views/components";
import { errorState } from "../common";
import { searchIMEIState, searchPageSizeState } from "../search";

export const devicesPageState = atom({
  key: "devices/page",
  default: 1,
});

export const devicesRequestIdState = atom({
  key: "devices/requestId",
  default: dayjs().valueOf(),
});

const devicesRespQuery = selector({
  key: "devices/resp",
  get: async ({ get }) => {
    get(devicesRequestIdState);

    const params: DevicesListParams = {
      page: get(devicesPageState),
      pageSize: get(searchPageSizeState),
    };

    const imei = get(searchIMEIState);

    if (!imei) {
      params.status = get(deviceStatusState);
      const searchTimestamps = get(searchTimestampsQuery);

      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.imei = imei;
    }

    try {
      const resp = await fetchDevices(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const devicesQuery = selector({
  key: "devices",
  get: ({ get }) => {
    return get(devicesRespQuery)?.data ?? [];
  },
});

export const devicesCountQuery = selector({
  key: "devices/count",
  get: ({ get }) => {
    return get(devicesRespQuery)?.page?.total ?? 0;
  },
});
