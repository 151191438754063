import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { editProfile } from "apis";
import { DeviceStatus, RiskStatus } from "enums";
import { Device, Profile } from "interfaces";
import _ from "lodash";
import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { editDeviceQuery, editProfileQuery, errorState } from "states";
import { getErrorMessage } from "utils";

export type EditProfileProps = {
  profile: Profile;
  device?: Device;
};

export function BanOrRestoreProfileButton({
  profile,
  device,
}: EditProfileProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedProfile, setEditedProfile] = useRecoilState(
    editProfileQuery(profile.id)
  );

  const setEditedDevice = useSetRecoilState(editDeviceQuery(device?.id ?? ""));

  const updatedProfile = editedProfile ?? profile;

  if (updatedProfile.riskStatus === RiskStatus.Underage) return <></>;

  const _getToastTitle = () => {
    return updatedProfile.riskStatus !== RiskStatus.Banned ? "Ban" : "Restore";
  };

  const handleClick = async () => {
    setIsSubmitting(true);

    const targetRiskStatus =
      updatedProfile.riskStatus === RiskStatus.Banned
        ? RiskStatus.Confirmed
        : RiskStatus.Banned;

    try {
      await editProfile({
        ...updatedProfile,
        riskStatus: targetRiskStatus,
      });

      setEditedProfile((curr) => {
        let p = _.cloneDeep(curr);

        if (p) {
          p.riskStatus = targetRiskStatus;
        } else {
          p = { ...updatedProfile, riskStatus: targetRiskStatus };
        }

        return p;
      });

      // 更改本地设备状态
      if (device) {
        let targetDeviceStatus: DeviceStatus;

        if (
          [RiskStatus.Normal, RiskStatus.Confirmed].includes(targetRiskStatus)
        ) {
          targetDeviceStatus = DeviceStatus.Normal;
        } else if (
          [RiskStatus.Suspicious, RiskStatus.Banned].includes(targetRiskStatus)
        ) {
          targetDeviceStatus = DeviceStatus.Banned;
        } else {
          // underage
          targetDeviceStatus = DeviceStatus.Underage;
        }

        setEditedDevice((curr) => {
          let d = _.cloneDeep(curr);

          if (d) {
            // 更改设备状态值后放入缓存中
            d.status = targetDeviceStatus;
          } else {
            // 当前device修改状态后放入本地缓存中
            d = { ...device, status: targetDeviceStatus };
          }

          return d;
        });
      }

      onClose();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Button onClick={onOpen}>{_getToastTitle()} Profile</Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{_getToastTitle()}?</AlertDialogHeader>

          <AlertDialogBody>
            Ban
            Profile时，设备不为Underage时会变为Banned，恢复Profile时，设备不为Underage时会变为Normal。Are
            you sure?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="outline"
              ref={cancelRef}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              ml={3}
              onClick={handleClick}
              isLoading={isSubmitting}>
              {_getToastTitle()}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
