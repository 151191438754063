import { Select } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export enum RelationshipDirection {
  From,
  To,
}

export const relationshipDirectionState = atom({
  key: "direction",
  default: RelationshipDirection.From,
  effects: [syncEffect({ refine: number() })],
});

export function SearchRelationshipDirection() {
  const [direction, setDirection] = useRecoilState(relationshipDirectionState);

  return (
    <Select
      size="sm"
      variant="outline"
      defaultValue={direction}
      onChange={(e) => setDirection(+e.target.value)}
    >
      <option value="0">From</option>
      <option value="1">To</option>
    </Select>
  );
}
