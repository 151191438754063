import { fetchPendingProfiles } from "apis";
import dayjs from "dayjs";
import { RiskStatus } from "enums";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import { searchPageSizeState } from "states/search";
import { getErrorMessage } from "utils";
import { genders, genderState } from "views/components";

export const pendingProfilesRequestIdState = atom({
  key: "profiles/pending/requestId",
  default: dayjs().valueOf(),
});

const pendingProfilesRespQuery = selectorFamily({
  key: "profiles/pending/resp",
  get:
    (riskStatus: RiskStatus) =>
    async ({ get }) => {
      get(pendingProfilesRequestIdState);

      const pageSize = get(searchPageSizeState);
      const genderTabIndex = get(genderState);

      try {
        const resp = await fetchPendingProfiles({
          page: 1,
          pageSize: pageSize,
          gender: genders[genderTabIndex],
          riskStatus: riskStatus,
        });

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const pendingProfilesQuery = selectorFamily({
  key: "profiles/pending",
  get:
    (riskStatus: RiskStatus) =>
    ({ get }) => {
      return get(pendingProfilesRespQuery(riskStatus))?.data ?? [];
    },
});

export const pendingProfilesCountQuery = selectorFamily({
  key: "profiles/pending/count",
  get:
    (riskStatus: RiskStatus) =>
    ({ get }) => {
      return get(pendingProfilesRespQuery(riskStatus))?.page?.total ?? 0;
    },
});
