import { Suspense } from "react";
import { RecoilState, RecoilValueReadOnly } from "recoil";
import { PaginationContent } from "./PaginationContent";

export interface PaginationProps {
  totalState?: RecoilValueReadOnly<number>;
  state?: RecoilState<number>;
}

export function Pagination(props: PaginationProps) {
  return (
    <Suspense fallback={<></>}>
      {props.totalState && (
        <PaginationContent
          totalState={props.totalState}
          state={props.state}
        />
      )}
    </Suspense>
  );
}
