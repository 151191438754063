import { Stack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { SeekingStatus } from "enums";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./filter-selector";

export const seekingStatusState = atom({
  key: "seeking_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const seekingStatuses = [
  SeekingStatus.Pending,
  SeekingStatus.Approved,
  SeekingStatus.Rejected,
  SeekingStatus.Deleted,
];

export function SeekingStatusFilter() {
  const options = seekingStatuses.map((status) => {
    const option: FilterSelectorOption = {
      title: SeekingStatus[status],
      value: status,
    };

    return option;
  });

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Status
      </Text>
      <FilterSelector
        options={options}
        state={seekingStatusState}
      />
    </Stack>
  );
}
