import { AspectRatio, Image, ImageProps } from "@chakra-ui/react";

export function ImageView(props: ImageProps) {
  return (
    <AspectRatio ratio={10 / 16}>
      <Image
        objectFit="cover"
        {...props}
      />
    </AspectRatio>
  );
}
