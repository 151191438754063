import {
  APIResponse,
  PhoneModel,
  PhoneModelListParams,
  PhoneModelToCreate,
  PhoneModelToUpdate,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const fetchPhoneModels = (params: PhoneModelListParams) => {
  return axiosInstance.post<APIResponse<PhoneModel[]>>(
    ApiURL.PhoneModelSearch,
    params
  );
};

export const createPhoneModel = (phoneModelToCreate: PhoneModelToCreate) => {
  return axiosInstance.post(ApiURL.PhoneModelCreate, phoneModelToCreate);
};

export const editPhoneModel = (phoneModelToUpdate: PhoneModelToUpdate) => {
  return axiosInstance.post(ApiURL.PhoneModelEdit, phoneModelToUpdate);
};

export const deletePhoneModel = (id: string) => {
  return axiosInstance.delete(ApiURL.PhoneModelDelete + "/" + id);
};
