// 订购类型
export enum ProductType {
  Subscription = 1,
  Boost = 2,
  Gift = 3,
  InstantChat = 4,
}

// 流水记录查询参数类型
export enum FlowParamType {
  BoostPurchase = 1,
  SubscriptionPurchase = 2,
  BoostConsume = 3,
  BoostReward = 4,
  GiftPurchase = 5,
  GiftConsume = 6,
  GiftReward = 7,
  InstantChatPurchase = 8,
  InstantChatConsume = 9,
  InstantChatReward = 10,
}

// 流水记录展示类型
export enum FlowType {
  Unknown = 0,
  Purchase = 1,
  Reward = 2,
  Consume = 3,
}

export enum FlowDirection {
  In = 1,
  Out = 2,
}

// // https://developer.apple.com/documentation/appstoreservernotifications/notification_type
// enum AppleIAPNotificationType {
//   // Indicates that Apple Support canceled the auto-renewable subscription and the customer received a refund as of the timestamp in cancellation_date_ms.
//   CANCEL,

//   // Indicates that the customer initiated a refund request for a consumable in-app purchase, and the App Store is requesting that you provide consumption data. For more information, see Send Consumption Information.
//   CONSUMPTION_REQUEST,

//   // Indicates that the customer made a change in their subscription plan that takes effect at the next renewal. The currently active plan isn’t affected. Check the auto_renew_product_id field in unified_receipt.Pending_renewal_info to retrieve the product identifier for the product the customer’s subscription renews.
//   DID_CHANGE_RENEWAL_PREF,

//   // Indicates a change in the subscription renewal status. In the JSON response, check auto_renew_status_change_date_ms to retrieve the date and time of the last status update. Check auto_renew_status to get the current renewal status.
//   DID_CHANGE_RENEWAL_STATUS,

//   // Indicates a subscription that failed to renew due to a billing issue. Check is_in_billing_retry_period to retrieve the current retry status of the subscription. Check grace_period_expires_date to get the new service expiration date if the subscription is in a billing grace period.
//   DID_FAIL_TO_RENEW,

//   // Indicates a successful automatic renewal of an expired subscription that failed to renew in the past. Check expires_date to determine the next renewal date and time.
//   DID_RECOVER,

//   // Indicates that a customer’s subscription has successfully auto-renewed for a new transaction period. Provide the customer with access to the subscription’s content or service.
//   DID_RENEW,

//   // Occurs at the user’s initial purchase of the subscription. Store latest_receipt on your server as a token to verify the user’s subscription status at any time by validating it with the App Store.
//   INITIAL_BUY,

//   // Indicates the customer renewed a subscription interactively, either by using your app’s interface, or on the App Store in the account’s Subscriptions settings. Make service available immediately.
//   INTERACTIVE_RENEWAL,

//   // Indicates that the App Store has started asking the customer to consent to your app’s auto-renewable subscription price increase that requires consent.

//   // In the unified_receipt.Pending_renewal_info object, the price_consent_status value is 0 to indicate that the user hasn’t yet responded to the price increase.

//   // The App Store server sets the price_consent_status to 1 when the customer consents to the price increase.

//   // Check the latest price consent status by calling the Get All Subscription Statuses endpoint in the App Store Server API. Check the priceIncreaseStatus field in the JWSRenewalInfoDecodedPayload. You can also call verifyReceipt to view the updated price consent status.

//   // For more information about how StoreKit calls your app before it displays the price consent sheet for subscription price increases that require customer consent, see paymentQueueShouldShowPriceConsent(_:). For more information about managing subscription prices, see Managing Prices.
//   PRICE_INCREASE_CONSENT,

//   // Indicates that the App Store successfully refunded a transaction for a consumable in-app purchase, a non-consumable in-app purchase, or a non-renewing subscription. The cancellation_date_ms contains the timestamp of the refunded transaction. The original_transaction_id and product_id identify the original transaction and product. The cancellation_reason contains the reason.
//   REFUND,

//   // Indicates that an in-app purchase the user was entitled to through Family Sharing is no longer available through sharing. StoreKit sends this notification when a purchaser disabled Family Sharing for a product, the purchaser (or family member) left the family group, or the purchaser asked for and received a refund. Your app will also receive a paymentQueue(_:didRevokeEntitlementsForProductIdentifiers:) call. For more information about Family Sharing, see Supporting Family Sharing in Your App.
//   REVOKE,
// }

// // 订阅的 notificationType 可以具有以下值
// // https://developer.android.com/google/play/billing/rtdn-reference
// enum GoogleIAPNotificationType {
//   // 从帐号保留状态恢复了订阅。
//   SUBSCRIPTION_RECOVERED,

//   // 续订了处于活动状态的订阅。
//   SUBSCRIPTION_RENEWED,

//   // 自愿或非自愿地取消了订阅。如果是自愿取消，在用户取消时发送。
//   SUBSCRIPTION_CANCELED,

//   // 购买了新的订阅。
//   SUBSCRIPTION_PURCHASED,

//   // 订阅已进入帐号保留状态（如果已启用）。
//   SUBSCRIPTION_ON_HOLD,

//   // 订阅已进入宽限期（如果已启用）。
//   SUBSCRIPTION_IN_GRACE_PERIOD,

//   // 用户已通过 Play > 帐号 > 订阅恢复了订阅。订阅已取消，但在用户恢复时尚未到期。如需了解详情，请参阅 [恢复](/google/play/billing/subscriptions#restore)。

//   SUBSCRIPTION_RESTARTED,
//   // 用户已成功确认订阅价格变动。
//   SUBSCRIPTION_PRICE_CHANGE_CONFIRMED,

//   // 订阅的续订时间点已延期。
//   SUBSCRIPTION_DEFERRED,

//   // 订阅已暂停。
//   SUBSCRIPTION_PAUSED,

//   // 订阅暂停计划已更改。
//   SUBSCRIPTION_PAUSE_SCHEDULE_CHANGED,

//   // 用户在到期时间之前已撤消订阅。
//   SUBSCRIPTION_REVOKED,

//   // 订阅已到期。
//   SUBSCRIPTION_EXPIRED,
// }

// // Android Pub/Sub 推送中包含的订阅状态
// // https://developers.google.com/android-publisher/api-ref/rest/v3/purchases.subscriptionsv2#SubscriptionState
// enum SubscriptionState {
//   // 未指定订阅状态。
//   SUBSCRIPTION_STATE_UNSPECIFIED,

//   // 订阅已创建，但在注册期间正在等待付款。在此状态下，所有商品都正在等待付款。
//   SUBSCRIPTION_STATE_PENDING,

//   // 订阅处于有效状态。- (1) 如果订阅是自动续订方案，则至少有一个项目已自动续订且未过期。- (2) 如果订阅是预付费方案，至少有一项不会过期。
//   SUBSCRIPTION_STATE_ACTIVE,

//   // 订阅已暂停。仅当订阅是自动续订方案时，这个状态才可用。在此状态下，所有内容都会处于暂停状态。
//   SUBSCRIPTION_STATE_PAUSED,

//   // 订阅处于宽限期。仅当订阅是自动续订方案时，这个状态才可用。在此状态下，所有内容都处于宽限期。
//   SUBSCRIPTION_STATE_IN_GRACE_PERIOD,

//   // 订阅处于暂停状态（已暂停）。仅当订阅是自动续订方案时，这个状态才可用。在此状态下，所有内容都会处于保全状态。
//   SUBSCRIPTION_STATE_ON_HOLD,

//   // 订阅已取消，但尚未到期。仅当订阅是自动续订方案时，这个状态才可用。所有内容的 autoRenewEnabled 都设为 false。
//   SUBSCRIPTION_STATE_CANCELED,

//   // 订阅已过期。所有项的过期时间均为过去时间。
//   SUBSCRIPTION_STATE_EXPIRED,
// }
