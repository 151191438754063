import { Tab, TabList, Tabs } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { ChannelType } from "enums";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const channelTabState = atom({
  key: "channel_tab",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const channelTypes = [ChannelType.Peer, ChannelType.Group];

export function ChannelTabs() {
  const [tabIndex, setTabIndex] = useRecoilState(channelTabState);

  // tab变更时，更新search params中，将自动动刷新
  const handleChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Tabs
      isLazy
      colorScheme="pink"
      my={4}
      index={tabIndex}
      onChange={handleChange}>
      <TabList>
        {channelTypes.map((key, index) => (
          <Tab key={index}>{ChannelType[key]}</Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
