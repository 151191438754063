import { Link, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Secret } from 'interfaces';
import { SecretModalContent } from './secret-modal-content';

export function EditSecretButton(props: EditSecretButtonProps) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link onClick={onOpen}>
        {props.secret.id}
      </Link>

      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <SecretModalContent onClose={onClose} secret={props.secret} />
      </Modal>
    </>
  );
}
interface EditSecretButtonProps {
  secret: Secret;
}
