import { HStack, Spacer, Spinner, Stack } from "@chakra-ui/react";
import { Status } from "enums";

import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  aboutMesCountQuery,
  aboutMesPageState,
  aboutMesQuery,
  rejectedAboutMeIdsState,
} from "states";
import {
  AboutMeStatusFilter,
  aboutMeStatusState,
  DateFilter,
  Pagination,
  ProfileTextsTable,
} from "views/components";

export function AboutMePage() {
  const status = useRecoilValue(aboutMeStatusState);

  return (
    <Stack>
      <HStack>
        <AboutMeStatusFilter />
        {status !== Status.Pending && <DateFilter />}
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <ProfileTextsTable
          state={aboutMesQuery}
          rejectedState={rejectedAboutMeIdsState}
        />
      </Suspense>

      <Pagination
        totalState={aboutMesCountQuery}
        state={aboutMesPageState}
      />
    </Stack>
  );
}
