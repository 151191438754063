import { fetchMessages, fetchSessions, MessagesParams } from "apis";
import { Session } from "interfaces";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "./common";

export const currentSessionState = atom<Session | undefined>({
  key: "current_session_id",
  default: undefined,
});

export const lastSessionIdState = atom({
  key: "last_session_id",
  default: "",
});

export const sessionsRespQuery = selectorFamily({
  key: "sessions/resp",
  get:
    (uid: string) =>
    async ({ get }) => {
      const lastId = get(lastSessionIdState);

      try {
        const resp = await fetchSessions({
          uid,
          pageSize: 60,
          lastId,
        });

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const sessionsQuery = selectorFamily({
  key: "sessions",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(sessionsRespQuery(uid))?.data ?? [];
    },
});

export const lastSessionIdQuery = selectorFamily({
  key: "sessions/lastId",
  get:
    (uid: string) =>
    ({ get }) => {
      return get(sessionsRespQuery(uid))?.data ?? [];
    },
});

export const messagesRespQuery = selectorFamily({
  key: "messages/resp",
  get:
    (params: MessagesParams) =>
    async ({ get }) => {
      // const lastId = get(lastSessionIdState);

      try {
        const resp = await fetchMessages(params);

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const messagesQuery = selectorFamily({
  key: "messages",
  get:
    (params: MessagesParams) =>
    ({ get }) => {
      return get(messagesRespQuery(params))?.data ?? [];
    },
});

export const lastMessageIdQuery = selectorFamily({
  key: "messages/lastId",
  get:
    (uid: MessagesParams) =>
    ({ get }) => {
      return get(messagesRespQuery(uid))?.data ?? [];
    },
});
