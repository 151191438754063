import { fetchSubscriptionTransferRecords } from "apis";
import { TransferRecordsParams } from "interfaces";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchOriginalTransactionIdState,
  searchPageSizeState,
  searchPageState,
} from "states/search";
import { getErrorMessage } from "utils";
import { searchTimestampsQuery } from "views/components";

const transferRecordsRespQuery = selector({
  key: "transferRecords/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const originalTransactionId = get(searchOriginalTransactionIdState);

    const params: TransferRecordsParams = {
      page: page,
      pageSize: pageSize,
      // search ids
      originalTransactionId,
    };

    if (!originalTransactionId) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;
    }

    try {
      const resp = await fetchSubscriptionTransferRecords(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const transferRecordsQuery = selector({
  key: "transferRecords",
  get: ({ get }) => {
    return get(transferRecordsRespQuery)?.data ?? [];
  },
});

export const transferRecordsTotalQuery = selector({
  key: "transferRecords/total",
  get: ({ get }) => {
    const resp = get(transferRecordsRespQuery);

    return resp?.page?.total ?? 0;
  },
});
