import { fetchPhoneModels } from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { platforms, tabState } from "views/components";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const phonesRequestIdState = atom({
  key: "phones/requestId",
  default: dayjs().valueOf(),
});

export const phonesPageState = atom({
  key: "phones/page",
  default: 1,
});

export const phonesRespQuery = selector({
  key: "phones/resp",
  get: async ({ get }) => {
    get(phonesRequestIdState);

    try {
      const resp = await fetchPhoneModels({
        page: get(phonesPageState),
        pageSize: get(searchPageSizeState),
        platform: platforms[get(tabState)],
      });

      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const phonesQuery = selector({
  key: "phones",
  get: ({ get }) => get(phonesRespQuery)?.data ?? [],
});

export const phonesCountQuery = selector({
  key: "phones/count",
  get: ({ get }) => get(phonesRespQuery)?.page?.total ?? 0,
});
