import { Stack } from "@chakra-ui/react";
import { Gender, ShowMe } from "enums";
import { DiscoverFilter, SeekingFilter } from "interfaces";
import { inchesToHeightInFeetAndInches, milesToKilometers } from "utils";
import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { Caption } from "./details-caption";
import { Label } from "./details-label";

export function ProfileFiltersSection(props: ProfileFiltersSectionProps) {
  return (
    <>
      <Stack>
        <Caption title="Discover Filters" />
        <CustomSimpleGrid>
          <Label
            title="Looking For"
            value={props.discoverFilter.genders
              .map((item) => Gender[item])
              .join(", ")}
          />

          <Label
            title="Distance"
            value={
              props.discoverFilter.distance +
              "mi / " +
              Math.ceil(milesToKilometers(props.discoverFilter.distance)) +
              "km"
            }
          />

          <Label
            title="Show Couples"
            value={props.discoverFilter.showMe
              .map((item) => ShowMe[item])
              .join(", ")}
          />

          <Label
            title="Age"
            value={
              props.discoverFilter.minAge +
              " - " +
              props.discoverFilter.maxAge +
              (props.discoverFilter.maxAge === 55 ? "+" : "")
            }
          />
          <Label
            title="Height"
            value={
              inchesToHeightInFeetAndInches(props.discoverFilter.minHeight) +
              " - " +
              inchesToHeightInFeetAndInches(props.discoverFilter.maxHeight)
            }
          />
        </CustomSimpleGrid>
      </Stack>

      <Stack>
        <Caption title="Seeking Filters" />
        <CustomSimpleGrid
          spacing={8}
          columns={4}>
          <Label
            title="Looking For"
            value={props.seekingFilter.genders
              .map((item) => Gender[item])
              .join(", ")}
          />
          <Label
            title="Distance"
            value={
              props.seekingFilter.distance +
              "mi / " +
              Math.ceil(milesToKilometers(props.seekingFilter.distance)) +
              "km"
            }
          />
        </CustomSimpleGrid>
      </Stack>
    </>
  );
}
interface ProfileFiltersSectionProps {
  discoverFilter: DiscoverFilter;
  seekingFilter: SeekingFilter;
}
