import { Spinner, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { FlowDirection, FlowParamType, FlowType } from "enums";
import { Flow } from "interfaces";
import { Suspense } from "react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView,
} from "views/components";

type FlowsTableProps = {
  state: RecoilValueReadOnly<Flow[]>;
};

export function FlowsView({ state }: FlowsTableProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <FlowsTable state={state} />
    </Suspense>
  );
}

function FlowsTable({ state }: FlowsTableProps) {
  const flows = useRecoilValue(state);

  return (
    <>
      {flows.length === 0 && <EmptyView />}
      {flows.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>User</Th>
              <Th>Type</Th>
              <Th>Change</Th>
              <Th>After</Th>
              <Th>Note</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {flows.map((flow) => (
              <FlowTr
                flow={flow}
                key={flow.id}
              />
            ))}
          </Tbody>
        </CustomTable>
      )}
    </>
  );
}

interface FlowTrProps {
  flow: Flow;
}

function FlowTr(props: FlowTrProps) {
  const { flow } = props;

  const getDirectionIcon = () => {
    if (flow.direction === FlowDirection.In) {
      return <Text color="green">+ {flow.quantity}</Text>;
    } else if (flow.direction === FlowDirection.Out) {
      return <Text color="red">- {flow.quantity}</Text>;
    } else {
      return <Text color="yellow">? {flow.quantity}</Text>;
    }
  };

  return (
    <CustomTr>
      <Td>{flow.id}</Td>
      <Td>
        <BasicUserInfoView userInfo={flow.user} />
      </Td>
      <Td>{_getProductRecordType(flow.type)}</Td>
      <Td>{getDirectionIcon()}</Td>
      <Td>{flow.subTitle}</Td>
      <Td>{flow.externalId}</Td>
      <Td>{timestampMsToDateStr(flow.createdAt)}</Td>
      <Td>{timestampMsToDateStr(flow.updatedAt)}</Td>
    </CustomTr>
  );
}

function _getProductRecordType(type: FlowParamType) {
  let productRecordType: FlowType;

  switch (type) {
    case FlowParamType.BoostPurchase:
    case FlowParamType.GiftPurchase:
    case FlowParamType.InstantChatPurchase:
      productRecordType = FlowType.Purchase;
      break;
    case FlowParamType.BoostConsume:
    case FlowParamType.GiftConsume:
    case FlowParamType.InstantChatConsume:
      productRecordType = FlowType.Consume;
      break;
    case FlowParamType.BoostReward:
    case FlowParamType.GiftReward:
    case FlowParamType.InstantChatReward:
      productRecordType = FlowType.Reward;
      break;
    default:
      productRecordType = FlowType.Unknown;
  }

  return FlowType[productRecordType];
}
