import { Box, Divider, Flex } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { currentSessionState } from "states";
import { ChatView } from "./ChatView";
import { SessionsView } from "./SessionsView";

export function ChatsView({ uid }: { uid: string }) {
  const currentSession = useRecoilValue(currentSessionState);

  return (
    <Flex h="82vh">
      <SessionsView uid={uid} />

      <Box px={4}>
        <Divider orientation="vertical" />
      </Box>

      <Box
        flex="1"
        overflowY="auto">
        <ChatView
          from={uid}
          session={currentSession}
        />
      </Box>
    </Flex>
  );
}
