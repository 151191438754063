import { Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { phonesQuery } from "states/phones";
import { CustomTable, EmptyView } from "views/components";
import { PhoneModelsTableCell } from "./models-table-cell";

// PhoneModelsTable
export function PhoneModelsTable() {
  const phones = useRecoilValue(phonesQuery);

  return (
    <>
      {phones.length === 0 && <EmptyView />}
      {phones.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Platform</Th>
              <Th>Model</Th>
              <Th>Marketing name</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>

          <Tbody>
            {phones.map((phoneModel) => (
              <PhoneModelsTableCell
                key={phoneModel.id}
                phoneModel={phoneModel}
              />
            ))}
          </Tbody>
        </CustomTable>
      )}
    </>
  );
}
