import { fetchGenderTags } from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const gendersRequestIdState = atom({
  key: "genders/requestId",
  default: dayjs().valueOf(),
});

export const gendersPageState = atom({
  key: "genders/page",
  default: 1,
});

export const gendersRespQuery = selector({
  key: "genders/resp",
  get: async ({ get }) => {
    get(gendersRequestIdState);

    try {
      const resp = await fetchGenderTags({
        page: get(gendersPageState),
        pageSize: get(searchPageSizeState),
      });

      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const gendersQuery = selector({
  key: "genders",
  get: ({ get }) => get(gendersRespQuery)?.data ?? [],
});

export const gendersCountQuery = selector({
  key: "genders/count",
  get: ({ get }) => get(gendersRespQuery)?.page?.total ?? 0,
});
