import {
  fetchPendingReportedChannels,
  fetchPendingReportedProfiles,
} from "apis";
import dayjs from "dayjs";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  channelTabState,
  channelTypes,
  genders,
  genderState,
} from "views/components";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const reportedProfilesRequestId = atom({
  key: "reported/profiles/requestedId",
  default: dayjs().valueOf(),
});

const reportedProfilesRespQuery = selector({
  key: "reported/profiles/resp",
  get: async ({ get }) => {
    get(reportedProfilesRequestId);

    try {
      const resp = await fetchPendingReportedProfiles({
        page: 1,
        pageSize: get(searchPageSizeState),
        gender: genders[get(genderState)],
      });

      // 设置photos
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedProfilesQuery = selector({
  key: "reported/profiles",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.data ?? [];
  },
});

export const reportedProfilesCountQuery = selector({
  key: "reported/profiles/count",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.page?.total ?? 0;
  },
});

export const reportedChannelsRequestId = atom({
  key: "reported/channels/requestedId",
  default: dayjs().valueOf(),
});

const reportedChannelsRespQuery = selector({
  key: "reported/channels/resp",
  get: async ({ get }) => {
    get(reportedChannelsRequestId);

    try {
      const resp = await fetchPendingReportedChannels({
        page: 1,
        pageSize: get(searchPageSizeState),
        type: channelTypes[get(channelTabState)],
      });

      // 设置photos
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedChannelsQuery = selector({
  key: "reported/channels",
  get: ({ get }) => {
    return get(reportedChannelsRespQuery)?.data ?? [];
  },
});

export const reportedChannelsCountQuery = selector({
  key: "reported/channels/count",
  get: ({ get }) => {
    return get(reportedChannelsRespQuery)?.page?.total ?? 0;
  },
});
