import { fetchAboutMe } from "apis";
import dayjs from "dayjs";
import { Status } from "enums";
import { SearchAboutParams } from "interfaces";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  aboutMeStatusState,
  genders,
  genderState,
  searchTimestampsQuery,
} from "views/components";
import { errorState } from "./common";
import { searchPageSizeState } from "./search";

export const aboutMesPageState = atom({
  key: "aboutMes/page",
  default: 1,
});

export const aboutMesRequestIdState = atom({
  key: "aboutMes/requestId",
  default: dayjs().valueOf(),
});

export const aboutMesRespQuery = selector({
  key: "aboutMes/resp",
  get: async ({ get }) => {
    get(aboutMesRequestIdState);

    const page = get(aboutMesPageState);
    const pageSize = get(searchPageSizeState);

    const params: SearchAboutParams = {
      page,
      pageSize,
      status: [get(aboutMeStatusState)],
      gender: genders[get(genderState)],
    };

    if (Status.Pending !== get(aboutMeStatusState)) {
      const timestamps = get(searchTimestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchAboutMe(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const aboutMesQuery = selector({
  key: "aboutMes",
  get: ({ get }) => {
    return get(aboutMesRespQuery)?.data ?? [];
  },
});

export const aboutMesCountQuery = selector({
  key: "aboutMes/count",
  get: ({ get }) => {
    return get(aboutMesRespQuery)?.page?.total ?? 0;
  },
});
