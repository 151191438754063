import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import {
  APIResponse,
  Flow,
  FlowsParams,
  InAppProduct,
  Order,
  OrdersParams,
  Subscription,
  SubscriptionNotification,
  SubscriptionNotificationsParams,
  SubscriptionsParams,
  TransferRecord,
  TransferRecordsParams,
  VirtualProductListParams,
} from "interfaces";

// 获取所有订阅
export const fetchSubscriptions = (params: SubscriptionsParams) => {
  return axiosInstance.post<APIResponse<Subscription[]>>(
    ApiURL.SubscriptionsSearch,
    params
  );
};

// 获取订阅的通知事件
export const fetchSubscriptionNotifications = (
  params: SubscriptionNotificationsParams
) => {
  return axiosInstance.post<APIResponse<SubscriptionNotification[]>>(
    ApiURL.SubscriptionNotificationsSearch,
    params
  );
};

// 获取订单
export const fetchOrders = (params: OrdersParams) => {
  return axiosInstance.post<APIResponse<Order[]>>(ApiURL.OrdersSearch, params);
};

// 获取订阅迁移记录
export const fetchSubscriptionTransferRecords = (
  params: TransferRecordsParams
) => {
  return axiosInstance.post<APIResponse<TransferRecord[]>>(
    ApiURL.SubscriptionTransferRecordsSearch,
    params
  );
};

// 获取用户Boosts
export const fetchUserBoosts = (params: VirtualProductListParams) => {
  return axiosInstance.post<VirtualProductResp>(ApiURL.BoostsSearch, params);
};

// 获取用户Boost流水
export const fetchFlows = (params: FlowsParams) => {
  return axiosInstance.post<APIResponse<Flow[]>>(ApiURL.FlowsSearch, params);
};

// 获取用户的Boosts
export const fetchUserGifts = (params: VirtualProductListParams) => {
  return axiosInstance.post<VirtualProductResp>(ApiURL.GiftsSearch, params);
};

export const fetchUserInstantChats = (params: VirtualProductListParams) => {
  return axiosInstance.post<VirtualProductResp>(
    ApiURL.InstantChantsSearch,
    params
  );
};

type VirtualProductResp = APIResponse<InAppProduct[]>;
