import { TableCellProps, Td } from "@chakra-ui/react";
import { SELECTED_COLOR_BG } from "constant";

type CustomTdProps = {
  isSelected: boolean;
};

export function CustomTd(props: CustomTdProps & TableCellProps) {
  const { isSelected, children, ...tdProps } = props;
  return (
    <Td
      bg={isSelected ? SELECTED_COLOR_BG : undefined}
      color={isSelected ? "red" : undefined}
      {...tdProps}>
      {children}
    </Td>
  );
}
