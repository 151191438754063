import {
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { searchPageState, totalPagesQuery } from "states";
import { PaginationNextButton } from "./PaginationNextButton";
import { PaginationPrevButton } from "./PaginationPrevButton";

export interface PaginationContentProps {
  totalState: RecoilValueReadOnly<number>;
  state?: RecoilState<number>;
}

export function PaginationContent(props: PaginationContentProps) {
  // total pages
  const totalPages = useRecoilValue(totalPagesQuery(props.totalState));
  const state = props.state ?? searchPageState;

  // url page state
  const [page, setPage] = useRecoilState(state);

  // 设置Page
  const handleSubmitPage = (value: string) => {
    let _page: number = parseInt(value);

    if (_page < 1 || !_page) {
      // 输入的page 小于 1，修正为1
      // setLocalPage(1)
      setPage(1);
    } else if (_page > totalPages) {
      // 输入的page 超过最大页码，修正为最大页
      setPage(totalPages);
    } else {
      setPage(_page);
    }
  };

  return (
    <>
      {totalPages > 0 && (
        <HStack>
          <Spacer />
          {/* <PaginationTotalView totalState={props.totalState} /> */}
          <PaginationPrevButton {...props} />
          <Editable
            fontWeight="semibold"
            value={page.toString()}
            onChange={handleSubmitPage}>
            <EditablePreview />
            <EditableInput w="40px" />
          </Editable>
          <Text>/ {totalPages}</Text>
          <PaginationNextButton {...props} />
        </HStack>
      )}
    </>
  );
}
