import {
  Avatar,
  Heading,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ProfileStatus, StatusType } from "enums";
import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { editProfileQuery, profileQuery } from "states";
import { generateProfileSubInfo } from "utils";
import {
  BanOrRestoreProfileButton,
  ChatsView,
  CoupleStatusEditButton,
  DiscoverView,
  EmptyView,
  GenderEditorButton,
  HeaderBox,
  ProfileMenu,
  ProfileMoreInfoView,
  ProfileView,
  RelationshipCountView,
  RiskStatusTag,
  StatusTag,
  SubmitProfileButton,
  UnderageButton,
} from "views/components";

export function ProfilePage() {
  const params = useParams();

  const uid = params["uid"];

  if (!uid) return <EmptyView />;

  return (
    <Suspense fallback={<Spinner />}>
      <ProfileDetailView uid={uid} />
    </Suspense>
  );
}

export function ProfileDetailView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));
  const editedProfile = useRecoilValue(editProfileQuery(uid));

  if (!profile) {
    return <Spinner />;
  }

  const updatedProfile = editedProfile ?? profile;

  return (
    <Stack>
      <HeaderBox>
        {/* User Info */}
        <Avatar
          size="lg"
          src={profile.avatar?.url}
          name={profile.name ?? profile.id}
        />

        <Stack>
          <HStack>
            <Heading>{profile.name !== "" ? profile.name : profile.id}</Heading>
            <StatusTag
              status={updatedProfile.status}
              statusType={StatusType.Profile}
            />
            <RiskStatusTag riskStatus={updatedProfile.riskStatus} />
          </HStack>

          {/* Info */}

          <HStack>
            <Text opacity={0.5}>{generateProfileSubInfo(updatedProfile)}</Text>

            {profile.status !== ProfileStatus.Deleted && (
              <>
                <UnderageButton
                  profile={profile}
                  device={profile.currInfo?.device}
                />
                <CoupleStatusEditButton profile={profile} />
                <GenderEditorButton
                  profile={profile}
                  fullText
                />
              </>
            )}
          </HStack>
        </Stack>

        <Spacer />
        {profile.status !== ProfileStatus.Deleted && (
          <>
            <SubmitProfileButton uid={profile.id} />
            <BanOrRestoreProfileButton
              profile={profile}
              device={profile.currInfo?.device}
            />

            <ProfileMenu uid={profile.id} />
          </>
        )}
      </HeaderBox>

      <Tabs isLazy>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>More Info</Tab>
          <Tab>Relationships</Tab>
          <Tab>Chats</Tab>
          <Tab>Discover</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Suspense fallback={<Spinner />}>
              <ProfileView uid={profile.id} />
            </Suspense>

            {/* IAP */}
            {/* Reports */}
          </TabPanel>

          <TabPanel px={0}>
            <Suspense fallback={<Spinner />}>
              <ProfileMoreInfoView uid={profile.id} />
            </Suspense>
          </TabPanel>

          <TabPanel px={0}>
            <Suspense fallback={<Spinner />}>
              <RelationshipCountView uid={profile.id} />
            </Suspense>
          </TabPanel>

          <TabPanel px={0}>
            <Suspense fallback={<Spinner />}>
              <ChatsView uid={profile.id} />
            </Suspense>
          </TabPanel>

          <TabPanel px={0}>
            <Suspense fallback={<Spinner />}>
              <DiscoverView uid={profile.id} />
            </Suspense>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
