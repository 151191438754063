export enum Platform {
  iOS = 1,
  Android = 2,
  Web = 3,
  Console = 5,
}

export enum PurchaseSource {
  // 苹果商店
  iOS = 1,

  // 谷歌商店
  Android = 2,

  // 苹果商店兑换码
  "Redeem (iOS)" = 3,

  // 谷歌商店兑换码
  "Promo (Android)" = 4,

  // 管理后台
  Console = 5,
}
