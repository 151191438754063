import {
  BlockParams,
  fetchRelationshipBlock,
  fetchRelationshipGift,
  fetchRelationshipInstantChat,
  fetchRelationshipLike,
  fetchRelationshipMatch,
  fetchRelationshipPassed,
  GiftParams,
  InstantChatParams,
  LikeParams,
  MatchParams,
  PassedParams,
} from "apis";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  RelationshipDirection,
  relationshipDirectionState,
} from "views/components";
import { errorState } from "./common";
import { searchPageSizeState, searchPageState, searchUIDState } from "./search";

export const passedRelationshipsRespQuery = selector({
  key: "relationships/passed/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: PassedParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipPassed(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const passedRelationshipsQuery = selector({
  key: "relationships/passed",
  get: ({ get }) => {
    return get(passedRelationshipsRespQuery)?.data ?? [];
  },
});

export const passedRelationshipsCountQuery = selector({
  key: "relationships/passed/count",
  get: ({ get }) => {
    return get(passedRelationshipsRespQuery)?.page.total ?? 0;
  },
});

export const likeRelationshipsRespQuery = selector({
  key: "relationships/like/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: LikeParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipLike(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const likeRelationshipsQuery = selector({
  key: "relationships/like",
  get: ({ get }) => {
    return get(likeRelationshipsRespQuery)?.data ?? [];
  },
});

export const likeRelationshipsCountQuery = selector({
  key: "relationships/like/count",
  get: ({ get }) => {
    return get(likeRelationshipsRespQuery)?.page.total ?? 0;
  },
});

export const matchRelationshipsRespQuery = selector({
  key: "relationships/match/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: MatchParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipMatch(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const matchRelationshipsQuery = selector({
  key: "relationships/match",
  get: ({ get }) => {
    return get(matchRelationshipsRespQuery)?.data ?? [];
  },
});

export const matchRelationshipsCountQuery = selector({
  key: "relationships/match/count",
  get: ({ get }) => {
    return get(matchRelationshipsRespQuery)?.page.total ?? 0;
  },
});

export const giftRelationshipsRespQuery = selector({
  key: "relationships/gift/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: GiftParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipGift(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const giftRelationshipsQuery = selector({
  key: "relationships/gift",
  get: ({ get }) => {
    return get(giftRelationshipsRespQuery)?.data ?? [];
  },
});

export const giftRelationshipsCountQuery = selector({
  key: "relationships/gift/count",
  get: ({ get }) => {
    return get(giftRelationshipsRespQuery)?.page.total ?? 0;
  },
});

export const instantChatRelationshipsRespQuery = selector({
  key: "relationships/instantChat/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: InstantChatParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipInstantChat(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const instantChatRelationshipsQuery = selector({
  key: "relationships/instantChat",
  get: ({ get }) => {
    return get(instantChatRelationshipsRespQuery)?.data ?? [];
  },
});

export const instantChatRelationshipsCountQuery = selector({
  key: "relationships/instantChat/count",
  get: ({ get }) => {
    return get(instantChatRelationshipsRespQuery)?.page.total ?? 0;
  },
});

export const blockRelationshipsRespQuery = selector({
  key: "relationships/block/resp",
  get: async ({ get }) => {
    const searchUID = get(searchUIDState);
    const direction = get(relationshipDirectionState);

    const params: BlockParams = {
      page: get(searchPageState),
      pageSize: get(searchPageSizeState),
    };

    if (direction === RelationshipDirection.From) {
      params.fromUid = searchUID ? searchUID : undefined;
    } else {
      params.toUid = searchUID ? searchUID : undefined;
    }

    try {
      const res = await fetchRelationshipBlock(params);

      return res.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const blockRelationshipsQuery = selector({
  key: "relationships/block",
  get: ({ get }) => {
    return get(blockRelationshipsRespQuery)?.data ?? [];
  },
});

export const blockRelationshipsCountQuery = selector({
  key: "relationships/block/count",
  get: ({ get }) => {
    return get(blockRelationshipsRespQuery)?.page.total ?? 0;
  },
});
