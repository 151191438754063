/* API */

import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, BasicProfile, ListParams, Timestamps } from "interfaces";

export const fetchRelationshipPassed = (params: PassedParams) => {
  return axiosInstance.post<PassedRelationshipsResponse>(
    ApiURL.RelationshipPassedSearch,
    params
  );
};

/* Statues */
export enum PassedStatus {
  Passed = 0,
  Rewind = 1,
}

/* Params */

export interface PassedParams extends ListParams {
  fromUid?: string;
  toUid?: string;
  status?: PassedStatus;
}

/* Response */
export interface PassedRelationship extends Timestamps {
  id: string;
  fromUser: BasicProfile;
  toUser: BasicProfile;
  status: PassedStatus;
}

type PassedRelationshipsResponse = APIResponse<PassedRelationship[]>;
