import { Link, LinkProps, Text } from "@chakra-ui/react";
import { BsFillPersonFill } from "react-icons/bs";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterURI } from "router/router.uri";

interface UserIdProps {
  uid: string | string | undefined;
}

export function UserId(props: UserIdProps & LinkProps) {
  // 仅给Link传递LinkProps
  const { uid, ...restProps } = props;

  if (!uid) {
    return <></>;
  }

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.Profiles + "/" + uid}
      onClick={(e) => e.stopPropagation()}
      {...restProps}
    >
      <Text fontSize={props.size}>:{uid}</Text>
    </Link>
  );
}

export function UserIdIcon(props: UserIdProps & LinkProps) {
  const { uid: userId, ...linkProps } = props;

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.Profiles + "/" + userId}
      onClick={(e) => e.stopPropagation()}
      {...linkProps}
    >
      <BsFillPersonFill />
    </Link>
  );
}

export function UserIdContainer(props: UserIdProps & LinkProps) {
  const { uid: userId, children, ...linkProps } = props;

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.Profiles + "/" + userId}
      onClick={(e) => e.stopPropagation()}
      {...linkProps}
    >
      {children}
    </Link>
  );
}
