import { HStack, StackProps, useColorModeValue } from "@chakra-ui/react";

export function HeaderBox(props: StackProps) {
  const headerBg = useColorModeValue("white", "gray.800");

  return (
    <HStack
      position="sticky"
      top="55px"
      zIndex={4}
      w="100%"
      py={2}
      bg={headerBg}>
      {props.children}
    </HStack>
  );
}
