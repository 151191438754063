/* 转换时间戳，计算年龄，获取指定日期的时间戳 */

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DateOption, DayjsKey } from "enums";
import { Timestamp } from "interfaces";

dayjs.extend(customParseFormat);

// timestampMsToDateStr 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const timestampMsToDateStr = (timestampMs: number): string => {
  return dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};

// 生日转毫秒时间戳
// 2002-02-2021 => 1635930000
export const birthdayToTimestampMs = (birthday: string): number => {
  return dayjs(birthday, DayjsKey.BirthdayValueFormat).valueOf();
};

// 时间戳转生日
// 1635930000 => Feb 2, 2021
export const timestampMsToBirthdayStr = (timestampMs: number): string => {
  return dayjs(timestampMs).format(DayjsKey.BirthdayDisplayFormat);
};

// 时间戳转年龄
export const timestampMsToAge = (timestampMs: number): number => {
  return dayjs().diff(dayjs(timestampMs), "year");
};

// 获取指定月起始时间戳
// month: 0-11
export const getFirstDayTimestampMsOfTheMonth = (month: number): number => {
  return dayjs().month(month).day(1).valueOf();
};

// 获取指定月最后时间戳
// month: 0-11
export const getFirstDayTimestampMsOfNextMonth = (month: number): number => {
  return getFirstDayTimestampMsOfTheMonth(month + 1);
};

// 获取当前月起始时间戳
export const getFirstDayTimestampMsOfCurrentMonth = (): number => {
  return dayjs().day(1).valueOf();
};

export const getTheStartAndEndTimestamps = (
  datePickerValue: string | number
) => {
  const date = dayjs(String(datePickerValue), DayjsKey.DatePickerValueFormat);

  // 不要指定day(1), 会有bug
  return {
    createdStart: date.valueOf() as Timestamp,
    createdEnd: date.month(date.month() + 1).valueOf() as Timestamp,
  };
};

export const generateDateOptions = (
  startDateStr: string,
  endDateStr: string,
  format: string
): DateOption[] => {
  // 解析起止时间字符串
  const startDate = dayjs(startDateStr, format);
  const endDate = dayjs(endDateStr, format);

  // 返回值容器
  let dateOptions: DateOption[] = [];

  // 循环生成DateOption
  let tmpDate = endDate.clone();
  while (startDate.isBefore(tmpDate)) {
    // 格式化输出数据
    const dateOption: DateOption = {
      title: tmpDate.format(format),
      value: +tmpDate.format(DayjsKey.DatePickerValueFormat),
    };
    // 结果放入dateOptions
    dateOptions.push(dateOption);

    // 减一月，下一轮
    tmpDate = tmpDate.subtract(1, "month");
  }

  return dateOptions;
};
