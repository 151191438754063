import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { MainNav, Sidebar } from "views/components";

// MainLayout 主布局
export const MainLayout = () => {
  return (
    <Flex height="100vh">
      {/* Left: Sidebar container */}
      <Sidebar />

      {/* Right: MainNav + MainContent */}
      <Box
        flex="1"
        overflowY="auto">
        {/* Top: MainNav */}
        <MainNav />

        {/* Bottom: MainContent */}
        <Box p={4}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
};
