import { APIResponse, GenderTag, GenderTagListParam } from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

// TODO: 优化这里
export const fetchGenderTags = (params: GenderTagListParam) => {
  return axiosInstance.post<APIResponse<GenderTag[]>>(
    ApiURL.GenderTagSearch,
    params
  );
};
