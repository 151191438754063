import {
  Avatar,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MemberRole, MemberStatus } from "enums";
import { Member } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { membersCountQuery, membersPageState, membersQuery } from "states";
import { timestampMsToDateStr } from "utils";
import {
  CustomTable,
  CustomTr,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";
import { CreateMemberButton } from "./create-member-button";
import { MemberIdLink } from "./member-id";

export function MembersPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={membersCountQuery} />
        <Spacer />

        {/* New Member */}
        <CreateMemberButton />
      </HeaderBox>

      <Pagination
        state={membersPageState}
        totalState={membersCountQuery}
      />

      <Suspense fallback={<Spinner />}>
        <MembersTable />
      </Suspense>

      {/* Pagination */}
      <Pagination
        state={membersPageState}
        totalState={membersCountQuery}
      />
    </Stack>
  );
}

function MembersTable() {
  const members = useRecoilValue(membersQuery);

  return (
    <CustomTable>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>User</Th>
          <Th>Firebase UID</Th>
          <Th>Email</Th>
          <Th>Role</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {members.map((item) => (
          <MembersTableItem
            key={item.id}
            member={item}
          />
        ))}
      </Tbody>
    </CustomTable>
  );
}

interface MembersTableItemProps {
  member: Member;
}

function MembersTableItem(props: MembersTableItemProps) {
  return (
    <CustomTr>
      <Td>
        <MemberIdLink member={props.member} />
      </Td>

      <Td>
        <HStack>
          <Avatar
            size="sm"
            name={props.member.name}
            src={props.member.avatar}
          />
          <Text>{props.member.name}</Text>
        </HStack>
      </Td>
      <Td>{props.member.account}</Td>
      <Td>{props.member.email}</Td>

      <Td>{MemberRole[props.member.role]}</Td>
      <Td>{MemberStatus[props.member.status!]}</Td>
      <Td>{timestampMsToDateStr(props.member.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.member.updatedAt)}</Td>
    </CustomTr>
  );
}
