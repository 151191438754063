import { Button, Center, Heading, Stack, useToast } from "@chakra-ui/react";
import { CacheKey } from "enums";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "states/auth";
import { getErrorMessage, getFromStateFrom } from "utils";
import { LogoImage } from "views/components";

export function LoginPage() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useRecoilValue(authAtom);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      // 已登陆，跳转from或首页
      navigate(getFromStateFrom(location), { replace: true });
    }
  });

  const handleSignInWithGoogle = async () => {
    // 开始登录，转菊花
    setLoading(true);

    let provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(getAuth(), provider);
      // 登录成功，都默认激活一次账号
      window.localStorage.setItem(CacheKey.NeedAuth, "1");
    } catch (error) {
      toast({
        title: "Auth user failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Center>
      <Stack
        my={8}
        alignItems="center"
        spacing={8}>
        <LogoImage />
        <Stack>
          <Heading>{process.env.REACT_APP_NAME} Console</Heading>
        </Stack>
        <Stack my={8}>
          <Button
            leftIcon={<FcGoogle />}
            // colorScheme="white"
            // variant="outline"
            onClick={handleSignInWithGoogle}
            isLoading={loading}>
            Sign in with Google
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
}
