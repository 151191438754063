import {
  Avatar,
  AvatarGroup,
  Flex,
  HStack,
  List,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DEFAULT_COLOR_BG_DARK, DEFAULT_COLOR_BG_LIGHT } from "constant";
import {
  Message,
  MessageType,
  Session,
  TextMessage,
  VideoMessage,
  VoiceMessage,
} from "interfaces";
import { Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentSessionState, sessionsQuery } from "states";
import { timestampMsToDateStr } from "utils";

export function SessionsView({ uid }: { uid: string }) {
  return (
    <Flex
      flexDirection="column"
      overflow="auto">
      <Suspense fallback={<Spinner />}>
        <SessionsList uid={uid} />
      </Suspense>
    </Flex>
  );
}

function SessionsList({ uid }: { uid: string }) {
  const sessions = useRecoilValue(sessionsQuery(uid));

  return (
    <List spacing="1px">
      {sessions.map((session) => (
        <SessionView
          key={session.id}
          session={session}
        />
      ))}
    </List>
  );
}

function SessionView({ session }: { session: Session }) {
  const bg = useColorModeValue(DEFAULT_COLOR_BG_LIGHT, DEFAULT_COLOR_BG_DARK);

  const [currentSession, setCurrentSession] =
    useRecoilState(currentSessionState);

  return (
    <HStack
      bg={session.id === currentSession?.id ? bg : undefined}
      borderRadius="24px"
      onClick={() => setCurrentSession(session)}
      cursor="pointer"
      _hover={{ bg: bg }}
      p={4}>
      <AvatarGroup
        size="md"
        max={1}>
        {session.users.map((user) => (
          <Avatar
            key={user.id}
            name={user.name}
            src={user.avatar?.url}
          />
        ))}
      </AvatarGroup>

      <Stack
        spacing={0}
        w="256px">
        <HStack>
          <Text
            fontSize="14px"
            fontWeight="semibold">
            {session.users.map((user) => user.name).join(" & ")}
          </Text>

          <Spacer />
          <Text
            fontSize="12px"
            opacity={0.5}>
            {timestampMsToDateStr(session.updateTime)}
          </Text>
        </HStack>

        <LastMessageView message={session.lastMsg} />
      </Stack>
    </HStack>
  );
}

function LastMessageView({ message }: { message: Message }) {
  let msg = "";

  switch (message.type) {
    case MessageType.Text:
      msg = (message.body as TextMessage).msg;
      break;
    case MessageType.Voice:
      msg =
        `[${MessageType[message.type]}] ` +
        (message.body as VoiceMessage).dur / 1000 +
        "s";
      break;
    case MessageType.Photo:
      msg = `[${MessageType[message.type]}] `;
      break;
    case MessageType.Video:
      msg =
        `[${MessageType[message.type]}] ` +
        (message.body as VideoMessage).dur / 1000 +
        "s";
      break;
    default:
      msg = `[${MessageType[message.type]}] `;
  }

  return (
    <Text
      opacity={0.5}
      flex={1}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis">
      {msg}
    </Text>
  );
}
