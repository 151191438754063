import { DeviceStatus } from "enums";
import { APIResponse, Device, DevicesListParams } from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

export const fetchDevices = (params: DevicesListParams) => {
  return axiosInstance.post<APIResponse<Device[]>>(
    ApiURL.DevicesSearch,
    params
  );
};

export const updateDeviceStatus = (id: string, status: DeviceStatus) => {
  return axiosInstance.post(ApiURL.DevicesEdit, {
    id,
  });
};
