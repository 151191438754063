import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { InAppProduct } from "interfaces";
import { Suspense } from "react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView,
} from "views/components";

export function InAppProductsView(props: InAppProductsTableProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <InAppProductsTable {...props} />
    </Suspense>
  );
}

type InAppProductsTableProps = {
  state: RecoilValueReadOnly<InAppProduct[]>;
};

function InAppProductsTable(props: InAppProductsTableProps) {
  const products = useRecoilValue(props.state);

  return (
    <>
      {products.length === 0 && <EmptyView />}
      {products.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>User</Th>
              <Th>Balance</Th>
              <Th>Orders</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <ProductsTableBody products={products} />
        </CustomTable>
      )}
    </>
  );
}

interface ProductsTableBodyProps {
  products: InAppProduct[];
}

function ProductsTableBody(props: ProductsTableBodyProps) {
  return (
    <Tbody>
      {props.products.map((boost, index) => (
        <ProductsTableCell
          product={boost}
          key={index}
        />
      ))}
    </Tbody>
  );
}

interface ProductsTableCellProps {
  product: InAppProduct;
}

function ProductsTableCell(props: ProductsTableCellProps) {
  const { product } = props;

  return (
    <CustomTr>
      <Td>{product.id}</Td>
      <Td>
        <BasicUserInfoView userInfo={product.user} />
      </Td>
      <Td>{product.quantity}</Td>
      <Td>{product.orderQuantity}</Td>
      <Td>{timestampMsToDateStr(product.createdAt)}</Td>
      <Td>{timestampMsToDateStr(product.updatedAt)}</Td>
    </CustomTr>
  );
}

export { ProductsTableBody };
