export enum CacheKey {
  NeedAuth = "needAuth",
  RefreshIdToken = "refresh_id_token",
  EmailForSignIn = "emailForSignIn",
  LoginFrom = "loginFrom",
}

// 采用下划线编码方式
export enum SearchParamKey {
  Gender = "gender",
  ChannelType = "channel_type",
  Timestamp = "ms",
  Page = "page",
  PageSize = "page_size",
  UID = "uid",
  SubscriptionId = "subscription_id",
  OriginalTransactionId = "original_transaction_id",
  Email = "email",
  IMEI = "imei",
  Status = "status",
  AboutMeStatus = "about_me_status",
  "Couple Status" = "couple_status",
  "Reviewed Status" = "reviewed_status",
  Month = "month",
  MediaType = "media_type",
  PaymentType = "payment_type",
  IPVersion = "ip_version",
  Platform = "platform",
  tab = "tab",
}

export enum DayjsKey {
  DatePickerDisplayFormat = "MMMM YYYY",
  DatePickerValueFormat = "YYYYMM",
  BirthdayValueFormat = "YYYY-MM-DD",
  BirthdayDisplayFormat = "MMM DD, YYYY",
  BirthdayInputFormat = "MM/DD/YYYY",
}
