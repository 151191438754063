import { Link, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { PhoneModel } from 'interfaces';
import { PhoneModelModalContent } from './model-modal-content';

export function EditPhoneModelButton(props: EditPhoneModelButtonProps) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link onClick={onOpen}>
        {props.phoneModel.id}
      </Link>

      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <PhoneModelModalContent onClose={onClose} phoneModel={props.phoneModel} />
      </Modal>
    </>
  );
}
interface EditPhoneModelButtonProps {
  phoneModel: PhoneModel;
}
