import { Box, Highlight } from "@chakra-ui/react";
import { highlightWords } from "constant";

export function CustomHighlighter({ text }: { text: string }) {
  return (
    <Box
      fontSize="18px"
      whiteSpace="normal"
      wordBreak="break-all">
      <Highlight
        query={highlightWords}
        styles={{ bg: "red.100" }}>
        {text}
      </Highlight>
    </Box>
  );
}
