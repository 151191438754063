import { Box, HStack, Stack } from "@chakra-ui/react";
import { notificationsTotalQuery } from "states";
import {
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";
import { NotificationsView } from "./components/notifications-table";

export const configuration = {
  name: "Notifications",
  component: <NotificationsView />,
  totalState: notificationsTotalQuery,
  trainingItems: [DateFilter],
};

export function NotificationsPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={configuration.totalState} />
      </HeaderBox>

      <HStack>
        {configuration.trainingItems.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      {
        <Box overflowX="scroll" key={configuration.name}>
          {configuration.component}
        </Box>
      }

      <Pagination totalState={configuration.totalState} />
    </Stack>
  );
}
