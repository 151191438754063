import {
  AspectRatio,
  Box,
  Center,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CARD_WIDTH,
  HOVER_COLOR_BG_DARK,
  HOVER_COLOR_BG_LIGHT,
  SELECTED_COLOR_BG,
} from "constant";
import { Gender, RiskStatus, SexualOrientation, StatusType } from "enums";
import { Profile } from "interfaces";
import _ from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  editProfileQuery,
  isSelectedProfileIdQuery,
  rejectedUserIdsState,
} from "states";
import { timestampMsToAge } from "utils";
import {
  ProfileMediaView,
  RejectMark,
  StatusTag,
  UserIdLink,
  ZStack,
} from "views/components";
import { IpLink, RiskStatusTag, UnderageButton } from ".";
import { RelationshipsTagView } from "../other/RelationshipsTagView";
import { GenderEditorButton } from "./edit";
import { VerifyImageView } from "./VerifyImageView";

interface ProfilesCardProps {
  profile: Profile;
}

export function ProfilesCard(props: ProfilesCardProps) {
  const { profile } = props;

  return (
    <HStack alignItems="flex-start">
      {/* Basic Info */}
      <BasicInfoView profile={profile} />

      {/* verify img */}
      <VerifyImageView verifyImage={profile.verify} />

      {/* profile images */}
      <HStack alignItems="flex-end">
        {profile.photos &&
          profile.photos.map((media) => (
            <ProfileMediaView
              key={media.id}
              media={media}
            />
          ))}
      </HStack>
    </HStack>
  );
}

// Basic
function BasicInfoView(props: ProfilesCardProps) {
  const { profile } = props;

  const editedProfile = useRecoilValue(editProfileQuery(profile.id));

  const riskStatus = editedProfile?.riskStatus ?? profile.riskStatus;

  const borderColor = useColorModeValue("gray.50", "gray.600");
  const hoverColor = useColorModeValue(
    HOVER_COLOR_BG_LIGHT,
    HOVER_COLOR_BG_DARK
  );

  const selectProfile = useSetRecoilState(rejectedUserIdsState);
  const isSelectedProfile = useRecoilValue(
    isSelectedProfileIdQuery(profile.id)
  );

  const handleClickProfile = () => {
    // ban状态不允许点击
    if (profile.riskStatus === RiskStatus.Banned) return;

    selectProfile((curr) => {
      return _.xor(curr, [profile.id]);
    });
  };

  return (
    <Stack
      minW={CARD_WIDTH}
      _hover={{ bg: hoverColor }}
      borderWidth="1px"
      borderColor={isSelectedProfile ? SELECTED_COLOR_BG : borderColor}
      borderStyle="solid"
      spacing={0}>
      <AspectRatio
        ratio={10 / 16}
        cursor="pointer"
        pointerEvents="none">
        <ZStack p={2}>
          <Box
            w="100%"
            h="100%"
            onClick={handleClickProfile}
            pointerEvents="auto"
          />

          <Stack
            w="100%"
            h="100%">
            {/* TOP */}
            <HStack>
              <RelationshipsTagView tag={profile.relationshipTag} />
              <Spacer />
              {riskStatus !== RiskStatus.Underage && (
                <UnderageButton profile={profile} />
              )}
            </HStack>
            <Heading size="sm">{profile.name}</Heading>

            <Stack>
              <HStack>
                <Text>
                  {profile.birthday && timestampMsToAge(profile.birthday)}
                </Text>
                <Text>{profile.gender && Gender[profile.gender]}</Text>
                <Spacer />
                <GenderEditorButton profile={profile} />
              </HStack>

              <Text>
                {profile.sexualOrientation &&
                  SexualOrientation[profile.sexualOrientation]}
              </Text>

              <Text>
                {[
                  profile.currInfo?.geo?.city,
                  profile.currInfo?.geo?.country,
                ].join(", ")}
              </Text>
            </Stack>
            <Spacer />

            <IpLink ip={profile.registerInfo?.ip} />
            <HStack pointerEvents="auto">
              <RiskStatusTag riskStatus={riskStatus} />
              <Spacer />
            </HStack>
          </Stack>

          {isSelectedProfile && (
            <Center h="100%">
              <RejectMark w="128px" />
            </Center>
          )}
        </ZStack>
      </AspectRatio>

      <Spacer />
      <HStack
        p={2}
        borderTop="1px"
        borderColor={borderColor}>
        <UserIdLink uid={profile.id} />

        <Spacer />
        <StatusTag
          statusType={StatusType.Profile}
          status={profile.status}
        />
      </HStack>
    </Stack>
  );
}
