import { Box, HStack, List, Spacer, Stack, Text } from "@chakra-ui/react";
import { ReportStatus } from "enums";
import { ReportedRecord } from "interfaces";
import { timestampMsToDateStr } from "utils";
import { BasicUserInfoView } from "../profile";

export function ReportsList(props: ReportsListProps) {
  return (
    <List>
      {props.data.map((item, index) => (
        <HStack
          key={index}
          opacity={item.status === ReportStatus.Pending ? "1.0" : "0.5"}
        >
          <Stack>
            <Text>{item.content}</Text>
            <Text opacity={0.5} fontSize="12px">
              {timestampMsToDateStr(item.createdAt)}
            </Text>
          </Stack>

          <Spacer />
          <Box w="200px">
            <BasicUserInfoView userInfo={item.reportUser} />
          </Box>
        </HStack>
      ))}
    </List>
  );
}
interface ReportsListProps {
  data: ReportedRecord[];
}
