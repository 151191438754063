/* API */

import { axiosInstance } from "apis/common/axios";
import { ApiURL } from "apis/common/urls";
import { APIResponse, BasicProfile, ListParams, Timestamps } from "interfaces";

export const fetchRelationshipBlock = (params: BlockParams) => {
  return axiosInstance.post<BlockRelationshipsResponse>(
    ApiURL.RelationshipBlockSearch,
    params
  );
};

/* Statues */
export enum BlockStatus {
  Block = 0,
  Unblock = 1,
}

/* Params */

export interface BlockParams extends ListParams {
  fromUid?: string;
  toUid?: string;
  status?: BlockStatus;
}

/* Response */
export interface BlockRelationship extends Timestamps {
  id: string;
  fromUser: BasicProfile;
  toUser: BasicProfile;
  status: BlockStatus;
}

type BlockRelationshipsResponse = APIResponse<BlockRelationship[]>;
