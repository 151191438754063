import { Heading, HStack, Image, Stack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import logo from "../../../assets/images/Yolo_Logo.png";
import { isSidebarOpenQuery } from "../sidebar/sidebar-toggle";

export function Logo() {
  // const bgColor = useColorModeValue("blackAlpha.50", "#0A0A0A");
  const isSidebarOpen = useRecoilValue(isSidebarOpenQuery);
  return (
    <Stack
      position="sticky"
      top={0}
      left={0}
      right={0}
      // bg={bgColor}
      zIndex={2}
      justifyContent="center"
      p={4}>
      <HStack>
        <Image
          w="32px"
          src={logo}
          borderRadius={10}
        />
        {isSidebarOpen && (
          <Heading size="md">{process.env.REACT_APP_NAME ?? ""}</Heading>
        )}
      </HStack>
    </Stack>
  );
}

export function LogoImage() {
  return (
    <Image
      w="80px"
      src={logo}
      borderRadius={40}
    />
  );
}
