import {
  AspectRatio,
  Box,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CARD_WIDTH } from "constant";
import { Media } from "interfaces";
import { ProfileMediaView } from "views/components";

export function VerifyImageView({ verifyImage }: { verifyImage?: Media }) {
  const bg = useColorModeValue("gray.50", "gray.900");
  return (
    <>
      {verifyImage ? (
        <ProfileMediaView
          media={verifyImage}
          isVerify={true}
        />
      ) : (
        <Stack>
          <AspectRatio
            w={CARD_WIDTH}
            ratio={10 / 16}>
            <Box
              w="100%"
              h="100%"
              bg={bg}>
              {/* <Logo /> */}
              <Text opacity={0.5}>Verify Image</Text>
            </Box>
          </AspectRatio>
        </Stack>
      )}
    </>
  );
}
