import { AspectRatio, AspectRatioProps, Icon } from "@chakra-ui/react";
import { SELECTED_COLOR_BG } from "constant";
import { AiFillCloseCircle } from "react-icons/ai";

export function RejectMark(props: AspectRatioProps) {
  return (
    <AspectRatio
      ratio={1 / 1}
      {...props}>
      <Icon
        as={AiFillCloseCircle}
        color={SELECTED_COLOR_BG}
        w="100%"
        h="100%"
      />
    </AspectRatio>
  );
}
