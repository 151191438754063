import {
  Avatar,
  Box,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DEFAULT_COLOR_BG_DARK, DEFAULT_COLOR_BG_LIGHT } from "constant";
import {
  Message,
  MessageBody,
  MessageType,
  PhotoMessage,
  TextMessage,
  VideoMessage,
  VoiceMessage,
} from "interfaces";
import { PiSpeakerSimpleHighDuotone } from "react-icons/pi";
import { useAudio, useVideo } from "react-use";
import { timestampMsToDateStr } from "utils";

export function MessageView({
  from,
  message,
}: {
  from: string;
  message: Message;
}) {
  const bg = useColorModeValue(DEFAULT_COLOR_BG_LIGHT, DEFAULT_COLOR_BG_DARK);

  const isSent = from === message.fromUser.id;

  return (
    <HStack alignItems="flex-start">
      {!isSent && (
        <Avatar
          size="sm"
          name={message.fromUser.name}
          src={message.fromUser.avatar?.url}
        />
      )}

      {isSent && <Spacer />}
      <Stack spacing={0}>
        <Box
          maxW="256px"
          bg={isSent ? "green.400" : bg}
          borderTopLeftRadius={isSent ? 24 : 0}
          borderTopRightRadius={24}
          borderBottomLeftRadius={24}
          borderBottomRightRadius={isSent ? 0 : 24}
          overflow="clip">
          <MessageCell message={message} />
        </Box>

        <HStack>
          {isSent && <Spacer />}
          <Text
            fontSize="12px"
            opacity={0.5}>
            {timestampMsToDateStr(message.time)}
          </Text>
          {!isSent && <Spacer />}
        </HStack>
      </Stack>
      {!isSent && <Spacer />}
    </HStack>
  );
}

function MessageCell({ message }: { message: Message }) {
  switch (message.type) {
    case MessageType.Text:
      return <TextMessageView body={message.body} />;
    case MessageType.Photo:
      return <PhotoMessageView body={message.body} />;
    case MessageType.Voice:
      return <VoiceMessageView body={message.body} />;
    case MessageType.Video:
      return <VideoMessageView body={message.body} />;
    default:
      return (
        <Text
          px={4}
          py={2}>
          [{MessageType[message.type]}]
        </Text>
      );
  }
}

type MessageViewProps = {
  body: MessageBody;
};

function TextMessageView({ body }: MessageViewProps) {
  const _body = body as TextMessage;

  return (
    <Text
      px={4}
      py={2}>
      {_body.msg}
    </Text>
  );
}

function PhotoMessageView({ body }: MessageViewProps) {
  const _body = body as PhotoMessage;

  return (
    <Image
      src={_body.url}
      alt={_body.md5}
    />
  );
}

function VoiceMessageView({ body }: MessageViewProps) {
  const _body = body as VoiceMessage;

  const [audio, state, controls] = useAudio({
    src: _body.url,
  });

  const handleClick = () => {
    state.playing ? controls.pause() : controls.play();
  };

  return (
    <HStack
      px={4}
      py={2}
      cursor="pointer"
      onClick={handleClick}>
      <PiSpeakerSimpleHighDuotone />
      {audio}
      <Text>
        {state.playing
          ? (state.duration - state.time).toFixed(0)
          : _body.dur / 1000}
        s
      </Text>
    </HStack>
  );
}

function VideoMessageView({ body }: MessageViewProps) {
  const _body = body as VideoMessage;

  const [video] = useVideo(
    <video
      src={_body.url}
      controls
    />
  );

  return <Box>{video}</Box>;
}
