import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { MediaType } from "enums";
import { useRecoilState } from "recoil";
import { modalMediaViewState } from "states";
import { ImageView } from "../profile/media/image-view";
import { VideoPlayer } from "../profile/media/video-player";

export function FullScreenMediaView() {
  const [media, setMedia] = useRecoilState(modalMediaViewState);

  return (
    <Modal
      isCentered
      isOpen={media ? true : false}
      onClose={() => setMedia(undefined)}
    >
      <ModalOverlay />
      <ModalContent onClick={() => setMedia(undefined)}>
        {media && (
          <ModalBody p={0}>
            {media.type === MediaType.Image ? (
              <ImageView src={media.url} />
            ) : (
              <VideoPlayer url={media.url} />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
