// 从给定的url中获取path的最后一部分
// url: https://google.com/abc?def=ghi
// return: abc?def=ghi
export const getLastComponentsFromUrl = (url: string): string => {
  return url.substring(url.lastIndexOf("/") + 1);
};

// 从href中获取最后的url分段
// input: /abc/def?ghi=jkm
// output: def
export function getLastSegmentFromHref(href: string): string {
  const pathname = new URL(href).pathname;
  return getLastSegmentFromPathname(pathname);
}

// 从头pathname中获取最后的url组件分段
// input: /abc/def?ghi=jkm
// output: def
export function getLastSegmentFromPathname(pathname: string): string {
  const segments = pathname.split("/");
  const last = segments.pop() || segments.pop(); // Handle potential trailing slash
  return last ?? "";
}

export const getTitleFromPathname = (pathname: string) => {
  // 获取lastSegment，下划线使用空格代替
  const lastSegment = getLastSegmentFromPathname(pathname).replace(/_/g, " ");
  // 首字母大写
  return lastSegment
    .toLowerCase()
    .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};
