import { fetchSeeking } from "apis";
import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorState } from "../common";

export const seekingRequestIdState = atom({
  key: "seeking/requestId",
  default: dayjs().valueOf(),
});

export const seekingQuery = selectorFamily({
  key: "seeking",
  get:
    (id: string) =>
    async ({ get }) => {
      get(seekingRequestIdState);

      try {
        const res = await fetchSeeking(id);

        return res.data.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});
