import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Platform } from "enums";
import { Order } from "interfaces";
import { Suspense } from "react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView,
} from "views/components";

type OrdersTableProps = {
  state: RecoilValueReadOnly<Order[]>;
};

export function OrdersView({ state }: OrdersTableProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <ReceiptsTable state={state} />
    </Suspense>
  );
}

export function ReceiptsTable({ state }: OrdersTableProps) {
  const receipts = useRecoilValue(state);

  return (
    <>
      {receipts.length === 0 && <EmptyView />}
      {receipts.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Platform</Th>
              <Th>Original Transaction Id</Th>
              <Th>Transaction ID</Th>
              <Th>Product</Th>
              <Th>Purchase Date</Th>
              <Th>Expires Date</Th>
              <Th>Note</Th>
              <Th>Buyer</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <ReceiptsTableBody receipts={receipts} />
        </CustomTable>
      )}
    </>
  );
}

interface ReceiptsTableBodyProps {
  receipts: Order[];
}

function ReceiptsTableBody(props: ReceiptsTableBodyProps) {
  return (
    <Tbody>
      {props.receipts.map((receipt, index) => (
        <ReceiptsTableCell receipt={receipt} key={index} />
      ))}
    </Tbody>
  );
}

interface ReceiptsTableCellProps {
  receipt: Order;
}

function ReceiptsTableCell(props: ReceiptsTableCellProps) {
  const { receipt } = props;
  return (
    <CustomTr>
      <Td>{receipt.id}</Td>
      <Td>{Platform[receipt.platform]}</Td>
      <Td>{receipt.originalTransactionId ?? "--"}</Td>
      <Td>{receipt.transactionId}</Td>
      <Td>{receipt.productId}</Td>
      <Td>{timestampMsToDateStr(receipt.purchaseDate)}</Td>
      <Td>
        {receipt.expiresDate ? timestampMsToDateStr(receipt.expiresDate) : "--"}
      </Td>
      <Td>{receipt.note ?? "--"}</Td>
      <Td>
        <BasicUserInfoView userInfo={receipt.user} />
      </Td>
      <Td>{timestampMsToDateStr(receipt.createdAt)}</Td>
      <Td>{timestampMsToDateStr(receipt.updatedAt)}</Td>
    </CustomTr>
  );
}
