export enum RelationshipTag {
  Single = 1,
  Couple = 2,
}

export enum VerifyStatus {
  // 非强制认证
  "Incomplete" = 1,
  "Pending" = 1 << 1,
  "Approved" = 1 << 2,
  "Rejected" = 1 << 3,

  // 强制认证
  "Incomplete (Force)" = 1 << 4,
  "Pending (Force)" = 1 << 5,
  "Approved (Force)" = 1 << 6,
  "Rejected (Force)" = 1 << 7,
}

export enum RecommendedStatus {
  Normal,
  recommended,
}

export enum ChannelType {
  Peer = 1,
  Group = 2,
}

export enum ReviewStatus {
  WaitingForReview,
  Checked,
}

export enum ProfileType {
  Profile,
  Verification,
}
