import { fetchSubscriptionNotifications } from "apis";
import { Platform } from "enums";
import { SubscriptionNotificationsParams } from "interfaces";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import {
  searchOriginalTransactionIdState,
  searchPageSizeState,
  searchPageState,
  searchSubscriptionIdState,
} from "states/search";
import { getErrorMessage } from "utils";
import { platformState, searchTimestampsQuery } from "views/components";

const notificationsRespQuery = selector({
  key: "notifications/resp",
  get: async ({ get }) => {
    const page = get(searchPageState);
    const pageSize = get(searchPageSizeState);

    const platform = get(platformState);

    const originalTransactionId = get(searchOriginalTransactionIdState);
    const subscriptionId = get(searchSubscriptionIdState);

    const params: SubscriptionNotificationsParams = {
      page: page,
      pageSize: pageSize,

      platform: platform === 0 ? undefined : (platform as Platform),
      // search ids
      originalTransactionId,
      subscriptionId,
    };

    if (!originalTransactionId || !subscriptionId) {
      const dateRange = get(searchTimestampsQuery);

      params.createdStart = dateRange.createdStart;
      params.createdEnd = dateRange.createdEnd;
    }

    try {
      const resp = await fetchSubscriptionNotifications(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const notificationsQuery = selector({
  key: "notifications",
  get: ({ get }) => {
    return get(notificationsRespQuery)?.data ?? [];
  },
});

export const notificationsTotalQuery = selector({
  key: "notifications/total",
  get: ({ get }) => {
    const resp = get(notificationsRespQuery);

    return resp?.page?.total ?? 0;
  },
});
