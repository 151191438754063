import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { HOVER_COLOR_BG_DARK, HOVER_COLOR_BG_LIGHT } from "constant";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { statusState } from "states";

import { getTitleFromPathname } from "utils";

// SidebarNavLink 自定义侧边栏NavLink
export function SidebarNavLink(props: LinkProps) {
  const resetStatus = useResetRecoilState(statusState);

  // const resetFirstApprovedType = useResetRecoilState(approvedStatusState);
  // const resetPlatform = useResetRecoilState(platformState);
  // const resetTabIndex = useResetRecoilState(tabState);
  // const resetCoupleType = useResetRecoilState(coupleTypeAtom);
  // const resetSearchMonth = useResetRecoilState(searchMonthState);

  // 提取to和children属性
  const { to, children, ...restProps } = props;

  // 判断path是否匹配
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  // active & hover状态背景色
  const hoverBg = useColorModeValue(HOVER_COLOR_BG_LIGHT, HOVER_COLOR_BG_DARK);

  // 默认从to中获取title
  // 若提供children则使用children

  const handleClick = () => {
    // resetTabIndex();
    resetStatus();
    // resetFirstApprovedType();
    // resetPlatform();
    // resetCoupleType();
    // resetSearchMonth();
  };

  return (
    <Link
      to={to}
      {...restProps}
      onClick={handleClick}>
      <Box
        p={2}
        bg={match ? hoverBg : ""}
        borderRadius={8}
        _hover={{ bg: hoverBg }}>
        {children ? (
          children
        ) : (
          <Text>{getTitleFromPathname(to as string)}</Text>
        )}
      </Box>
    </Link>
  );
}
