import {
  Heading,
  Spacer,
  Spinner,
  Stack,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { fetchSeekingMembers } from "apis";
import { Gender, InvitationStatus, SeekingJoinWay } from "enums";
import { SeekingMember } from "interfaces";
import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  searchPageSizeState,
  seekingQuery,
  seekingRequestIdState,
} from "states";
import { getErrorMessage } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView,
  HeaderBox,
  NextSubmitButton,
  SeekingView,
} from "views/components";

export function SeekingPage() {
  const params = useParams();
  const seekingId = params["seekingId"];

  if (!seekingId) return <EmptyView />;

  return (
    <Stack>
      <HeaderBox>
        <Heading>Seeking Details</Heading>
        <Spacer />

        <Suspense fallback={<></>}>
          <SubmitSeekingButton id={seekingId} />
        </Suspense>
      </HeaderBox>

      <Suspense fallback={<Spinner />}>
        <SeekingView id={seekingId} />
      </Suspense>

      <Suspense fallback={<Spinner />}>
        <SeekingMembersView id={seekingId} />
      </Suspense>
    </Stack>
  );
}

function SubmitSeekingButton({ id }: { id: string }) {
  const seeking = useRecoilValue(seekingQuery(id));

  if (!seeking?.description) return <></>;

  return (
    <NextSubmitButton
      bios={[seeking.description]}
      requestIdState={seekingRequestIdState}
    />
  );
}

interface SeekingMembersViewProps {
  id?: string;
  gid?: string;
}

function SeekingMembersView(props: SeekingMembersViewProps) {
  const bg = useColorModeValue("gray.50", "gray.900");
  const [members, setMembers] = useState<SeekingMember[]>([]);

  const toast = useToast();

  const pageSize = useRecoilValue(searchPageSizeState);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchSeekingMembers({
          aid: props.id,
          gid: props.gid,
          page: 1,
          pageSize: pageSize,
          joinWays: [SeekingJoinWay.Invite, SeekingJoinWay.Request],
        });
        setMembers(res.data.data ?? []);
      } catch (error) {
        toast({
          title: "Fetch seeking members failed",
          description: getErrorMessage(error),
          status: "error",
        });
        setMembers([]);
      }
    })();

    return () => {
      setMembers([]);
    };
  }, [pageSize, props, toast]);

  if (members.length === 0) {
    return <></>;
  }

  return (
    <Stack
      bg={bg}
      borderRadius={15}>
      <Heading
        size="md"
        p={4}>
        Members
      </Heading>
      <TableContainer>
        <CustomTable>
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Join Way</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {members.map((member, index) => (
              <CustomTr key={index}>
                <Td>
                  <BasicUserInfoView
                    userInfo={{
                      id: member.id,
                      avatar: member.avatar,
                      name: member.name,
                      gender: Gender.Man,
                      birthday: 1234567890123,
                    }}
                  />
                </Td>
                <Td>{SeekingJoinWay[member.memberJoinWay]}</Td>
                <Td>{InvitationStatus[member.memberStatus]}</Td>
              </CustomTr>
            ))}
          </Tbody>
        </CustomTable>
      </TableContainer>
    </Stack>
  );
}
