import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from "@chakra-ui/react";
import { ProfileStatus } from "enums";
import { Suspense } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import { profileQuery } from "states";

import { BirthdayEditor } from "./edit";
import { DeleteProfileButton } from "./edit/DeleteProfile";

export function ProfileMenu({ uid }: { uid: string }) {
  return (
    <Suspense>
      <ProfileMenuView uid={uid} />
    </Suspense>
  );
}

export function ProfileMenuView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));

  if (!profile || profile.status === ProfileStatus.Deleted) return <></>;

  return (
    // 用Box包裹起来，控制台就不报css警告了。
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<BsChevronDown />}>
          More
        </MenuButton>
        <MenuList>
          <BirthdayEditor
            profile={profile}
            device={profile.currInfo?.device}
          />

          <MenuDivider />
          <DeleteProfileButton profile={profile} />
        </MenuList>
      </Menu>
    </Box>
  );
}
