import { Box, HStack, Stack } from "@chakra-ui/react";
import { transferRecordsTotalQuery } from "states";
import {
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
} from "views/components";
import { TransfersView } from "./components/transfer-records-table";

const configuration = {
  name: "Transfer Record",
  component: <TransfersView />,
  totalState: transferRecordsTotalQuery,
  trainingItems: [DateFilter],
};

export function TransfersPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={configuration.totalState} />
      </HeaderBox>

      <HStack>
        {configuration.trainingItems.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      {
        <Box
          overflowX="scroll"
          key={configuration.name}>
          {configuration.component}
        </Box>
      }

      <Pagination totalState={configuration.totalState} />
    </Stack>
  );
}
