import { HStack, Spinner, Stack } from "@chakra-ui/react";
import { Suspense } from "react";
import { subscriptionsTotalQuery } from "states";
import {
  DateFilter,
  HeaderBox,
  MainHeader,
  Pagination,
  PlatformsFilter,
  SearchUIDInput,
} from "views/components";
import { SubscriptionsTable } from "./components/subscriptions-table";

export function SubscriptionsPage() {
  return (
    <Stack>
      <HeaderBox>
        <MainHeader totalState={subscriptionsTotalQuery} />
      </HeaderBox>
      <HStack>
        <PlatformsFilter />
        <DateFilter />
        <SearchUIDInput />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <SubscriptionsTable />
      </Suspense>
      <Pagination totalState={subscriptionsTotalQuery} />
    </Stack>
  );
}
