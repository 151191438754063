import { CIDRStatus } from "enums";
import { atom } from "recoil";
import { FilterSelector } from "./filter-selector";

export const cidrStatusState = atom({
  key: "cidr_status",
  default: 0,
});

export const cidrStatuses = [CIDRStatus.Enabled, CIDRStatus.Disabled];

export function CIDRStatusFilter() {
  const options = cidrStatuses.map((option) => {
    return {
      title: CIDRStatus[option],
      value: option,
    };
  });

  return (
    <FilterSelector
      options={options}
      state={cidrStatusState}
    />
  );
}
