import { Spinner, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TransferRecord } from "interfaces";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { transferRecordsQuery } from "states";
import { timestampMsToDateStr } from "utils";
import {
  BasicUserInfoView,
  CustomTable,
  CustomTr,
  EmptyView
} from "views/components";

export function TransfersView() {
  return (
    <Suspense fallback={<Spinner />}>
      <TransfersTable />
    </Suspense>
  );
}

export function TransfersTable() {
  const records = useRecoilValue(transferRecordsQuery);

  return (
    <>
      {records.length === 0 && <EmptyView />}
      {records.length > 0 && (
        <CustomTable>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Original Transaction ID</Th>
              <Th>From</Th>
              <Th>To</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.map((record, index) => (
              <TransferRecordsTableCell
                record={record}
                key={index}
              />
            ))}
          </Tbody>
        </CustomTable>
      )}
    </>
  );
}

interface TransferRecordsTableCellProps {
  record: TransferRecord;
}

function TransferRecordsTableCell(props: TransferRecordsTableCellProps) {
  const { record } = props;

  return (
    <CustomTr>
      <Td>{record.id}</Td>
      <Td>{record.originalTransactionId}</Td>
      <Td>
        {record.fromUser && <BasicUserInfoView userInfo={record.fromUser} />}
      </Td>
      <Td>
        <BasicUserInfoView userInfo={record.toUser} />
      </Td>
      <Td>{timestampMsToDateStr(record.createdAt)}</Td>
      <Td>{timestampMsToDateStr(record.updatedAt)}</Td>
    </CustomTr>
  );
}
