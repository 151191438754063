import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { AppRouter } from "router/router.routes";
import {
  FullScreenMediaView,
  GlobalErrorHandler,
  MultiMediaSourcesModalViewWrapper,
} from "views/components";
import { customTheme } from "./theme";

export function App() {
  useEffect(() => {
    // 设置页面title
    document.title = process.env.REACT_APP_NAME || "Magic Web";
  });

  return (
    <ChakraProvider theme={customTheme}>
      <AppRouter />

      <FullScreenMediaView />
      <MultiMediaSourcesModalViewWrapper />
      <GlobalErrorHandler />
    </ChakraProvider>
  );
}
