import { HStack, Spacer, Spinner, Stack } from "@chakra-ui/react";
import { Status } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { rejectedSeekingIdsState } from "states";
import {
  seekingDescriptionsCountQuery,
  seekingDescriptionsPageState,
  seekingDescriptionsQuery,
} from "states/seeking/description";

import {
  DateFilter,
  Pagination,
  ProfileTextsTable,
  SeekingStatusFilter,
  seekingStatusState,
} from "views/components";

export function SeekingDescriptionsPage() {
  const status = useRecoilValue(seekingStatusState);

  return (
    <Stack>
      <HStack>
        <SeekingStatusFilter />
        {status !== Status.Pending && <DateFilter />}
        <Spacer />
      </HStack>

      <Suspense fallback={<Spinner />}>
        <ProfileTextsTable
          state={seekingDescriptionsQuery}
          rejectedState={rejectedSeekingIdsState}
        />
      </Suspense>

      <Pagination
        totalState={seekingDescriptionsCountQuery}
        state={seekingDescriptionsPageState}
      />
    </Stack>
  );
}
