import {
  Box,
  HStack,
  Link,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Platform } from "enums";
import { Device } from "interfaces";
import { useRecoilValue } from "recoil";
import { editDeviceQuery } from "states";
import { timestampMsToDateStr } from "utils";
import { DeviceStatusTag } from "../other";
import { Label } from "./details-label";

export function DeviceView({ device }: { device?: Device }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatedDevice = useRecoilValue(editDeviceQuery(device?.id ?? ""));

  if (!device) return <>--</>;

  return (
    <>
      {/* View */}
      <Stack spacing={0}>
        <HStack>
          <Link onClick={onOpen}>{device.hardware}</Link>
          <DeviceStatusTag status={updatedDevice?.status ?? device.status} />
        </HStack>
        <Text
          fontSize="14px"
          opacity={0.5}>
          {`${device.packageVersion} (${device.packageBuild})`}
        </Text>
      </Stack>

      {/* Modal */}
      <Modal
        size="2xl"
        isCentered
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Device</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <DeviceModalView device={device} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export function DeviceModalView({ device }: { device: Device }) {
  const updatedDevice = useRecoilValue(editDeviceQuery(device.id));

  return (
    <List spacing={4}>
      <Label
        title="Id"
        value={device.id}
      />
      <Label
        title="Device id"
        value={device.imei}
      />
      <Label
        title="Hardware Model"
        value={device.hardware}
      />
      <Label
        title="OS Version"
        value={device.os}
      />

      <Label
        title={device.platform === Platform.iOS ? "Bundle ID" : "Package Name"}
        value={device.packageBundleId}
      />

      <Label
        title="App Version"
        value={device.packageVersion}
      />
      <Label
        title="App Build"
        value={device.packageBuild}
      />
      <Label title="Status">
        <Box>
          <DeviceStatusTag status={updatedDevice?.status ?? device.status} />
        </Box>
      </Label>

      <Label
        title="Created At"
        value={timestampMsToDateStr(device.createdAt)}
      />
      <Label
        title="Updated At"
        value={timestampMsToDateStr(device.updatedAt)}
      />

      <Label
        title="FCM Token"
        value={device.token ?? "--"}
      />
    </List>
  );
}
