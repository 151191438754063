import { fetchSeekings, SeekingsParams } from "apis";
import dayjs from "dayjs";
import { Status } from "enums";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import { searchPageSizeState, searchUIDState } from "states/search";
import { getErrorMessage } from "utils";
import {
  genders,
  genderState,
  searchTimestampsQuery,
  seekingStatusState,
} from "views/components";

export const seekingsRequestIdState = atom({
  key: "seekings/requestId",
  default: dayjs().valueOf(),
});

export const seekingsPageState = atom({
  key: "seekings/page",
  default: 1,
});

const seekingsRespQuery = selector({
  key: "seekings/resp",
  get: async ({ get }) => {
    get(seekingsRequestIdState);

    const params: SeekingsParams = {
      page: get(seekingsPageState),
      pageSize: get(searchPageSizeState),

      gender: genders[get(genderState)],
    };

    const uid = get(searchUIDState);

    if (!!uid) {
      params.uid = uid;
    } else {
      params.status = get(seekingStatusState);

      if (Status.Pending !== get(seekingStatusState)) {
        const searchTimestamps = get(searchTimestampsQuery);
        params.createdStart = searchTimestamps.createdStart;
        params.createdEnd = searchTimestamps.createdEnd;
      }
    }

    try {
      const resp = await fetchSeekings(params);

      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const seekingsQuery = selector({
  key: "seekings",
  get: ({ get }) => {
    return get(seekingsRespQuery)?.data ?? [];
  },
});

export const seekingsCountQuery = selector({
  key: "seekings/count",
  get: ({ get }) => {
    return get(seekingsRespQuery)?.page?.total ?? 0;
  },
});
