import { Stack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import dayjs from "dayjs";
import { DayjsKey, SearchParamKey } from "enums";
import { atom, selector, useResetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { searchPageState } from "states";
import { generateDateOptions, getTheStartAndEndTimestamps } from "utils";
import { FilterSelector } from "./filter-selector";

const dateOptions = generateDateOptions(
  "October 2017",
  dayjs().format(DayjsKey.DatePickerDisplayFormat),
  DayjsKey.DatePickerDisplayFormat
);

const searchMonthState = atom({
  key: SearchParamKey.Month,
  default: +dayjs().format(DayjsKey.DatePickerValueFormat),
  effects: [syncEffect({ refine: number() })],
});

export const searchTimestampsQuery = selector({
  key: "timestamps",
  get: ({ get }) => {
    const month = get(searchMonthState);
    return getTheStartAndEndTimestamps(month);
  },
});

export function DateFilter() {
  const resetPage = useResetRecoilState(searchPageState);

  return (
    <Stack>
      <Text
        fontSize="14px"
        opacity={0.5}>
        Month
      </Text>
      <FilterSelector
        options={dateOptions}
        state={searchMonthState}
        onReset={resetPage}
      />
    </Stack>
  );
}
