import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// 扩展主题
export const customTheme = extendTheme(
  {
    fonts: {
      body: "Facundo, sans-serif",
      heading: "Facundo, serif",
      mono: "Facundo, monospace",
    },
    colors: {
      gray: {
        50: "#f9f9f9",
        100: "#ececec",
        200: "#e3e3e3",
        300: "#cdcdcd",
        400: "#b4b4b4",
        500: "#9b9b9b",
        600: "#676767",
        700: "#424242",
        750: "#2f2f2f",
        800: "#212121",
        900: "#171717",
        950: "#0d0d0d",
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "red" })
);
