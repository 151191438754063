import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabPanelsProps,
  Tabs,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { SearchParamKey } from "enums";
import React from "react";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { searchPageState } from "states";

interface CustomTabsProps extends TabPanelsProps {
  tabs: string[];
}

export const tabState = atom<number>({
  key: SearchParamKey.tab,
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function CustomTabs(props: CustomTabsProps) {
  const [tabIndex, setTabIndex] = useRecoilState(tabState);

  const resetPage = useResetRecoilState(searchPageState);

  const handleChange = (index: number) => {
    setTabIndex(index);
    resetPage();
  };

  return (
    <Tabs
      isLazy
      colorScheme="pink"
      defaultIndex={tabIndex}
      index={tabIndex}
      onChange={handleChange}>
      <TabList>
        {props.tabs.map((title, index) => (
          <Tab key={index}>{title}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {React.Children.map(props.children, (child) => (
          <TabPanel>{child}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
